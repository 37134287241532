import io from "socket.io-client";
import { KYC_SIT } from "../Constants";
import { storeObj } from "../Store/store";
const url = KYC_SIT;
export let socket;

export const emit = (key, data) => {
  console.log("socket emitting ... ", socket, key, data);
  if (key && socket) socket.emit(key, data);
};

export const socketInit = ()=>async (dispatch,getState) => {
  const { user = {}, uid } = getState().user;
  const { token,company="" } = user;
  console.log(token,"sssssssssssssssssssssssss")
  try{
      var token1 = `Bearer ${token}`;
      console.log("inside socket connection");
      socket = io(url, {
        transports: ["websocket"],
        query: { token: token1 },
      });

      socket.on("connect", (_socket) => {
        console.log("socket connected ");
      });
}catch (error) {
  console.log("socket error", error);
}

}
export const disconnect = () => {
  socket && socket.disconnect();
};
