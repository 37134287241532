import React, { useState, useEffect } from "react";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import _store from "./Store/store"
import './App.css';
import RootRouter from "./RootRouter";
import Loader from './Components/Loader'
const {persistor, store} = _store();
require('dotenv').config()

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  // if (loading)
  //  return <div className="signup_data"><Loader />
  //  <p className="pt-5">Video kyc is loading</p>
  //  </div>;
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <RootRouter  />
      </Provider>
    </PersistGate>
  );
}

export default App;
