import React, { Component } from "react";
import { connect } from "react-redux";
import { changeHeaderonAadhar } from "../../Actions";
import "./Aadharxml.css";
import xml from "../../Images/xml.svg";
import rightArrow from "../../Images/right_icon.svg";
import phone from "../../Images/OTPIcon.svg";
import capture from "../../Images/Capture.svg";
import { socket, socketInit } from "../../Helpers";
        
export class Aadharxml extends Component {
  constructor(props) {
    super(props);
    this.props.changeHeaderonAadhar({
      step: 1,
      title: "Aadhaar ",
      visible: true,
      border_visible:false,
    });
    if (!socket) {
      socketInit()
    }
  }
 
 
  render() {
    return (
      <React.Fragment>
        
          <div className=" xml_top">
            <div
              className="aadharxml_data mt-5 pt-4"
              onClick={() => this.props.history.push("/aadharxmlupload")}
            >
              <img src={xml} alt="aadhar xml" />
              <div>
                <div className="aadhar_div">Aadhaar XML</div>
                <p>Upload your Aadhaar Xml</p>
              </div>
              <img src={rightArrow} alt="rightarrow" />
            </div>

            <div
              className="aadharxml_data mt-5 pt-4"
              onClick={() => this.props.history.push("/aadharotp")}
            >
              <img src={phone} alt="aadhar xml" />
              <div>
                <div className="aadhar_div">Aadhaar OTP</div>
                <p>Get Aadhaar from OTP</p>
              </div>
              <img src={rightArrow} alt="rightarrow" />
            </div>

            <div
              className="aadharxml_data mt-5 pt-4"
              onClick={() => this.props.history.push("/aadharcapture")}
            >
              <img src={capture} alt="aadhar xml" />
              <div>
                <div className="aadhar_div">Aadhaar Capture</div>
                <p>Click on camera to capture</p>
              </div>
              <img src={rightArrow} alt="rightarrow" />
            </div>
          </div>
        
        
      </React.Fragment>
    );
  }
}

export default connect(() => ({}), { changeHeaderonAadhar })(Aadharxml);
