import React, { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../Actions";
import "./signup.css";
import { changeHeaderonAadhar } from "../../Actions";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { ActionType} from "../../Constants";
import { toast } from "react-toastify";
import Loader from '../../Components/Loader';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SignUp = (props) => {
  const {loader=false,user={}} = useSelector(({user})=>user)
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    company: "",
    emailError:"",
    phoneError:"",
    lastname:'',
    nameError:"",
    customer_id:"None",
    lastname:"",
    lnameError:"",
    emailbutton:true,
    fnamebutton:true,
    lnamebutton:true,
    phonebutton:true,
    language:"",
    languagebutton:true,
    languageError:""
  });
  const [open,setOpen]=useState(false)
  const handleChange=(e)=> {
    const phone = e.target.value
    const re = /^[0-9\b]+$/;
    if (phone.length!==0){
      if (re.test(phone)&&phone.length===10) {
         setState({...state,phone: phone,phonebutton:false,phoneError:"",})
      }
      else {
      setState({...state,phoneError:"Phone number should be in 10 digits and should contain only numbers",phonebutton:true,phone: phone})
    }
  }else {
    setState({...state,phoneError:"Field Required",phonebutton:true,phone: phone})
  }
}
  const onEmailChange=(e)=> {
    const email=e.target.value
    if (email.length!==0){
    if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
      setState({...state,emailError:"",email:email,emailbutton:false})
    }else {
      setState({...state,emailError:"Email is not valid",emailbutton:true,email:email})
    }
  }else {
    setState({...state,emailError:"Field Required",emailbutton:true,email:email})
  }
  }
  const onNamechange=(e)=> {
    const name=e.target.value.replace(/\s+/g, '')
    
    const regex = new RegExp("^[a-zA-Z ]+$");
    if (name.length!==0){
      if (regex.test(name)) {
        setState({...state,nameError:"",name:name,fnamebutton:false})
      }else {
        setState({...state,nameError:"Please enter only letters",fnamebutton:true,name:name})
      }
    }else {
      var k = e ? e.which : window.e.keyCode;
      console.log(k)
      if (k == 32){
        return false;
      } 
      setState({...state,nameError:"Field Required",fnamebutton:true,name:name})
    }
  }
  const ONlastNamechange=(e)=> {
    const lname=e.target.value.replace(/\s+/g, '');
    const regex = new RegExp("^[a-zA-Z ]+$");
    if (lname.length!==0){
      if (regex.test(lname)) {
        setState({...state,lnameError:"",lastname:lname,lnamebutton:false})
      }else {
        setState({...state,lnameError:"Please enter only letters",lnamebutton:true,lastname:lname})
      }
    }else {
      setState({...state,lnameError:"Field Required",lnamebutton:true,lastname:lname})
    }
  }
  useEffect(() => {
    const urlElements = window.location;
    var url = new URL(urlElements);
    let company = url.searchParams.get("company");
    let customer_id=url.searchParams.get("customer")
    setState({...state, company: company,customer_id:customer_id });
    dispatch(changeHeaderonAadhar({ title: "Sign up", visible: true,type: ActionType.RESET,border_visible:false }));
    document.body.classList.add("background-white");
  }, []);
 
  const register = async (e) => {
    e.preventDefault()
    if(!state.company){
     return toast.error("Company is not found for this user,Check the URL")
    }else if (!state.name || !state.email || !state.phone ||!state.lastname)  {
    toast.error("Please fill all required fields")
    }
    else if (state.name) {
      let data=window.location.href
      const result = await dispatch(signup(state,data));
      if (result){
         props.history.push("/otp");
      }
    }
  };
  const languageChange = (event) => {
    console.log(event.target.value.length)
    const language=event.target.value
    if (language.length>0) {
      setState({...state,language:event.target.value,languageError:"",languagebutton:false})
    }else {
      setState({...state,language:event.target.value,languageError:"Please Select language",languagebutton:true})
    }
  };

  const handleClose = () => {
    setOpen(false)
  };

  const handleOpen = () => {
    setOpen(true)
  };
  return (
    <div className="data">
    {loader?<Loader msg="Please wait signing in......"/>:
    <div className="signup_data1">
      <h1 className="signup">Sign up</h1>
      <form id="signUpForm">
        <div className="signup_form_user">
          <label>First name</label>
          <div className="text_information">Make sure it matches the name on your government ID</div>
          <TextField
            className=" underline"
            id="standard-name"
            name="name"
            type="text"
            onChange={onNamechange}
            required
            value={state.name}
            pattern="[^\s]+"
          />
          <div className="errormsg">{state.nameError}</div>
          <label>Last Name</label>
          <div className="text_information"></div>
          <TextField
            onChange={ONlastNamechange}
            className="underline"
            id="standard-names"
            type="text"
            value={state.lastname}
            required
          />
          <div className="errormsg">{state.lnameError}</div>
          <label>Email</label>
          <div className="text_information">We will email you the information</div>
          <TextField
            onChange={onEmailChange}
            className="underline"
            id="standard-name3"
            type="email"
            value={state.email}
            required
          />
          <div className="errormsg">{state.emailError}</div>
          <label>Phone</label>
          <TextField
            onChange={handleChange}
            className="underline"
            value={state.phone}
            id="standard-name1"
            required
          />
          <div className="errormsg">{state.phoneError?state.phoneError:""}</div>
          <label>Language</label>
                <FormControl className="underline mb-3" id="standard-name">
                  <InputLabel 
                  >Language</InputLabel>
                  <Select
                   
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={state.language}
                    onChange={languageChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem  value={"English"}>English</MenuItem>
                    <MenuItem value={"Hindi"}>Hindi</MenuItem>
                  </Select>
                </FormControl>
                <div className="errormsg">{state.languageError?state.languageError:""}</div>
          <button type="submit" id="signUpButton" onClick={register} disabled={state.phonebutton||state.emailbutton||state.lnamebutton||state.fnamebutton||state.languagebutton}>
            Sign up
          </button>
        </div>
      </form>
    </div>
  }
    </div>
  );
};

const mapdispatchToProps = {
  changeHeaderonAadhar,
};
export default connect(() => ({}), mapdispatchToProps)(SignUp);
