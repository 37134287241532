import React, { useState,useEffect } from 'react';
import { OTPublisher } from 'opentok-react';
import { ConnectionStatus } from './';
const OT = require('@opentok/client');

export default props => {
  const [state, setState] = useState({
    error: null,
    audio: true,
    video: true,
    source: 'camera'
  });
  const onError = err => {
    console.log("error ", err)
    if (err.code === 1500) {
      setState({ ...state, source: 'camera' })
    }
  }

  
  return (
    <div className="publisher">
      <ConnectionStatus connected={!!props.session} />
        User
      <OTPublisher
        session={props.session}
        properties={{
          publishAudio: state.audio,
          publishVideo: state.video,
          height : 300,
          width : 300
        }}
        // eventHandlers={events}
        onError={onError}
      />
    </div>
  )
}