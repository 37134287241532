import React from "react";
import { preloadScript } from "opentok-react";

import "@opentok/client";
import "./opentok.css";
import Header from "./Header";
import OpentokBody from "./OpentokBody";
import Loader from '../../Components/Loader'
import {  useSelector } from "react-redux";
import  { useState } from 'react';
const OpenTok = (props) => {
  const { screencapture=false } = useSelector(({ agent }) => agent);
  console.log(screencapture)
  return (
      <React.Fragment>
      {screencapture?
        <Loader msg="Recorded video is being uploaded please wait...."/>:
            <div className="agent-conatiner">
              <Header />
              <OpentokBody/>
                <div className="flex1"/>
            </div>
            }
      </React.Fragment>
  );
};

export default preloadScript(OpenTok);
