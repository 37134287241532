import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OpentokVideoCall from "./OpentokVideoCall";
import StepsComponents from "./StepsComponents";
import { imageExtractionFromUser, imageExtraction,livelinessdatafromuser } from "../../Actions";
import { socket } from "../../Helpers";
import { ActionType } from "../../Constants";

let Aadharxmldata={}
let Livelinessdata={}
let detailIm = {};

export default (props) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState(null);
  const { steps = {}, doctype = "" } = useSelector(({ agent }) => agent);
  const { session = {} } = useSelector(({ opentok }) => opentok);
  const [count,setCount]=useState(0)
  const [live,liveCount]=useState(0)
  const [imagecount,setImagecount]=useState(0)


  useEffect(() => {
    if (socket)
      socket.on(`${session.Session_ID}_USER`, (d) => {
        console.log(d);
        const { uid, data, user_id, alias } = d;
        if (alias === "aadhar_xml_extracted_data" &&session.uid === uid &&session.user_id === user_id) {
          setCount(count+1)
          Aadharxmldata=data
        }
       
      });
  }, []);
  useEffect(()=>{
    if (socket) {
      socket.on(`${session.Session_ID}_USER`, (detail) => {
        console.log(detail)
          if(detail.session_id===session.Session_ID){
            detailIm = detail;
            setImagecount(imagecount+1);
          }
      })
    }
    },[])
  useEffect(()=> {
    if (socket)
      socket.on(`${session.Session_ID}_USER`,(live_data)=> {
        const {uid,data,user_id,alias}=live_data;
        if(alias==='Liveliness_data_from_user' && session.uid===uid && session.user_id===user_id) {
          liveCount(live+1)
          Livelinessdata=data
      }
      })
  })
  useEffect(()=> {
      dispatch(livelinessdatafromuser(Livelinessdata,steps.active))
      setTimeout(() => {
        setCount(0);
        Aadharxmldata={}
      }, 3000);
  },[live])
  useEffect(()=> {
    if (count===1) {
      dispatch(imageExtractionFromUser(Aadharxmldata, steps.active));
      setTimeout(() => {
        setCount(0);
        Aadharxmldata={}
      }, 3000);
    }
  },[count])
  useEffect(()=>{
    if(imagecount===1){
        onImage(detailIm.image);
        console.log(detailIm.image)
        if (detailIm.image===undefined) {
          console.log("image is undefined")
        }else {
          dispatch({type:ActionType.IMAGE_LOADER_SUCCESS,payload:detailIm.image})
        }
      setTimeout(() => {
        setImagecount(0);
        detailIm =  {}
      }, 3000);
    }
  },[imagecount])
  useEffect(() => {
    setImage(null);
    dispatch({type:ActionType.IMAGE_LOADER_SUCCESS,payload:null})
  }, [steps.active]);
  const onImageCapture = (e) => {
    setImage("capture");
    if (steps.active===0) {
      dispatch({type:ActionType.SCREENCAPTURE_CAPTURE_AUTOMATE,payload:true})
    }else {
      dispatch({type:ActionType.SCREENCAPTURE_CAPTURE_AUTOMATE,payload:false})
    }
  };
  const onImage = (image) => {
    console.log(image)
    setImage(
      image && image ?`data:image/jpeg;base64, ${image}`:"Noimage"
    );
  };
  const onUpload = () => {
    console.log(image)
    const { active = 0 } = steps;
    let type =
      active === 0
        ? "aadhar"
        : active === 1
        ? doctype
        : active === 2
        ? "signature"
        : "";
    if (image && image !== "capture") {
      dispatch(imageExtraction(image, type))
    }
      
  };
  return (
    <div className="opentok-container">
      <div className="opentok-left-container">
        <OpentokVideoCall {...props} onImage={onImage} image={image} />
      </div>
      <div className="opentok-right-container">
        <StepsComponents
          onImageCapture={onImageCapture}
          onUpload={onUpload}
          setImage={setImage}
          image={image && image !== "capture" ? image : null}
        />
      </div>
    </div>
  );
};
