
import React, { Component } from "react";
import "./pancapture.css";
import { connect } from "react-redux";
import { changeHeaderonAadhar } from "../../Actions";
import Capture from '../../Components/DataCapture/capture'
export class pancapture extends Component {
  constructor(props){
    super(props);
    this.props.changeHeaderonAadhar({step : 2, title : "PAN ", visible : true,border_visible:true,border:40});
  }
  render() {
    return(
      <div>
          <Capture data_type="pan" history={this.props.history}/>
      </div>
    )
  }
}

export default connect(()=>({}),{changeHeaderonAadhar})(pancapture)
