
import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, CircularProgress } from '@material-ui/core';
import { CameraAlt, ArrowRightAlt, KeyboardBackspace, RadioButtonChecked, FiberManualRecord } from "@material-ui/icons";
import AadharDetails from "./AadharDetails";
import { agentAadharStep, setDocType,Agentidinsertion } from "../../Actions";
import ProfileComponent from "./ProfileComponent"
import starting from '../../Images/Starting.svg'
import completed from '../../Images/Completed.svg'
import {socketInit,socket} from '../../Helpers'
import { ActionType } from "../../Constants";


const Loader = ({size=30}) => {
    return (
        <div className="aadhar-loader-wrapper center">
            <CircularProgress size={size} />
        </div>
    )
}
let agent_count=0
const AadharCapture = props => {
    agent_count+=1
    const dispatch = useDispatch();
    if (!socket){
    dispatch(socketInit())
    }
    if (agent_count===1) {
    dispatch(Agentidinsertion())
    }
    const {extloader=false,image_loader=false,data_image,aadhar_front={},aadharfront_image} = useSelector(({agent})=>agent)
    console.log(aadhar_front)
    const { onAadharFront = () => { }, image=null, onAadharBack = () => { }, onUpload=()=>{}, onImageCapture = () => { }, active = "front", title="" } = props;
    console.log(data_image,"sssssssssssssssssssssss")
    if (title==="Liveliness")
    return null
    return (
        <div className="agent-card">
            <div className="agent-card-header">
                <div onClick={onAadharFront} className={`card-header-text ${active === "front" ? "active-card-header-text" : ""}`}>
                    {title}
                    </div>
                {title==="Aadhar Front"?
                <div onClick={onAadharBack} className={`card-header-text ${active === "back" ? "active-card-header-text" : ""}`}>
                    Aadhaar Back
                    </div> 
                    :""}
            </div>
            <div className="agent-card-body">
                <div className="agent-left-card" >
                    {image_loader&&image_loader?
                    <div className="agent-left-card-captured center d-flex flex-column">
                        <Loader size={30}/>
                        <p className="mt-5">Please wait Captured Image will be shown here</p>
                    </div>:
                    <div>
                    {
                    aadharfront_image&&active==="front"&&title==="Aadhar Front"?
                    <img src={aadharfront_image} className="agent-left-card-captured"/>:
                    data_image&&data_image?
                    <img src={`data:image/jpeg;base64, ${data_image}`} className="agent-left-card-captured"/> :
                    <div className="agent-left-card-captured center">
                        Captured Image will be shown here
                    </div>
                    }
                    </div>
                    
                }
                </div>
                <div className="agent-right-card center" >
                    <div className="uploads-capture-buttons">
                    <Button variant="outlined" className="document-capture-button" disabled={extloader}
                        startIcon={<CameraAlt />} onClick={onImageCapture} >
                        Capture
                    </Button>
                    <div className="space-20" />
                    {image&&image!=='capture'&&<Button variant="outlined" className="document-capture-button" disabled={extloader}
                        onClick={onUpload} >
                        {extloader ? <Loader size={15}/> : 'Upload' }
                    </Button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const NavButtons = ({ onNext = () => { }, onBack=()=>{}, step=1 }) => {
    const {extloader=false, _loader=false,extraction={}, steps,} = useSelector(({agent})=>agent);
    const { state=false } = extraction[steps.active]?extraction[steps.active]:{};
    return (
        <div className="document-next-button-wrapper">
            {step > 0 && 
            <Button variant="outlined" className="document-back-button" disabled={extloader||_loader}
                startIcon={<KeyboardBackspace />} onClick={onBack} >
                Back
            </Button>}
            <div className="flex1"/>
            <Button variant="contained" className="document-next-button" disabled={extloader||_loader||state}
                endIcon={<ArrowRightAlt />} onClick={onNext} >
                Next
            </Button>
        </div>
    )
}

export const RadioButtonSelection = ({radio="pan", onChange=()=>{}}) => {
    return (
        <div className="radio-view">
            <div className="inner-radio-view" onClick={()=>onChange("pan")}>
                {radio==="pan" ? <RadioButtonChecked className="radio active-radio" /> : <FiberManualRecord className="radio"/>}
                <span> PAN Card </span>
            </div>
            <div className="inner-radio-view" onClick={()=>onChange("dl")}>
                {radio==="dl" ? <RadioButtonChecked disabled={true} className="radio active-radio" /> : <FiberManualRecord className="radio"/>}
                <span> Driving License </span>
            </div>
            <div className="inner-radio-view" onClick={()=>onChange("passport")}>
                {radio==="passport" ? <RadioButtonChecked disabled={true} className="radio active-radio" /> : <FiberManualRecord className="radio"/>}
                <span> Passport </span>
            </div>
        </div>
    )
}
export const Liveliness =(props)=> {
    console.log(props)
    const {  extraction={}, steps,  } = useSelector(({ agent }) => agent);
    const { randdata="",msg="" } = extraction[steps.active]?extraction[steps.active]:{};
    console.log(randdata,msg)
    return (
        <div>
            <div className="liveliness_indicating">{msg==="Success"?<p><img src={completed} />Video Recording has been completed</p>:<p><img src={starting} />Video Recording yet to start</p>}
            </div>
            <div className="liveliness_data">
                <div>Customer need to read aloud the number which is shown below. 
                Please click next once he read it right.</div>
                <div className="livelinessotp">{randdata?randdata:""}</div>
                {/*
                <Button className="document-capture-button">Verify</Button>
                */}
            </div>
        </div>
    )
}
const ProfileModal = props => {
    return (
        <Modal
            className="center"
            open={props.modal}
            onClose={()=>props.setModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <ProfileComponent onClose={()=>props.setModal(false)}/>
      </Modal>
    )
}
export default ({ tab, heading, image=null, max, onImageCapture, onUpload,randdata,msg,setImage }) => {
    console.log(tab,heading,msg,randdata)
    const dispatch = useDispatch();
    const {steps={}, doctype='pan',data_image,aadharfront_image} = useSelector(({agent})=>agent)
    const [active, setActive] = useState("front");
    const [modal, setModal] = useState(false);
    console.log(aadharfront_image)
    const setRadio = (type) => {
        dispatch(setDocType(type))
    };

    const onAadharFront = () => {
        setActive("front");
        // dispatch({type:ActionType.AADHAAR_FRONT_IMAGE,payload:null})
    }
    const onAadharBack = () => {
        setActive("back");
        dispatch({type:ActionType.IMAGE_LOADER_SUCCESS,payload:null})
        setImage(null)

    }
    const onNext =(state) => {
        let current = steps.active;
        if(state==="next" && current >= max-1)
           return setModal(true);
            let pos = state==="next" ? current >= max ? max : current  + 1 :  
                current <= 0 ? 0 : current - 1
            dispatch(agentAadharStep({ active : pos }));
    }

    const title = tab===1?"Aadhar Front":tab===2?doctype==="pan"?"PAN Card" : doctype==="dl"? "Driving License" : "Passport" 
    : tab===3 ? "Signature" : tab===4?"Liveliness":""
    const captureProps = { image, active, title, onAadharFront, onAadharBack, onImageCapture, onUpload,setImage };
    const detailsProps = {tab, heading, title };
    const navButtProps = { onNext : ()=>onNext("next"), onBack : ()=>onNext("back"), step : steps.active, max }
    const {extraction = {} } = useSelector(({ agent }) => agent);
    const {type=''} = extraction&&extraction[0]?extraction[0]:{};
    

    return (
        <div className="aadhar-component" >
            <div className="aadhar-component-title center">
                <div className="round-step center">{tab}</div>
                <span>{heading}</span>
            </div>
            {tab===2&&heading==="OVD"&&<RadioButtonSelection radio={doctype} onChange={rd=>setRadio(rd)} />}
            <div>{type==="Aadhar_XML" && tab===1&&heading==="Aadhaar"?"":<AadharCapture { ...captureProps } />}</div>
            <AadharDetails { ...detailsProps } />
            <div>{tab===4&&heading==="Liveliness"?<Liveliness />:""}</div>
            <NavButtons { ...navButtProps } />
            <ProfileModal modal={modal} setModal={setModal} />
        </div>
    )
}