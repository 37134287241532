import React, { Component } from "react";
import { connect } from "react-redux";
import SideMenu from "../../Components/Agents/side-menu/sidemenu";
import "./dashboard.css";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import axios from "axios";
import { socket,socketInit, emit } from "../../Helpers";
import LoadingOverlay from "react-loading-overlay";
import {
  clearExtraction,
  requestAgentService,
  Sendmail,
  // Download
} from "../../Actions";
import { ActionType, KYC_SIT, PATH } from "../../Constants";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { createFormHeader } from "../../Actions/agent/opentok";
import search from '../../Images/search.png'
import _ from 'lodash';
let timer,count = 0;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_text: "",
      calls_count: 0,
      failcalls_count: 0,
      user: "",
      company: "",
      button_enable: true,
      pagesize: "",
      pageindex: "",
      loader: false,
      data: [],
      showModal: false,
      showUploadModal: false,
      offset: 0,
      limit: 10,
      page: 1,
      email_list: [],
      hidden:false,
      Next_button:false,
      next:false,
      que_length:""
    };
    if(!socket)
    this.props.socketInit()
    this.start = this.start.bind(this);
  }
  ModalShow = () => {
    this.setState({ showModal: true });
  };
  ModalHide = () => {
    this.setState({ showModal: false });
  };
  Addcompany = (e) => {
    const email_length=e.target.value
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email_length)&&email_length.length>=3) {
      this.setState({button_enable:false,email_list: email_length})
    }else {
      this.setState({button_enable:true,email_list: email_length})
    }
  };
  handleClick = () => {
    this.props.Sendmail({email_list:this.state.email_list,hide_modal:this.ModalHide},(success)=> {
      if (success) {
        this.setState({email_list:""})
      }
    });
  };
  show=()=> {
    this.setState({ hidden: !this.state.hidden });
  }
  handlepageSize = (e) => {
    const updatedoffset = this.state.offset;
    const updatedlimit = e.target.value;
    this.generateTable(updatedoffset, updatedlimit);
  };
  Search = _.debounce((text) => {
    const updatedoffset = this.state.offset;
    const updatedlimit = this.state.limit;
    const search = text
    this.generateTable(updatedoffset, updatedlimit, search);
  },1000);
  next = () => {
    console.log(this.state.offset);
    this.setState({
      offset: this.state.offset + 1,
    });

  };
  previous = () => {
    console.log(this.state.offset);
    this.setState({
      offset: this.state.offset - 1,
    });
  };
  componentDidMount() {
    this.props.clearExtraction();
    const updatedoffset = this.state.offset;
    const updatedlimit = this.state.limit;
    this.generateTable(updatedoffset, updatedlimit);
    const company_language=`${this.props.company}_${this.props.language}`
    socket.on(`Queue_length_${company_language}`, (data) => {
      this.setState({que_length:data})
    });
  }
  // if (socket) {
  //   alert("data")
  //   socket.on(`Queue_length_${this.props.company}`, (data) => {
  //     this.setState({que_length:data})
  //     console.log(data);
  //   });
  // }
  Pressing =()=> {
    const company_language=`${this.props.company}_${this.props.language}`
      try {
        emit("Queue_length", {
          company:company_language
        });
        socket.on(`Queue_length_${company_language}`, (data) => {
          this.setState({que_length:data})
          console.log(data);
        });
      } catch (error) {
        console.log(error);
      }
  }
  componentWillUnmount() {
    this.removePoll();
  }
  startPoll = async () => {
    let url=window.location.href
    console.log("poll ", count);
    timer = setInterval(async () => {
      const queu = await this.props.requestAgentService(this.props.company,url);
      count += 1;
      if (queu && queu.Session_ID) {
        emit("agent-to-user", {
          uid: queu.uid,
          user_id: queu.user_id,
          session_id:queu.Session_ID,
          alias: "skip_to_wait_screen",
          side:"AGENT",
        });
        emit("Queue_length", {
          company:this.props.company
        });
        this.removePoll();
        this.props.history.push("/agent/Opentok");
      }
      if (count > 5) {
        this.removePoll();
        this.setState({ loader: false });
        toast.error("No one waiting in the queue");
      }
    }, 5000);
  };

  removePoll = () => {
    if (timer) clearInterval(timer);
    count = 0;
  };
  dataDownload=(mainkey)=> {
    this.props.Download(mainkey)
  }
  async generateTable(updatedoffset, updatedlimit, search) {
    console.log(updatedlimit, search);
    try {
      var formData = new FormData();
      formData.append("agentid", this.props.agent_id);
      formData.append("pagesize", updatedlimit);
      formData.append("pageindex", updatedoffset + 1);
      formData.append("keyword", search);
      const res = await axios(
        `${KYC_SIT}${PATH.readuser_pagination}`,
        createFormHeader(formData, this.props.token),
        {}
      );
      const resp = res.data;
      if (res.status === 200) {
      this.Pressing()
        this.setState({
          data: resp.status,
          next:true
        });
      } 
    } catch (error) {
      toast.error("Token is expired")
      localStorage.clear();
		  window.location.href = '/agent';
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    console.log(nextState);
    if (this.state.offset !== nextState.offset) {
      const updatedoffset = nextState.offset;
      const updatedlimit = this.state.limit;
      this.generateTable(updatedoffset, updatedlimit);
    }
    return true;
    
  }

  start = async () => {
    this.setState({ loader: true });
      toast.success(
        "Please wait untill we connect with the user. You will be redirected automatically "
      );
      this.props.clearExtraction();
      this.startPoll();
  };
  render() {
  console.log(this.props.company)
    const columns = [
      {
        name: "UserID",
        options: {
          display: false,
        },
      },
      {
        name: "Phone",
        options: {
          sort:false
        },
      },
      {
        name: "Email",
        options: {
          sort:false
        },
      },
      {
        name: "Name",
        options: {
          sort:false
        },
      },
      {
        name: "Company",
        options: {
          display: false,
        },
      },
      {
        name: "UID",
        options: {
          display: false,
        },
      },
      {
        name: "Status",
        options: {
          display: false,
        },
      },
      {
        name: "Timestamp",
        label: "Timestamp",
        options: {
          filter: true,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const strDateTime = tableMeta.rowData[7];
            return (
              <React.Fragment>
                <a>{strDateTime.slice(0, -3)}</a>
              </React.Fragment>
            );
          },
        },
      },
      {
        name: "Download",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const mainkey = tableMeta.rowData[5];
            return (
              <React.Fragment>
              {/*
              <div onClick={()=>this.dataDownload(mainkey)}>downlaod</div>*/}
              <a href={`${KYC_SIT}${PATH.download}/${mainkey}`}>Download</a> 
              </React.Fragment>
            );
          },
        },
      },
    ];
    const options = {
      responsive: "scroll",
      search: false,
      print: false,
      viewColumns: false,
      serverSide: true,
      download: false,
      rowHover: true,
      filter: false,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      textLabels: {
        body: {
          noMatch: (
            <div>
              {this.state.data.length>0  ? (
                <div className="noDataDisp d-flex justify-content-center">
                  <Loader />
                  <h6 className="mt-5">
                    Loading Please Wait Fetching data....
                  </h6>
                </div>
              ) : (
                "No Data found please do kyc process"
              )}
            </div>
          ),
          toolTip: "Sort",
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
      },
    };

    return (
      <div className="main">
        <LoadingOverlay
          active={this.state.loader}
          spinner
          text="Please Wait ..."
        >
          <div className="Modalpopup">
            <Modal
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="uploadMod"
              show={this.state.showModal}
              onHide={this.ModalHide}
            >
              <div>
                <input
                  id="search_text"
                  name="search"
                  onChange={this.Addcompany}
                  className="textInput"
                  type="text"
                  placeholder="Enter the email ID of your customer"
                  size="50"
                  value={this.state.email_list}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleClick}
                  disabled={this.state.button_enable}
                >
                  Add Customers
                </Button>
              </div>
              <div className="uploadbottom_data mt-3">
                {this.state.showModal ? (
                  <Button
                    className="cancel_button"
                    variant="light"
                    onClick={this.ModalHide}
                  >
                    Cancel
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Modal>
          </div>
          <SideMenu highlight="dash" />
          <div id="toReview" className="wrapper">
            <div id="invoicesHead" className="row">
              <h5>Dashboard</h5>
              <div className="que_length">
                  Current users in queue is:{this.state.que_length}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={this.ModalShow}
              >
                Invite
              </Button>
            </div>
            <div className="row justify-content-center">
              <div id="welcomeDiv" className="d-flex align-items-center">
                <div className="welcomeuser">
                  
                  <div className="d-flex flex-column  agent_name_div">
                    <h5>Welcome to Video KYC</h5>
                    <h6 className="agent_name">
                      {this.props.agent_email.split("@")[0]}
                    </h6>
                  </div>
                </div>
                <div className="company_input">
                  <Button
                    variant="contained"
                    color="primary"
                    className="consent_button dashboard_button"
                    onClick={() => this.start()}
                    style={{ marginTop: "-6px" }}
                    disabled={!this.props.company}
                  >
                    Start Next Customer
                  </Button>
                </div>
              </div>
            </div>
            <div id="tablediv">
              <div className="tableDetails">
                {
                  <div>
                    <div className="dashboardheader">
                      <h3>Customer KYC Details</h3>
                      <div className="d-flex justify-content-between align-items-center">
                      {this.state.hidden?<input
                        id="search_text"
                        name="search"
                        onChange={(e) => this.Search(e.target.value)}
                        className="textInput"
                        type="text"
                        placeholder="Search for your customer..."
                        size="50"
                      />:""}
                      <div onClick={this.show}><img className="hideimage" src={search} alt="searchimage" /></div>
                      </div>
                    </div>
                    <MUIDataTable
                      data={this.state.data}
                      columns={columns}
                      options={options}
                      onNextPageClick={this.handleNextPageClick}
                      onPreviousPageClick={this.handlePreviousPageClick}
                    />
                    <div className="pagination">
                      <div>
                        <label id="name">Rows per page:</label>
                        <select
                          className="textInputforcreate"
                          onClick={this.handlepageSize}
                        >
                          <option value="10">10</option>
                          <option value="15">15</option> "
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                      {this.state.offset <= 0 ? (
                        <Button
                          disabled
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={this.previous}
                          className="paginationbutton"
                        >
                          {" "}
                          Prev{" "}({this.state.offset})
                        </Button>
                      ) : (
                        <Button
                          disabled={this.state.isLoading}
                          type="button"
                          variant="contained"
                          color="primary"
                          className="paginationbutton"
                          onClick={this.previous}
                        >
                          {" "}
                          Prev({this.state.offset})
                        </Button>
                      )}
                      {this.state.data.length>0?(
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className="paginationbutton"
                        onClick={this.next}
                      >
                        Next{" "}
                      </Button>
                      ):(
                        <Button
                        disabled
                        type="button"
                        variant="contained"
                        color="primary"
                        className="paginationbutton"
                        onClick={this.next}
                      >
                        Next{" "}
                      </Button>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  console.log(state.user.user.company)
  return {
    agent_email: state.user.user.agent_email,
    readuser: state.agent.readuser,
    token: state.user.user.token,
    company: state.user.user.company,
    language: state.user.user.language,
    agent_id:state.user.user.agent_id

  };
};
const mapDispatchToProps = {
  clearExtraction,
  requestAgentService,
  // Agentstart,
  Sendmail,
  socketInit,
  // Download
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
