import { ActionType } from "../Constants";
import { act } from "react-dom/test-utils";
const initialState = {
  isLoggedIn: false,
  user: {},
  loader: false,
  uid: null,
  uid_loader: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionType.LOGOUT:
      return initialState;
    case ActionType.USER_REQUEST:
      return { ...state, loader: true };
    case ActionType.USER_COMPLETE:
      return {
        ...state,
        loader: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case ActionType.USER_COMPLETE_PRE:
      return {
        ...state,
        loader: false,
        isLoggedIn: false,
        user: action.payload,
      };
    case ActionType.USER_FAILED:
      return initialState;
    case ActionType.USER_UID_REQUEST:
      return { ...state, uid_loader: true };
    case ActionType.USER_UID_COMPLETED:
      return { ...state, uid: action.payload, uid_loader: false };
    case ActionType.USER_UID_FAILED:
      return { ...state, uid_loader: false };
    case ActionType.MODAL_CHANGE:
      return {...state,modal:action.modal}
    case ActionType.AGENT_AUTH_SUCCESS:
      return {
        ...state,
        loader: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case ActionType.URL_UPDATE:
      return {...state,url:action.payload}
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};
