export const KYC_SIT= 'https://kycsit.in-d.ai/'
export const IN_D= 'https://one.in-d.ai/'
export const PATH = {
  opentokToken: "api/opentok/opentok_create_token",
  custRegister: "mail_insert",
  agentLogin: "api/queue/loginpass",
  agentOpentok: "api/que/consumer/",
  userOpentok: "api/que/producer",
  bucket: "submit_sign/",
  imageExtraction: "api/fields/",
  classification: "api/class/",
  uid: "upload/uid",
  otp: "otpverify",
  geocoding: "reverseGeocode",
  validation: "api/fields/validation/",
  agentprofilepage: "api/profile/vkyc/",
  profileChanges: "api/profile/validation/vkyc/",
  aadharxml: "api/class/aadhaarxml/",
  create_token: "api/create_token",
  generateotp: "api/class/generate_otp/",
  submitotp: "api/class/submit_otp/",
  screencapture: "submit_video/",
  uidupload: "uidinsertion",
  agentidinsertion: "agentidinsertion",
  statusupdate: "status_update",
  livelines: "api/verification/vkyc/",
  readuser: "readuser",
  readuser_pagination: "readuser_pagination",
  quedelete: "api/que/delete/",
  download: "download",
  decode:"decode",
  mailing:"mailing",
  company_apitoken:"company_apitoken",
  addagent:"api/users/direct/",
  aadharvalidation:"api/validation/",
  delete_data:"delete_data/",
  current_address:"api/current_address/",
  update_remarks:"update_remarks",
  vgg_class:"api/class/",
  lightcheck:"api/lightcheck",
  userdetails:"api/users/get_user_details/",
  faceliveliness:"faceliveliness"
};


export const YouTube_Url={
  IN_D:'https://www.youtube.com/watch?v=Xa6yrHOQCQk',
  Demo:'https://www.youtube.com/watch?v=5a8oXP3EbLo',
  ASG:'https://www.youtube.com/watch?v=vSyVe3k60W8',
  mediassist:"https://www.youtube.com/watch?v=5a8oXP3EbLo"
}
export { default as ActionType } from "./actionTypes";
