import axios from "axios";
import { PATH, ActionType, KYC_SIT,IN_D } from "../../Constants";
import { toast } from "react-toastify";

export const login = (body, history,cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionType.USER_REQUEST });
    let formData = new FormData();
    formData.append("email", body.email);
    formData.append("password", body.password);
    const res = await axios(`${KYC_SIT}${PATH.agentLogin}`, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res)
    if (res.status === 200) {
      console.log(res);
      dispatch({
        type: ActionType.USER_COMPLETE,
        payload: {
          ...res.data,
          type: "agent",
          id: res.data.agent_id,
          agent_email: body.email,
        },
      });
      history.push("/agent/Dashboard");
      cb(true)
    } else {
      toast.error(res.data.desc);
      dispatch({ type: ActionType.USER_FAILED });
      cb(true)
    }
  } catch (error) {
    toast.error("Something went wrong")
    dispatch({ type: ActionType.USER_FAILED });
    cb(true)
  }
};
export const registration = (body, history,cb) => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionType.AGENT_REGISTRATION_REQUEST });
    var formData = new FormData();
    formData.append("name", body.name);
    formData.append("email", body.email);
    formData.append("password", body.password);
    formData.append("company_name", body.company);
    formData.append("phone", body.phone);
    formData.append("language", body.language);
    const res = await axios(`${IN_D}${PATH.addagent}`, {
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(res);
    if (res.status === 201) {
      toast.success("User added Successfully please login");
      dispatch({ type: ActionType.AGENT_REGISTRATION_SUCCESS });
      history.push("/agent");
      cb(true)
    } else {
      console.log(res.data)
      toast.error(res.data.desc);
      dispatch({ type: ActionType.AGENT_REGISTRATION_FAILED });
      cb(true)
    }
  } catch (error) {
    toast.error(error.response.data.message)
    dispatch({ type: ActionType.AGENT_REGISTRATION_FAILED });
    cb(true)
  }
};
export const Sendmail = ({ email_list, hide_modal },cb) => async (dispatch,getState) => {
  const { user = {} } = getState().user;
  const { token = "", company = "" } = user;
  const email_list_empty = [];
  email_list_empty.push(email_list);
  const data1 = {
    email_list: email_list_empty,
    company_name: company,
  };
  try {
    const maindata = await axios(`${KYC_SIT}${PATH.mailing}`, {
      method: "POST",
      data: data1,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        hide_modal();
        toast.success("Successfully sent email to user");
        cb(true)
      }
      if (!res.data.status === "success") {
        toast.error("Error while sending email to customer");
        cb(true)
      }
    });
  } catch (error) {
    console.log(error);
    toast.error("Unable to send Invitation to users")
  }
};
