import { KYC_SIT, PATH, API_ID, ActionType } from "../Constants";
import axios from "axios";
import { createFormHeader } from './agent/opentok'
// opentok sessionid and token getting function
export const getTokens = () => async (dispatch, getState) => {
  const { user = {}, uid } = getState().user;
  const { token,company="",language="" } = user;
  const company_language=`${company}_${language}`
  let formData = new FormData();
  formData.append("company_name",company_language);
  try {
    dispatch({ type: ActionType.OPENTOK_REQUEST });
    const token1 = await (
      await fetch(`${KYC_SIT}${PATH.opentokToken}`, {
        method: "POST",
        body:formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).json();
    console.log(token1)
    dispatch({
      type: ActionType.OPENTOK_COMPLETED,
      payload: { ...token1?.desc, apikey: token1.desc.api_key },
    });
    return new Promise((res) => res({ finished: true }));
  } catch (error) {
    console.log("opnetok tokens catch error ", error);
    dispatch({ type: ActionType.OPENTOK_FAILED });
    return new Promise((res) => res({ finished: true }));
  }
};
export const Delete=()=>async(dispatch,getState)=> {
  const {uid,user={} } = getState().user;
  const {token}=user
  try {
    const delete_data=await axios.get(`${KYC_SIT}${PATH.delete_data}${uid}`,{
      headers: {
      Authorization: `Bearer ${token}`,
    },})
    console.log(delete_data)
  }catch (error) {
    console.log(error)
  }
}
