import React, { useState, useEffect } from 'react';
import { OTSubscriber } from 'opentok-react';
import { useSelector,useDispatch } from "react-redux";
import { emit, socket } from "../../Helpers";
import { ActionType } from '../../Constants';
// let detailIm = {};
export default ({ audio=true, video=true, image, onImage=()=>{}}) => {
    let dispatch=useDispatch()
    let subscriber = React.createRef();
    const {session = {}} = useSelector(state=>state.opentok);
    const {user = {}} = useSelector(state=>state.user);
    const [state, setState] = useState({error: null});
    const [count, setCount] = useState(0);
    const changeState = (key, value) => {
        setState({ ...state, [key] : value })
    }
    useEffect(()=>{
      console.log(image)
      if(image==="capture")
        captureImage()
    },[image])
    const captureImage = ()=>{
      dispatch({type:ActionType.IMAGE_LOADER_REQUEST})
      // if (subscriber&&subscriber.current) {
      //   const currentSubscriber = subscriber.current.getSubscriber();
      //   const imageData = currentSubscriber?currentSubscriber.getImgData():null;
      //    onImage(imageData);
    const { uid = "", user_id = "" } = session;
    const { agent_id = ""} = user;
      emit("agent-to-user", {
        uid, user_id, agent : agent_id,
        alias: "capture_user_camera",
        session_id:session.Session_ID,
        side:"AGENT"
      });
    }
    // useEffect(()=>{
    // if (socket) {
    //   socket.on(`${session.Session_ID}_USER`, (detail) => {
    //     console.log(detail)
    //       if(detail&&detail.session_id===session.Session_ID){
    //         detailIm = detail;
    //         setCount(count+1);
    //       }
    //   })
    // }
    // },[])

    // useEffect(()=>{
    //   if(count===1){
    //     onImage(detailIm.image);
    //     setTimeout(() => {
    //       setCount(0);
    //       detailIm =  {}
    //     }, 3000);
    //   }
    // },[count])
    const loaderStyle = {
      borderTopLeftRadius : 30,
      borderTopRightRadius : 30
    }
    return(
            <OTSubscriber
              ref={subscriber}
              properties={{
                subscribeToAudio: audio,
                subscribeToVideo: video,
                width: 612,
                height: 539,
              }}
              retry={true}
              onError={err => console.log("error occured in subscriber ", err)}
            />
    )
}