import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AadharComponent from "./AadharComponent";
import ProfileComponent from "./ProfileComponent";

const components = [
  (props) => <AadharComponent {...props} tab={1} heading="Aadhaar" max={4} />,
  (props) => <AadharComponent {...props} tab={2} heading="OVD" max={4} />,
  (props) => <AadharComponent {...props} tab={3} heading="Signature" max={4} />,
  (props) => <AadharComponent {...props} tab={4} heading="Liveliness" max={4} />,
];

export default (props) => {
  const { steps = {} } = useSelector(({ agent }) => agent);

  return (
    <div className="steps-render-container">
      {components[steps.active]
        ? components[steps.active](props)
        : components[0](props)}
    </div>
  );
};
