import { Loader } from "../Components";

export default {
  USER_REQUEST: "USER_REQUEST",
  USER_COMPLETE: "USER_COMPLETE",
  USER_COMPLETE_PRE: "USER_COMPLETE_PRE",
  USER_FAILED: "USER_FAILED",
  LOGOUT: "LOGOUT",
  RESET: "RESET",
  AADHAR_NUMBER: "AADHAR_NUMBER",

  OPENTOK_REQUEST: "OPENTOK_REQUEST",
  OPENTOK_COMPLETED: "OPENTOK_COMPLETED",
  OPENTOK_FAILED: "OPENTOK_FAILED",

  USER_SERVICE_REQUEST: "USER_SERVICE_REQUEST",
  USER_SERVICE_COMPLETED: "USER_SERVICE_COMPLETED",
  USER_SERVICE_FAILED: "USER_SERVICE_FAILED",
  USER_RE_STREAM: "USER_RE_STREAM",
  USER_RE_SESSION: "USER_RE_SESSION",

  AGENT_SERVICE_REQUEST: "AGENT_SERVICE_REQUEST",
  AGENT_SERVICE_COMPLETED: "AGENT_SERVICE_COMPLETED",
  AGENT_SERVICE_FAILED: "AGENT_SERVICE_FAILED",
  AGENT_VIDEO_COMPONENTS: "AGENT_VIDEO_COMPONENTS",

  CHANGE_AADHAR_STEPS: "CHANGE_AADHAR_STEPS",
  CHANGE_DOC_TYPE: "CHANGE_DOC_TYPE",

  AADHAR_XML_FIELDS_FAILED: "AADHAR_XML_FIELDS_FAILED",
  AADHAR_XML_FIELDS_COMPLETED: "AADHAR_XML_FIELDS_COMPLETED",
  AADHAR_XML_FIELDS_REQUEST: "AADHAR_XML_FIELDS_REQUEST",

  CLEAR_EXTRACTION_STEPS: "CLEAR_EXTRACTION_STEPS",
  AADHAR_CAPTURE: "AADHAR_CAPTURE",
  PAN_CAPTURE: "PAN_CAPTURE",

  GEO_LOCATION: "GEO_LOCATION",
  RESET_AADHAR: "RESET_AADHAR",

  PROFILE_PAGE_REQUEST: "PROFILE_PAGE_REQUEST",
  PROFILE_PAGE_COMPLETED: "PROFILE_PAGE_REQUEST_COMPLETED",
  PROFILE_PAGE_FAILED: "PROFILE_PAGE_FAILED",

  AGENT_PROFILE_PAGE_REQUEST: "AGENT_PROFILE_PAGE_REQUEST",
  AGENT_PROFILE_PAGE_COMPLETED: "AGENT_PROFILE_PAGE_COMPLETED",
  AGENT_PROFILE_PAGE_FAILED: "AGENT_PROFILE_PAGE_FAILED",
  AGENT_PROFILE_PAGE_SUCCESS: "AGENT_PROFILE_PAGE_SUCCESS", // just to make loader false

  LIVELINESS_PAGE_REQUEST: "LIVELINESS_PAGE_REQUEST",
  LIVELINESS_PAGE_SUCCESS: "LIVELINESS_PAGE_SUCCESS",
  LIVELINESS_PAGE_FAILED: "LIVELINESS_PAGE_FAILED",

  OTP_PAGE: "OTP_PAGE",
  OTP_PAGE_REQUEST: "OTP_PAGE_REQUEST",
  SIGN_UPLOAD: "SIGN_UPLOAD",
  SAVE_AADHAR_REQUEST: "SAVE_AADHAR_REQUEST",
  SAVE_AADHAR_COMPLETE: "SAVE_AADHAR_COMPLETE",
  SAVE_AADHAR_FAILED: "SAVE_AADHAR_FAILED",

  IMAGE_EXTRACTION_REQUEST: "IMAGE_EXTRACTION_REQUEST",
  IMAGE_EXTRACTION_COMPLETED: "IMAGE_EXTRACTION_COMPLETED",
  IMAGE_EXTRACTION_FAILED: "IMAGE_EXTRACTION_FAILED",

  USER_UID_REQUEST: "USER_UID_REQUEST",
  USER_UID_COMPLETED: "USER_UID_COMPLETED",
  USER_UID_FAILED: "USER_UID_FAILED",

  SCREEN_CAPTURE_REQUEST: "SCREEN_CAPTURE_REQUEST",
  SCREEN_CAPTURE_COMPLETED: "SCREEN_CAPTURE_COMPLETED",
  SCREEN_CAPTURE_FAILED: "SCREEN_CAPTURE_FAILED",

  UID_UPLOAD: "UID_UPLOAD",

  COMPANY_NAME_UPDATE: "COMPANY_NAME_UPDATE",

  USER_STATUS_UPDATE: "USER_STATUS_UPDATE",

  AADHAR_NUMBER_SENT_OTP_REQUEST: "AADHAR_NUMBER_SENT_OTP_REQUEST",
  AADHAR_NUMBER_SENT_OTP_COMPLETE: "AADHAR_NUMBER_SENT_OTP_COMPLETE",
  AADHAR_NUMBER_SENT_OTP_FAILED: "AADHAR_NUMBER_SENT_OTP_FAILED",

  LIVELINESS_DATA_FROM_USER: "LIVELINESS_DATA_FROM_USER",

  AGENTRESET: "AGENTRESET",
  DASHBOARD_READ_USER: "DASHBOARD_READ_USER",
  DECODE_TOKEN_COMPLETED: "DECODE_TOKEN_COMPLETED",
  DECODE_TOKEN_FAIL: "DECODE_TOKEN_FAIL",

  AADHAR_VALIDATION: "AADHAR_VALIDATION",

  AADHARFRONT_CARD: "AADHARFRONT_CARD",

  AGENT_OVERRIDE: "AGENT_OVERRIDE",

  IMAGE_LOADER_REQUEST: "IMAGE_LOADER_REQUEST",
  IMAGE_LOADER_SUCCESS: "IMAGE_LOADER_SUCCESS",
  BORDER_CHANGE: "BORDER_CHANGE",

  AGENT_AUTH_SUCCESS: "AGENT_AUTH_SUCCESS",
  AGENT_AUTH_REQUEST: "AGENT_AUTH_REQUEST",
  AGENT_AUTH_FAILED: "AGENT_AUTH_FAILED",

  AGENT_SCREEN_CAPTURE_STOP_MSG:"AGENT_SCREEN_CAPTURE_STOP_MSG",

  AADHAAR_FRONT_IMAGE:"AADHAAR_FRONT_IMAGE",

  CURRENT_ADDRESS_SUCCESSFUL: "CURRENT_ADDRESS_SUCCESSFUL",
  CURRENT_ADDRESS_FAILURE: "CURRENT_ADDRESS_FAILURE",
  MODAL_CHANGE:"MODAL_CHANGE",
  LIGHTNING_CONDITION_REQUEST:"LIGHTNING_CONDITION_REQUEST",
  LIGHTNING_CONDITION_SUCCESS:"LIGHTNING_CONDITION_SUCCESS",
  LIGHTNING_CONDITION_FAIL:"LIGHTNING_CONDITION_FAIL",

  AGENT_REGISTRATION_REQUEST:"AGENT_REGISTRATION_REQUEST",
  AGENT_REGISTRATION_SUCCESS:"AGENT_REGISTRATION_SUCCESS",
  AGENT_REGISTRATION_FAILED:"AGENT_REGISTRATION_FAILED",

  AGENT_TOKEN_SUCCESS:"AGENT_TOKEN_SUCCESS",
  
  URL_UPDATE:"URL_UPDATE",

  SCREENCAPTURE_CAPTURE_AUTOMATE:"SCREENCAPTURE_CAPTURE_AUTOMATE"
};
