import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HighlightOffOutlined, CheckCircle, Cancel } from "@material-ui/icons";
import {agentProfileData,changeProfileData,Agentidinsertion,Statusupdate,Remarks} from "../../Actions";
import { Button, Switch, Backdrop, CircularProgress } from "@material-ui/core";
import { emit, socketInit, socket } from "../../Helpers";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const Header = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!socket) {
      socketInit()
    }
    dispatch(Agentidinsertion());
    dispatch(Statusupdate())
  }, []);
  const { name = "" } = props;
  return (
    <div className="profile-header">
      <span className="profile-header-title"> {name} - Profile Preview</span>
      <div className="flex1" />
      <HighlightOffOutlined className="cancel-modal" onClick={props.onClose} />
    </div>
  );
};

const DetailSection = (props) => {
  const {
    agentprofile = {},
    doctype = "pan",
    steps = {},
    extraction = {},
    aadhar_front={}
  } = useSelector(({ agent }) => agent);
  const {
    NAME = "",
    FATHER_SPOUSE_NAME = "",
    ADDRESS = "",
    AADHAR_NUMBER = "",
    DATE_OF_BIRTH = "",
    face,
    PAN = "",
    XML_PHOTO="",
    CURRENT_ADDRESS="",
    GENDER=""
  } = agentprofile;
  const {name=""}=aadhar_front
  const { address="" } = extraction && extraction[0] ? extraction[0] : {};
  const {number="" } = extraction && extraction[1] ? extraction[1] : {};
console.log(number)
  const profile = XML_PHOTO ? `data:image/png;base64, ${XML_PHOTO}`:face ? `data:image/png;base64, ${face}` : "";
  return (
    <div className="modal-details-section">
      <div className="modal-profile-pic-side center">
        {!profile ? (
          <div className="modal-profile-pic-view center">
            <span>Photo</span>
          </div>
        ) : (
          <img src={profile} className="modal-profile-pic-view" />
        )}
      </div>
      <div className="modal-details-side center center">
        <div className="details-left-side">
          <div className="details-info-view">
            <span className="detail-title">Name:</span>
            <span className="detail-value">{NAME||name}</span>
          </div>
          <div className="details-info-view">
            <span className="detail-title">PAN:</span>
            <span className="detail-value">{PAN||number}</span>
          </div>
          <div className="details-info-view">
            <span className="detail-title">DOB:</span>
            <span className="detail-value">{DATE_OF_BIRTH}</span>
          </div>
          {GENDER?<div className="details-info-view">
            <span className="detail-title">GENDER:</span>
            <span className="detail-value">{GENDER}</span>
          </div>
          :""}
        </div>
        <div className="details-right-side">
          <div className="details-info-view">
            <span className="detail-title">Father Name:</span>
            <span className="detail-value">{FATHER_SPOUSE_NAME}</span>
          </div>
          <div className="details-info-view">
            <span className="detail-title">Aadhaar Number:</span>
            <span className="detail-value">
              {AADHAR_NUMBER
                ? AADHAR_NUMBER.replace(/\d(?=\d{4})/g, "X")
                :"NA"}
            </span>
          </div>
          <div className="details-info-view">
            <span className="detail-title">Address:</span>
            <textarea type="textValue" className={`aadhar-input text-area name-input detail-value`} value={ADDRESS||address}/>
          </div>
          {CURRENT_ADDRESS?<div className="details-info-view">
          <span className="detail-title">Current Address:</span>
          <textarea type="textValue" className={`aadhar-input text-area name-input detail-value`} value={CURRENT_ADDRESS}/>
        </div>
        :""}
        </div>
      </div>
    </div>
  );
};

const CheckBox = (props) => {
  return (
    <Switch
      checked={props.checked}
      onClick={props.onCheck}
      name="checkedA"
      inputProps={{ "aria-label": "secondary checkbox" }}
    />
  );
};

const Verification = (props) => {
  const { state, changeState = () => {} } = props;
  return (
    <div className="verification-views center">
      <div className="check-status center">
        <span>Liveliness Check</span>
        <div className="flex1" />
        <CheckBox
          initialChecked={state.livelines}
          checked={state.livelines}
          onCheck={(checked) => changeState("livelines", checked)}
        />
      </div>
      <div className="width-10" />
      <div className="check-status center">
        <span>Cross Validation</span>
        <div className="flex1" />
        <CheckBox
          initialChecked={state.cross_check}
          checked={state.cross_check}
          onCheck={(checked) => changeState("cross_check", checked)}
        />
      </div>
    </div>
  );
};

const FaceMatchResult = ({ state = {}, changeState = () => {} }) => {
  
  return (
    <div className="padd-b-20 width-100 center">
      <div className="face-match-view">
        <span>Face Match Result</span>
        <div className="face-match-sub-views">
          <div className="face-match-sub-view ">
            <span>PAN & Aadhaar Match</span>
            <CheckBox
              initialChecked={state.face_match}
              checked={state.face_match}
              onCheck={(checked) => changeState("face_match", checked)}
            />
          </div>
          <div className="face-match-sub-view">
            <span>PAN & Photo Match</span>
            <CheckBox
              initialChecked={state.face_match}
              checked={state.face_match}
              onCheck={(checked) => changeState("face_match", checked)}
            />
          </div>
          <div className="face-match-sub-view">
            <span>Aadhaar & Photo Match</span>
            <CheckBox
              initialChecked={state.face_match}
              checked={state.face_match}
              onCheck={(checked) => changeState("face_match", checked)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Buttons = (props) => {
  console.log("running")
  const onReject = () => {};
  const onAccept = () => {};
  useEffect(() => {
    if (!socket) socketInit();
  });
  return (
    <div className="profile-footer-buttons">
    {/*
      <Button
        variant="outlined"
        className="profile-reject-button"
        onClick={onReject,props.onClose}
      >
        Close
      </Button>
      */}
      <Button
        variant="contained"
        className="profile-accept-button"
        onClick={(onAccept, props.onClick)}
      >
        Accept Application
      </Button>
    </div>
  );
};
const Textarea=()=> {
  const dispatch = useDispatch();
  const [remark,setRemark]=useState("")
  const [enable,setEnable]=useState(false)
  const handleRemark=(e)=> {
    setRemark(e.target.value)
    if (e.target.value.length>3) {
      setEnable(true)
    }else {
      setEnable(false)
    }
  }
  const SaveRemarks=()=> {
    dispatch(Remarks(remark));
  }

  return (
    <div className="profile-view-footer-hairline d-flex justify-content-center flex-column textarea">
    <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Remarks...." className="mb-2" onChange={handleRemark}/>
    {enable?
    <Button variant="contained" color="primary" className="saveremarks" onClick={SaveRemarks}>Save</Button>:""}
    </div>
  )
}
const LoaderOverlay = () => (
  <div className={"backdrop center"}>
    <CircularProgress color="inherit" />
  </div>
);
export default (props) => {
  const dispatch = useDispatch();
  const { agentprofile = {}, extraction = {}, ploader = false } = useSelector(
    ({ agent }) => agent

  );
  const {
    livelines = false,
    cross_check = false,
    face_match = false,
  } = agentprofile ? agentprofile : {};
  const [state, setState] = useState({
    livelines,
    cross_check,
    face_match,
  });
  useEffect(()=> {
    setState({livelines,cross_check,face_match})
  },[agentprofile])
  useEffect(() => {
    dispatch(agentProfileData());
  }, []);

  const { NAME = "" } = agentprofile&&agentprofile ? agentprofile : {}

  const changeState = (key, value) => {
    setState({ ...state, [key]: !state[key] });
  };
  const SaveProfileDetails = () => {
    dispatch(changeProfileData(state,props.onClose));
  };
  return (
    <div className="profile-container">
      {ploader && agentprofile ? (
        <LoaderOverlay />
      ) : (
        <>
          <Header name={NAME} onClose={props.onClose} />
          <DetailSection />
          <Verification changeState={changeState} state={state} />
          <FaceMatchResult changeState={changeState} state={state} />
          <Textarea />
          <Buttons onClick={SaveProfileDetails} onClose={props.onClose} />
        
        </>
      )}
    </div>
  );
};
