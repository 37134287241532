import React, { Component } from "react";
import Extraction from "../../Components/Extraction/Extraction"
import { connect } from 'react-redux'
import { changeHeaderonAadhar } from '../../Actions'
class AadharExtraction extends Component {
      constructor(props) {
        super(props);
        this.props.changeHeaderonAadhar({title:"Extraction",visible:true,border_visible:true,border:20})
      }
      render() {
        return (
          <div>
              <Extraction type="aadhar"/>
          </div>
        )
      }
    }
export default connect(()=>({}),{changeHeaderonAadhar})(AadharExtraction);
