import { ActionType } from '../Constants'

const initialState={
    aadharotp:"",
    client_id:"",
    liveloader:false,
    xml_loader:false,
    user_ploader:false,
    aadhar_otp_loader:false

}
export default (state = initialState, action) => {
    console.log(action)
    switch (action.type) {
      case ActionType.AADHAR_NUMBER_SENT_OTP_REQUEST:
        return {...state,client_id:{},aadhar_otp_loader:true}
      case ActionType.AADHAR_NUMBER_SENT_OTP_COMPLETE:
        return {...state,client_id:action.payload,aadhar_otp_loader:false}
      case ActionType.AADHAR_NUMBER_SENT_OTP_FAILED:
        return {...state,client_id:{},aadhar_otp_loader:false}
      case ActionType.AADHAR_XML_FIELDS_REQUEST:
        return {...state,xml_loader:true}
      case ActionType.AADHAR_XML_FIELDS_COMPLETED:
        return {...state,aadhar_xml:action.payload,xml_loader:false}
      case ActionType.AADHAR_XML_FIELDS_FAILED:
        return {...state,xml_loader:false}
      case ActionType.GEO_LOCATION:
        return {...state,location:action.payload}
      case ActionType.AADHAR_CAPTURE:
        return {...state,aadhar_xml:action.payload,type:action.typeofdata}
      case ActionType.PAN_CAPTURE:
        return {...state,capture:action.payload}
      case ActionType.RESET:
        return initialState
      case ActionType.RESET_AADHAR:
        return initialState
      case ActionType.PROFILE_PAGE_REQUEST:
        return {...state,profilepage:{},user_ploader:true}
      case ActionType.PROFILE_PAGE_FAILED:
        return {...state,profilepage:{},user_ploader:false}
      case ActionType.PROFILE_PAGE_COMPLETED:
        return {...state,profilepage:action.payload,user_ploader:false}
      case ActionType.LIVELINESS_PAGE_REQUEST:
        return {...state,liveloader:true}
      case ActionType.LIVELINESS_PAGE_SUCCESS:
        return {...state,liveloader:true,liveliness:action.payload}
      case ActionType.LIVELINESS_PAGE_FAILED:
        return {...state,liveloader:false}
      case ActionType.SIGN_UPLOAD:
        return {...state,sign:action.payload}
      case ActionType.OTP_PAGE_REQUEST:
        return {...state,otpverify:action.payload}
      case ActionType.USER_STATUS_UPDATE:
        return {...state,statusupdate:action.payload}
      case ActionType.CURRENT_ADDRESS_SUCCESSFUL:
        return {...state,currentaddress:action.payload}
      case ActionType.CURRENT_ADDRESS_FAILURE:
        return {...state}
      default:
        return state;
    }
};

