import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Publisher from "./Publisher";
import Subscriber from "./Subscriber";
import { Loader } from "../../Components";
import { requestAgentService, Screencapture,Delete } from "../../Actions";
import { OTStreams, OTSession, OTPublisher, OTSubscriber } from "opentok-react";
import CallGreenImage from "../../Images/call.svg";
import { Mic, Videocam } from "@material-ui/icons";
import { emit, socketInit, socket } from "../../Helpers";
import { useHistory } from 'react-router-dom';
import { Agentreset } from '../../Actions'
import '../../Components/Header/Header.css'
import DialogComponent from '../../Components/DialogComponent'
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText,Slide,Button} from "@material-ui/core";
import { Timer } from "../../Components/Timer/timer";
import { toast } from "react-toastify";

let OtSessionRef 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = ({ onChange = () => {}, state }) => {
  return (
    <div className="video-footer center">
      <div className="flex-p5" />
      <span className="footer-time-display">
        <Timer />
      </span>
      <div className="footer-icons flex-p5">
        <div
          className={`round-tool-icon center ${
            state.video ? "round-tool-icon-pressed" : ""
          }`}
          onClick={() => onChange("video")}
        >
          <Videocam />
        </div>
        <div
          className={`round-tool-icon center ${
            state.audio ? "round-tool-icon-pressed" : ""
          }`}
          onClick={() => onChange("audio")}
        >
          <Mic />
        </div>
      </div>
    </div>
  );
};

let recorder,
  stream,
  timer = null;
const Disconnect = ({ open, handleClose, disconnectOpentok,url}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Redirect=()=> {
    dispatch(Agentreset());
    disconnectOpentok();
    window.open(url,"_self")
    // history.push("/agent")
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Disconnect"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          No users are present in session please disconnect.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {handleClose(); Redirect();}} color="primary">
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
};
let numberOfConnections=0
export default ({ onImage, image }) => {
  const [capture, setCapture] = useState(false);
  const [connected, setConnected] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [disModal, setDiscModal] = useState(false);
  const { session = {} } = useSelector(({ opentok }) => opentok);
  const { loader = false,statusupdate={},stoprecording="",steps,captureautomate=false } = useSelector(({ agent }) => agent);
  console.log(steps)
  const {desc=""}=statusupdate
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { userlocation="",url="" } = session;
  const [modal,setLocationModal]=useState(false)
  const [videoState,setVideoState]=useState(null)
  const disconnect = () => {
    setDiscModal(true);
  };
  const disConnectOpentok = () =>{
    if (OtSessionRef) OtSessionRef.sessionHelper.disconnect();
      // stopvideo()
    OtSessionRef && OtSessionRef.current && OtSessionRef.current.sessionHelper.disconnect();
    }
  const agentcapturescreen = async (videoSource) => {
    console.log("starting video record ...")
    try {
      setCapture(true);
      let chunks = [];
      stream = await navigator.mediaDevices.getDisplayMedia({
        video: { mediaSource: "screen",audio: true  },
      });
      recorder = new MediaRecorder(stream);
      console.log(recorder)
        console.log(recorder.state)
        setVideoState(recorder.state)
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = (e) => {
        const completeBlob = new Blob(chunks, { type: chunks[0].type });
        let fileObject = new File([completeBlob], "video.webm", {
          type: "video/webm",
        });
        console.log('fileObject',fileObject);
        dispatch(Screencapture(fileObject,history));
      };
      recorder.start();
      setData(recorder);
    } catch (error) {
      console.log("catch video error ", error);
    }
  };
  console.log(recorder,"recorderrrrrrrrrrrrrrrrrrrrrrrrrrr")
  const PauseVideo=()=> {
    // alert("pause")
    console.log("Pause video..............")
    recorder&&recorder.pause()
    console.log(recorder.state)
    setVideoState(recorder.state)
  }
  
  const Resume=()=> {
    // alert("Resume")
    console.log("Resume video..............")
    recorder.resume()
    setVideoState(recorder.state)
    console.log(recorder.state)
  }
  console.log(capture,steps.active,videoState,captureautomate)
  useEffect(()=> {
    if (capture&&steps.active===0&&captureautomate) {
        PauseVideo()
        console.log("data")
    }else if(capture&&steps.active!==0) {
      Resume()
    }
  },[capture,steps.active,videoState,captureautomate])
  const stopvideo = () => {
    console.log("stoping video recording ...")
    data&&data.stop();
    setCapture(false);
  };
  const handleSessionRef = (ref) => {
    if (!socket){
    dispatch(socketInit())
    }
    OtSessionRef = ref;
    console.log("session session ", OtSessionRef);
    let session = OtSessionRef&&OtSessionRef.sessionHelper&&OtSessionRef.sessionHelper.session&&
        OtSessionRef.sessionHelper.session;
    if(session){
      session.on("connectionCreated",function(e){
        console.log("connectionCreated",e);
        numberOfConnections+=1
        setTimeout(() => {
        if (numberOfConnections>=3){
            return setConnected(true);
        }
        else if (numberOfConnections<=2){
          setLocationModal(false)
          setTimeout(() => {
            setDiscModal(true);
            // if (desc!=="completed"){
            //   console.log("deleteing google bucket data")
            //     dispatch(Delete())
            // }
          }, 1000);
        }
        }, 29000);

        console.log(numberOfConnections)

      });
      session.on("connectionDestroyed", e=>{
        numberOfConnections-=1
        console.log("connectionDestroyed ",e);
        setTimeout(() => {
        setDiscModal(true)
        // if (desc!=="completed"){
        //   console.log("deleteing google bucket data")
        //   dispatch(Delete())
        // }
        }, 30000);
        setConnected(false);
      })
    }
      
}
console.log(numberOfConnections,"numberOfConnectionsnumberOfConnectionsnumberOfConnectionsnumberOfConnections")

useEffect(()=>{
  if(connected) {
  setConfirm(true)
  } 
  // else stopvideo()
},[connected])
useEffect(()=>{
  console.log(stoprecording)
  console.log(connected)
    if (stoprecording==="stop") {
      console.log("recording is being stoped")
      dispatch(Agentreset());
      stopvideo()
    } 
    if (recorder===undefined&&stoprecording==="stop"){
      disConnectOpentok()
      window.open(url,"_self")
    }
  // else stopvideo()
},[stoprecording])
useEffect(()=> {
  setLocationModal(true)
},[])
const handleConfirm = () =>{
  agentcapturescreen();
  setConfirm(false)
}
const handleClose=()=> {
  setLocationModal(false)
}
const handleClick=()=> {
  setLocationModal(true)
}

  const [state, setState] = useState({
    audio: true,
    video: true,
  });
  const changeState = useCallback(
    (key) => {
      setState({ ...state, [key]: !state[key] });
    },
    [state]
  );
  if (modal&&userlocation==="outside_india") {
      return <DialogComponent desc={"User is not form india,if you want to proceed the KYC Go ahead otherwise disconnect the call."} header={"Not in India"} modal={modal} handleClick={handleClick} handleClose={handleClose} buttonname={"OK"} Refresh={handleClose}/>
  }
  return (
    <div className="video-call-container">
      {loader ? (
        <Loader />
      ) : session && session.token && session.apikey && session.Session_ID ? (
        <OTSession
          ref={handleSessionRef}
          apiKey={session.apikey}
          sessionId={session.Session_ID}
          token={session.token}
        >
          <div className="publisher-container center">
            <div className="subscriber">
              <OTStreams>
                <Subscriber
                  audio={state.audio}
                  image={image}
                  onImage={onImage}
                  video={state.video}
                />
              </OTStreams>
            </div>
            <div className="disconnect-call-pre center" onClick={disconnect}>
              <div className="disconnect-call center">
                <img src={CallGreenImage} />
              </div>
            </div>
          </div>
          <div className="subscriber-view">
            <Publisher />
          </div>
        </OTSession>
      ) : null}
      {session && session.token && (
        <Footer onChange={changeState} state={state} />
      )}

      <Disconnect open={disModal} handleClose={() => setDiscModal(false)} disconnectOpentok={disConnectOpentok} url={url}/>
      {session && session.token && (
        <agentcapturescreen>
          <div className="screencapture">
            {/*{session && session.token && session.apikey && session.Session_ID?agentcapturescreen():""}
          <Button
            disabled={capture}
            variant="contained"
            color="primary"
            className="liveliness_button"
            onClick={agentcapturescreen}
          >
            Start Screen Capture
          </Button>

          {capture?
            <div style={{width:"100%"}}>
          {videoState==="paused"?
            <Button
              onClick={Resume}
              variant="contained"
              color="primary"
              className="liveliness_button"
            >
            Resume Screencapture Video
            </Button>:
            <Button
              onClick={PauseVideo}
              variant="contained"
              color="primary"
              className="liveliness_button"
            >
            Pause Screencapture Video
            </Button>
        }</div>
        :""}
          */}

          </div>
        </agentcapturescreen>
      )}
      <VideoDialog open={confirm} handleClose={()=>setConfirm(false)} handleConfirm={handleConfirm} />
    </div>
  );
};
const VideoDialog = props => {

  return (
    <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Need you confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm to record the session
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  )
}
