import React,{useState} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { logout } from "../Actions";

const DialogComponent=({name,desc,header,buttonname,Refresh,logout,handleClick,handleClose,modal})=> {
    return (
        <div>
            <div >
            {!modal?
                <div className="logout" onClick={handleClick}>
                Logout
                </div>
                :""}
                <Dialog
                    open={modal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="logout_popup"
                >
                    <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    {name}{desc}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    {handleClick&&logout?
                        <React.Fragment>
                    <Button onClick={logout} color="primary" className="logout">
                        Logout
                    </Button>
                    <div>|</div>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    </React.Fragment>
                    :<Button onClick={Refresh} color="primary">
                    {buttonname}
                    </Button>}
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default DialogComponent
