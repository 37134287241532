import React, { Component } from "react";
import "./register.css";
import "react-phone-input-2/lib/style.css";
import { Redirect, NavLink } from "react-router-dom";
import IndLogo from "../../Images/IN-D-Logo/IND.png";

import TextField from "@material-ui/core/TextField";
import password_hide from '../../Images/password_hide.png'
import password_show from '../../Images/password_show.png'
import { connect } from "react-redux";
import { registration } from "../../Actions/agent";
import Loader from '../../Components/Loader'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const initialState={
  phone: "",
  name: "",
  email: "",
  password: "",
  redirect: false,
  tokenRed: false,
  company: "",
  phoneError: "",
  emailError: "",
  passwordError: "",
  registrationsuccess: false,
  registrationfail: false,
  error:false,
  hidden: true,
  nameError:"",
  namebutton:true,
  companyError:"",
  companybutton:true,
  emailbutton:true,
  phonebutton:true,
  passwordbutton:true,
  passwordError:"",
  language:"",
  open:false,
  languagebutton:true,
  languageError:""

};
class Register extends Component {
  constructor() {
    super();
    this.state = initialState
  }
  toggleShow=()=> {
    this.setState({ hidden: !this.state.hidden });
  }
  onNamechange=(e)=> {
    const name=e.target.value
    const regex = new RegExp("^[a-zA-Z ]+$");
    if (name.length!==0){
      if (regex.test(name)) {
        this.setState({...this.state,nameError:"",name:name,namebutton:false})
      }else {
        this.setState({...this.state,nameError:"Please enter only letters",namebutton:true})
      }
    }else {
      this.setState({...this.state,nameError:"Field Required",namebutton:true})
    }
  }
  onCompanychange=(e)=> {
    const company=e.target.value
    const regex = new RegExp("^[a-zA-Z ]+$");
    if (company.length!==0){
      if (regex.test(company)) {
        this.setState({...this.state,companyError:"",company:company,companybutton:false})
      }else {
        this.setState({...this.state,companyError:"Please enter only letters",companybutton:true})
      }
    }else {
      this.setState({...this.state,companyError:"Field Required",companybutton:true})
    }
  }
  handlephoneChange=(e)=> {
    const phone = e.target.value
    const re = /^[0-9\b]+$/;
    if (phone.length!==0){
      if (re.test(phone)&&phone.length===10) {
         this.setState({...this.state,phone: phone,phonebutton:false,phoneError:"",})
      }
      else {
      this.setState({...this.state,phoneError:"Phone number should be in 10 digits and should contain only numbers",phonebutton:true})
    }
  }else {
    this.setState({...this.state,phoneError:"Field Required",phonebutton:true})
  }
}
  
  onEmailChange=(e)=> {
    const email=e.target.value
    if (email.length!==0){
    if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
      this.setState({...this.state,emailError:"",email:email,emailbutton:false})
    }else {
      this.setState({...this.state,emailError:"Email is not valid",emailbutton:true})
    }
  }else {
    this.setState({...this.state,emailError:"Field Required",emailbutton:true})
  }
  }
  handlePassword=(e)=> {
    const pass=e.target.value
    if (pass.length>0) {
      this.setState({...this.state,passwordbutton:false,passwordError:"",password:pass})
    }else {
      this.setState({...this.state,passwordbutton:true,passwordError:"Please Enter your Password",password:pass})
    }
  }
  onSignUp = (event) => {
    event.preventDefault()
    const body = { name:this.state.name,email: this.state.email, password: this.state.password,company:this.state.company,phone:this.state.phone,language:this.state.language };
    this.props.registration(body, this.props.history,(success)=> {
      console.log(success)
      if (success) {
        this.setState(initialState)
      }
    });
  };

     languageChange = (event) => {
    console.log(event.target.value)
    const language=event.target.value
    if (language.length>0) {
      this.setState({language:language,languagebutton:false,languageError:''})
    }else {
      this.setState({language:language,languagebutton:true,languageError:"Please Select any one of the language"})
    }
  };

   handleClose = () => {
    this.setState({open:false})
  };

   handleOpen = () => {
    this.setState({open:true})
  };

  render() {
    console.log(this.state.language)
    const { loader = false } = this.props;
    const {companybutton,namebutton,emailbutton,phonebutton,passwordbutton,languagebutton}=this.state
    console.log(loader)
    console.log(this.state);
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/agent/Login",
          }}
        />
      );
    }
    if (this.state.tokenRed) {
      return (
        <Redirect
          to={{
            pathname: "/agent",
          }}
        />
      );
    }
    return (
      <div>
      {loader?<Loader msg="Please wait we are Registering your account......"/>:
      <React.Fragment>
       <div className="header d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
                <div>Registration</div>
            </div>
            <img src={IndLogo} alt="ind-logo" />
        </div>
        <div id="signUpBody" className=" row justify-content-center">
          <div className="row justify-content-center signUpForm">
            <div id="data" className="col-md-6 col-sm-12">
              <h1 className="signup">Registration</h1>
              <form className="signup_form" onSubmit={this.onSignUp}>
                <label>Name</label>
                <TextField
                  id="standard-name"
                  name="name"
                  onChange={this.onNamechange}
                  className="underline"
                  type="text"
                />
                <div className="errormsg">{this.state.nameError}</div>
                <label>Email Id</label>
                <TextField
                  id="standard-name"
                  name="email"
                  onChange={this.onEmailChange}
                  className="underline"
                  type="text"
                />
                <div className="errormsg">{this.state.emailError}</div>
                <div >
                    <label>Password</label>
                    <TextField
                    id="standard-name"
                    name="password"
                    onChange={this.handlePassword}
                    className="underline"
                    type={this.state.hidden ? 'password' : 'text'}
                    >
                    </TextField>
                    <div onClick={this.toggleShow} className="hide_div">
                    {this.state.hidden?
                      <img src={password_hide} alt="hide" className="hide" />
                      :
                      <img src={password_show} alt="hide" className="hide" />
                    }
                    </div>
                </div>
                <div className="errormsg">{this.state.passwordError}</div>
                <label>Phone</label>
                <TextField
                id="standard-name"
                  name="phone"
                  onChange={this.handlephoneChange}
                  className="underline"
                  type="text"
                />
                <div className="errormsg">{this.state.phoneError}</div>
                <label>Company</label>
                <TextField
                  id="standard-name"
                  name="company"
                  onChange={this.onCompanychange}
                  className="underline"
                  type="text"
                />
                <div className="errormsg">{this.state.companyError}</div>
                <label>Language</label>
                <FormControl className="underline mb-3" id="standard-name">
                  <InputLabel 
                  >Language</InputLabel>
                  <Select
                    open={this.state.open}
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    value={this.state.language}
                    onChange={this.languageChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem  value={"English"}>English</MenuItem>
                    <MenuItem value={"Hindi"}>Hindi</MenuItem>
                  </Select>
                </FormControl>
                <div className="errormsg">{this.state.languageError}</div>
                <button type="submit" id="signUpButton"  disabled={companybutton||namebutton||phonebutton||emailbutton||passwordbutton||languagebutton}>
                  Sign up
                </button>
                <div id="newFull" className="row">
                  {" "}
                  <hr className="register-hr" />
                  <div id="new">Already registered?</div>
                  <hr className="register-hr" />{" "}
                </div>
                <NavLink to="/agent/Login" style={{ textDecoration: "none" }}>
                  <button id="createButton">Login here</button>
                </NavLink>
              </form>
            </div>
          </div>
        </div>
        </React.Fragment>
                  }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.agent.authloader,
  };
};
const mapDispatchToProps = {
  registration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);




