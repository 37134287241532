import React, { useState } from 'react';
import { OTPublisher } from 'opentok-react';


export default ({audio=true, video=true}) => {
  return (
    <div className="publisher-view">
    {/*
      <OTPublisher
        properties={{
          publishAudio: true,
          publishVideo: true,
          width: 100,
          height: 113
        }}
        // eventHandlers={Events}
      />
      */}
      <OTPublisher
        properties={{
          publishAudio: true,
          publishVideo: true,
          width: 100,
          height: 113
        }}
        // eventHandlers={Events}
      />
    </div>
  )
}