// import React from 'react'
// import "./Header.css"
// import IndLogo from "../../Images/IN-D-Logo/IND.png";
// import { logout } from "../../Actions";
// import { useDispatch, useSelector } from "react-redux";
// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

// function Header() {
//     const dispatch = useDispatch();
//     const {user={},customer={}} = useSelector(state=>state);
//     const {name="",url=""}=user.user;
//     const {header={}} = customer;
//     const [open, setOpen] = React.useState(false);
//     const handleClickOpen = () => {
//       setOpen(true);
//     };
//     const handleClose = () => {
//       setOpen(false);
//     };
//     const _logout = () => {
//         dispatch(logout());
//         setTimeout(() => {
//             window.open(url,"_self")
//             setOpen(false);
//         }, 1000);
//     }
//     return (
//         <div >
//             {header.visible && <div className="header d-flex justify-content-between align-items-center">
//                 <div className="d-flex flex-column">
//                     <div>{header.title}</div>
//                     {header.step&&<p>{header.step} of 4</p>}
//                 </div>
//                 <div className="d-flex align-items-center" >
//                     {user.isLoggedIn&&user.user&&user.user.token&&
//                 <div >
//                     <div onClick={handleClickOpen}>
//                     Logout
//                     </div>
//                     <Dialog
//                         open={open}
//                         onClose={handleClose}
//                         aria-labelledby="alert-dialog-title"
//                         aria-describedby="alert-dialog-description"
//                         className="logout_popup"
//                     >
//                         <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
//                         <DialogContent>
//                         <DialogContentText id="alert-dialog-description">
//                         {name}, Are you sure you want to logout from Video kyc? 
//                         </DialogContentText>
//                         </DialogContent>
//                         <DialogActions>
//                         <Button onClick={_logout} color="primary">
//                             Logout
//                         </Button>
//                         <div>|</div>
//                         <Button onClick={handleClose} color="primary">
//                             Cancel
//                         </Button>
//                         </DialogActions>
//                     </Dialog>
//                 </div>}
//                 <img src={IndLogo} alt="ind-logo" />
//             </div>
            
//             </div>}
//         </div>
//     )
// }

// export default Header
import React,{useState} from 'react'
import "./Header.css"
import IndLogo from "../../Images/IN-D-Logo/IND.png";
import { useSelector,useDispatch } from "react-redux";
import DialogComponent from '../DialogComponent'
import {Modal,logout} from '../../Actions'
function Header() {
    const dispatch = useDispatch();
    const {user={},customer={}} = useSelector(state=>state);
    const {modal} = user;
    const {name="",url=""}=user.user;
    const {header={}} = customer;
    const [click,SetClick]=useState(false)
    const handleClick=()=> {
        SetClick(true)
        dispatch(Modal(true))
    }
    const handleClose = () => {
        SetClick(false);
        dispatch(Modal(false))
      };
      const _logout = () => {
        dispatch(logout());
        setTimeout(() => {
            window.open(url,"_self")
            // SetClick(false);
            handleClose()
        }, 1000);
    }
    return (
        <div >
            {header.visible && <div className="header d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                    <div>{header.title}</div>
                    {header.step&&<p>{header.step} of 4</p>}
                </div>
                <div className="d-flex align-items-center" >
                    {user.isLoggedIn&&user.user&&user.user.token&&
                    <DialogComponent name={name}  desc={", Are you sure you want to logout from vkyc? "} header={"Confirm Logout"} url={url} handleClick={handleClick} handleClose={handleClose} modal={modal} logout={_logout}/>}
                <img src={IndLogo} alt="ind-logo" />
            </div>
            
            </div>}
        </div>
    )
}

export default Header