import  React,{ useEffect } from "react";
import thanks_icon from '../../Images/thanks_success.svg'
import { connect } from 'react-redux'
import { changeHeaderonAadhar,logout } from '../../Actions'
import { useDispatch,useSelector } from "react-redux";

function Thankyou(props) {
    const {user={}}=useSelector(state=>state)
    const {url=""}=user.user
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(changeHeaderonAadhar({title:"Thank You",visible:true,border_visible:false}))
        setTimeout(() => {
            dispatch(logout())
            setTimeout(() => {
            window.open(url,"_self")
            }, 1000);
        }, 5000);
       
    },[dispatch])
    return (
        <div className="mt-5 pt-5" style={{textAlign:"center"}}>
            <img src={thanks_icon} alt="success_icon" className="mt-4 pt-3 mb-5"/>
            <h1 style={{fontFamily:"GooglesansBold"}}>Thank You</h1>
            <p style={{fontFamily:"GooglesansRegular"}} className="mt-4">your Video-KYC is complete</p>
        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        
    }
}
const mapDispatchToProps={
    changeHeaderonAadhar,
    logout
}
export default connect(mapStateToProps,mapDispatchToProps)(Thankyou)

