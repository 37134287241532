import { ActionType } from "../Constants";
const initialState = {
    loader: false,
    session: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ActionType.OPENTOK_REQUEST:
            return { ...state, loader: true }
        case ActionType.OPENTOK_COMPLETED:
            return { ...state, loader: false, session: action.payload }
        case ActionType.OPENTOK_FAILED:
            return { ...state, loader: false }
        case ActionType.RESET:
            return initialState;
        default:
            return state;
    }
};