import React, { Component } from "react";
import "./Waitpage.css";
import ReactPlayer from "react-player";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { createSession } from "opentok-react";
import {
  getTokens,
  requestUserService,
  Uidinsertion,
  changeHeaderonAadhar,
} from "../../Actions";
import { socket, socketInit,emit } from "../../Helpers";
import "@opentok/client";
import { YouTube_Url } from "../../Constants";

let company_language;
export class Waitpage extends Component {
  constructor(props) {
    super(props);
    this.state = { state: null, streams: [] };
    if (!socket) 
    this.props.socketInit();

    this.props.changeHeaderonAadhar({ title: "Waiting Page", visible: true });
  }

  componentDidMount = async () => {
    company_language=`${this.props.company}_${this.props.language}`
    document.body.classList.add("background-white");
    const { finished = true } = await this.props.getTokens();
    if (finished) {
      this.props.requestUserService(this.props.uid);
      this.listenSignal();
      setTimeout(() => {
        emit("Queue_length", {
          company:company_language
        });
      }, 8000);
     
    }
    // uid and user_id is inserting to database
    this.props.Uidinsertion();
    
  };
  listenSignal() {
    const { user = {} } = this.props;
    const { session = {} } = this.props;
    console.log(`${session.Session_ID}_AGENT`)
    try {
      socket.on(`${session.Session_ID}_AGENT`, (data) => {
        if (
          data.user_id === user.UserID &&
          data.alias === "skip_to_wait_screen" &&
          data.session_id === session.Session_ID
        ) {
          this.props.history.push("/aadharxml");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const company = this.props.company;
    return (
      <div className="container-fluid ">
        <div className="waitpage">
          <div className="">
            <h1>Waiting</h1>
          </div>
          <div>
            <p>
              One of our video KYC agent will get in contact with you in
              sometime.
            </p>
          </div>
          <div className="waittime_counter">
            <h3>Wait Time</h3>
            <h1>
              5 <span>Mins</span>
            </h1>
          </div>
          <div className="player-wrapper">
            <ReactPlayer
              // url='https://www.youtube.com/watch?v=Xa6yrHOQCQk'
              url={`${YouTube_Url[company]}`}
              className="react-player"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ opentok, customer, user, aadharotp }) => {
  return {
    session: opentok.session,
    streams: customer.streams,
    uid: user.uid,
    user: user.user,
    company: user.user.company,
    language: user.user.language
  };
};
const mapdispatchToProps = {
  getTokens,
  requestUserService,
  Uidinsertion,
  changeHeaderonAadhar,
  socketInit
};
export default connect(mapStateToProps, mapdispatchToProps)(Waitpage);
