import React, { Component } from 'react'
import { connect } from "react-redux";
import { changeHeaderonAadhar } from "../../Actions";
import Capture from '../../Components/DataCapture/capture'
import Loader from '../../Components/Loader'

export class LightningCondition extends Component {
    constructor(props) {
        super(props);
        this.props.changeHeaderonAadhar({
          title: "Lighting",
          visible: true,
        });
      }
    render() {
        return (
            <div>
        {this.props.lightcheck?<Loader msg="Please wait while we check to see if your lighting conditions are suitable for this KYC process...!"/>:
            <Capture data_type="lightning" history={this.props.history}/>}
        </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
      lightcheck: state.aadharotp.lightcheck,
    };
  };
export default connect(mapStateToProps, { changeHeaderonAadhar })(LightningCondition);

