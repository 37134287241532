import { ActionType } from "../Constants";
const initialState = {
  loader: false,
  streams : [],
  session : null,
  aadharsteps : 0,
  header : {
    visible : true,
    step : 0,
    title : "Sign up",
    border:0,
    border_visible:true
  },
  // border:{
  //   visible:true
  // },
};

export default (state = initialState, action) => {
  switch (action.type) {

    case ActionType.USER_SERVICE_REQUEST:
      return { ...state, loader: true }
    case ActionType.USER_SERVICE_COMPLETED:
      return { ...state, loader: false }
    case ActionType.USER_SERVICE_FAILED:
      return { ...state, loader: false };

    case ActionType.USER_RE_STREAM :
        return { ...state, streams : action.payload}
    case ActionType.USER_RE_SESSION :
        return { ...state, session : action.payload}

    case ActionType.CHANGE_AADHAR_STEPS : 
        return { ...state, header : action.header }
    // case ActionType.BORDER_CHANGE:
    //   return {...state,border:action.border}
    case ActionType.RESET:
      return initialState;

    default:
      return state;
  }
};