import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Consent from "./Pages/Consent/Consent";
import Waitpage from "./Pages/Waitpage/Waitpage"
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Aadharxml from './Pages/Aadharxml/Aadharxml'
import OTP from './Pages/OTP/Otppage'
import VkycCustomLayout from './Routes/VkycCustomLayout'
import AadharxmlUpload from "./Pages/AadharxmlUpload/Aadharxmlupload"
import AadharExtraction from "./Pages/AadharExtraction/AadharExtraction"
import AadharOTP from './Pages/AadharOTP/Aadharotp'
import Aadharcapture from './Pages/AadharCapture/Aadharcapture'
import liveliness from './Pages/LivelinessCheck/liveliness'
import ProfilePage from './Pages/ProfilePage/Profilepage'
import Thankyou from './Pages/Thankyou/Thankyou';
import SignUp from "./Pages/SignUp";
import Pancapture from './Pages/Pancapture/pancapture'
import SignatureCapture from './Pages/Signature/signature'
import Redirection from './Pages/Redirections/redirection'
// agents 
import Register from './AgentViews/register/register';
import Login from './AgentViews/login/login';
import Dashboard from './AgentViews/dashboard/dashboard';
import {OpenTok} from './AgentViews/opentok';
import DialogComponent from './Components/DialogComponent'
import ConditionCheck from './Pages/LightiningCondition/LightningCondition'
import { useSelector } from "react-redux";
const history = createBrowserHistory();



const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#00716e',
      main: '#00716e',
      dark: '#00716e',
    },
    secondary: {
      light: '#00716e',
      main: '#00716e',
      dark: '#00716e'
    },
  },

});

const AuthRoutes = props => {
  return (
    <Switch>
      <VkycCustomLayout exact path="/" component={SignUp} />
      <VkycCustomLayout exact path="/otp" component={OTP} />
      <VkycCustomLayout exact path="/consent" component={Consent} />
      <VkycCustomLayout exact path="/redirection" component={Redirection} />
      <Redirect to={"/"} />
    </Switch>
  )
}

// for after login / sign up routes
const HomeRoutes = props => {
  return (
    <Switch>
      <VkycCustomLayout exact path="/" component={Consent} />
      <VkycCustomLayout exact path="/conditioncheck" component={ConditionCheck} />
      <VkycCustomLayout exact path="/waitpage" component={Waitpage} />
      <VkycCustomLayout exact path="/aadharxml" component={Aadharxml} opentok/>
      <VkycCustomLayout exact path="/aadharxmlupload" component={AadharxmlUpload} opentok/>
      <VkycCustomLayout exact path="/aadharextraction" component={AadharExtraction} opentok/>
      <VkycCustomLayout exact path="/aadharotp" component={AadharOTP} opentok/>
      <VkycCustomLayout exact path="/aadharcapture" component={Aadharcapture} opentok/>
      <VkycCustomLayout exact path="/pancapture" component={Pancapture} opentok/>
      <VkycCustomLayout exact path="/signature" component={SignatureCapture} opentok/>
      <VkycCustomLayout exact path="/liveliness" component={liveliness} opentok/>
      <VkycCustomLayout exact path="/profilepage" component={ProfilePage} opentok/>
      <VkycCustomLayout exact path="/thankyou" component={Thankyou} />
      <Redirect to={"/"} />
    </Switch>
  )
}

const AgentsRoutes = (props) => {
  console.log("props ", props)
  if (props.isLoggin)
    return (
      <Switch>
        <Route exact path="/agent" component={Dashboard} />
        <Route exact path="/agent/Dashboard" component={Dashboard} />
        <Route exact path="/agent/Opentok" component={OpenTok} />
        <Redirect to={"/agent"} />
      </Switch>
    )
  else return (
    <Switch>
      <Route exact path="/agent/register" component={Register} />
      <Route exact path="/agent" component={Login} />
      <Redirect to={"/agent"} />
    </Switch>
  )
}
// const CustomerRoutes = props => {
//   if (props.isLoggin)
//     return <HomeRoutes />
//   else return <AuthRoutes />
// }
const CustomerRoutes = props => {
  const Refresh=()=> {
    window.location.reload('/')
}
  console.log(props.condition)
  if (props.condition==="online") {
    if (props.isLoggin){
      return <HomeRoutes />
    }else{
      return <AuthRoutes />
    }
  }else {
    return <DialogComponent desc={"You are offline please check your internet"} header={"No Internet"} openpopup={true} modal={true} Refresh={Refresh} buttonname={"Retry"}/>
  }
  
}
function App() {
  const { user = {} } = useSelector(state => state);
  const isLoggin = user.isLoggedIn && user.user && user.user.token;
  const isAgent = user&&user.user.type&&user.user.type === "agent";
  let condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      console.log("you are online")
    }else {
      console.log("You're Offline")
    }
  const {pathname=''} = history.location;
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ToastContainer />
        <Router history={history}>
          {
            !!isLoggin ?
             isAgent ? 
             <AgentsRoutes isLoggin={!!isLoggin} /> :

             <CustomerRoutes isLoggin={!!isLoggin} condition={condition}/>  :
             
            pathname==='/agent' || pathname === 'agent' ?
            <AgentsRoutes isLoggin={!!isLoggin} />:
            <CustomerRoutes isLoggin={!!isLoggin} condition={condition}/> 
}
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;