import React, { Component } from 'react'
import Capture from '../../Components/DataCapture/capture'
import { connect } from "react-redux";
import { changeHeaderonAadhar } from '../../Actions'
export class Aadharcapture extends Component {
  constructor(props) {
    super(props);
    // this.webcamRef = React.createRef(null);
    this.props.changeHeaderonAadhar({step : 1, title : "Aadhar", visible : true,border_visible:true,border:20});
  }
  render() {
    // console.log("this.props in aadhar capture ", this.props)
    return (
      <div>
          <Capture data_type="aadhar" history={this.props.history}/>
      </div>
    )
  }
}

export default connect(()=>({}),{changeHeaderonAadhar})(Aadharcapture);
