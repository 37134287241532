import React, { Component } from "react";
import Back from "../../Images/back.svg";
import "./Aadharxmlupload.css";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import uploadicon from "../../Images/UploadIcon.svg";
import { connect } from "react-redux";
import { Aadharxml_upload, changeHeaderonAadhar } from "../../Actions";
import LoadingOverlay from "react-loading-overlay";
export class Aadharxmlupload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      phone: "",
      code: "",
      phoneError:"",
      CodeError:"",
      sharebutton:true,
      phonebutton:true,
      filebutton:true,
      fileError:"Please Upload Your AadhaarXML file"
    };
    this.props.changeHeaderonAadhar({step: 1,title: "Aadhar ",visible: true,border_visible:true,border:20});
  }
  handleChange=(e)=> {
    const phone = e.target.value
    console.log(phone)
    const re = /^[0-9\b]+$/;
    if (phone.length!==0){
      if (re.test(phone)&&phone.length===10) {
         this.setState({...this.state,phone: phone,phonebutton:false,phoneError:"",})
      }
      else {
      this.setState({...this.state,phoneError:"Phone number should be in 10 digits and should contain only numbers",phonebutton:true,phone: phone})
    }
  }else {
    this.setState({...this.state,phoneError:"Field Required",phonebutton:true,phone: phone})
  }
  }
  ShareCode=(e)=> {
    const code = e.target.value
    console.log(code)
    const re = /^[0-9\b]+$/;
    if (code.length!==0){
      if (re.test(code)&&code.length===4) {
         this.setState({...this.state,code: code,sharebutton:false,CodeError:"",})
      }
      else {
      this.setState({...this.state,CodeError:"Share Code should be in 4 digits and should contain only numbers",sharebutton:true,code: code})
    }
  }else {
    this.setState({...this.state,CodeError:"Field Required",sharebutton:true,code: code})
  }
  }

  submitHandler = async (e) => {
    e.preventDefault();
    const Aadhar_xml_data = {
      file: "",
      filename: "",
      phone: this.state.phone,
      code: this.state.code,
    };
    this.pond
      .getFiles()
      .map((fileItem) => fileItem.file)
      .forEach((file) => {
        Aadhar_xml_data.file = file;
        Aadhar_xml_data.filename = file.name;
      });
    this.props.Aadharxml_upload(Aadhar_xml_data);
  };
  render() {
    console.log(this.props.aadhar_xmldata);

    
    if (this.props.aadhar_xmldata&&Object.keys(this.props.aadhar_xmldata).length>0) {
      this.props.history.push("/aadharextraction")
    }
    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.props.aadhar_xml_loader}
          // active={true}
          spinner
          text="Please wait your aadharxml is being uploaded ..."
        >
          <div
            className="aadhar_back"
            onClick={() => this.props.history.push("/aadharxml")}
          >
            <img src={Back} alt="back" />
            <h3>Aadhaar XML</h3>
          </div>
          <div className="aadharxmlupload_form mt-3">
            <form onSubmit={this.submitHandler}>
              <div id="borderBox">
                <FilePond
                  ref={(ref) => (this.pond = ref)}
                  maxFiles={1}
                  files={this.state.files}
                  acceptedFileTypes={["zip/*"]}
                  onupdatefiles={(fileItems) => {
                    this.setState({
                      files: fileItems.map((fileItem) => fileItem.file),
                    });
                    if (this.state.files.length>0) {
                      this.setState({...this.state,filebutton:false,fileError:""})
                    }else {
                      this.setState({...this.state,filebutton:true,fileError:"Please Upload Your AadhaarXML file"})
                    }
                  }}
                  name={"file"}
                  labelIdle={
                    `<img src=${uploadicon} alt="data" />` +
                    "<span>Upload Aadhar XML</span>"
                  }
                />
              </div>
              <div className="errormsg" style={{textAlign:"center"}}>{this.state.fileError?this.state.fileError:""}</div>
              <p>
                You can always download your Aadhaar XML{" "}
                <a
                  rel="noopener noreferrer"
                  href="https://resident.uidai.gov.in/offline-kyc"
                  target="_blank"
                >
                  Click here
                </a>
              </p>
              <div className="aadhar_sharecode">
                <h5>Enter your Aadhaar XML credentials</h5>
                <label className="aadharotp_label">Phone</label>
                <TextField
                  id="standard-basic"
                  label="Phone Number"
                  className="aadharuploadinput mt-4"
                  name="phone"
                  onChange={this.handleChange}
                  value={this.state.phone}
                />
                <div className="errormsg">{this.state.phoneError?this.state.phoneError:""}</div>
                <label className="aadharotp_label">Share Code</label>
                <TextField
                  id="standard-basic"
                  label="Share Code"
                  className="aadharuploadinput mt-3"
                  name="code"
                  onChange={this.ShareCode}
                  value={this.state.code}
                />
                <div className="errormsg">{this.state.CodeError?this.state.CodeError:""}</div>
              </div>
              <div className="submit_button mt-3 mb-4">
                <Button variant="contained" color="primary" type="submit" disabled={this.state.phonebutton||this.state.sharebutton||this.state.filebutton}>
                  SUBMIT
                </Button>
              </div>
            </form>
          </div>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(state);
  return {
    aadhar_xmldata: state.aadharotp.aadhar_xml,
    aadhar_xml_loader: state.aadharotp.xml_loader,
  };
};
const mapDispatchToProps = {
  Aadharxml_upload,
  changeHeaderonAadhar,
};
export default connect(mapStateToProps, mapDispatchToProps)(Aadharxmlupload);
