import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../Components/Header/Header'
import { OpenTok } from "../Pages/OpenTok";
import { useSelector } from "react-redux";
export default function LayoutRoute({ component: Component, opentok=false, ...rest }) {
  return (
    <Route {...rest} render={(props) => (
      <VkycCustomLayout>
        {Component && 
        <>
        {opentok ?
        <OpenTokHOC>
            <Component {...props} />
        </OpenTokHOC> :
        <Component {...props} />}
        </>
        }
      </VkycCustomLayout>
    )} />
  );
}

const OpenTokHOC = props => {
  return (
    <div className="opentok_data">
      {props.children}
      <OpenTok/>
    </div>
  )

}
function VkycCustomLayout({ children }) {
  const {customer={}} = useSelector(state=>state);
  const {header={}}=customer
  return (
    <React.Fragment>
      <Header />
      <div style={{ backgroundColor: "#83C2C0" }}>
        {header.border_visible&&
        <div className="w3-grey" style={{ height: "6px", width: `${header.border}%`, backgroundColor: "#98E8E5" }}></div>}
      </div>
      <div id="mainContent">
        <div className="inner-wrapper">
          <div className="container-fluid col-md-6 col-sm-12">
            <div className="">
              {children}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}