import React from "react";
import "./Consent.css";
import { Checkbox, Button } from "@material-ui/core";
import { connect } from "react-redux";
import { Geolocation, changeHeaderonAadhar,generateUid } from "../../Actions/";
import { toast } from "react-toastify";
import Loader from '../../Components/Loader'
import {  socketInit } from "../../Helpers";
import DialogComponent from '../../Components/DialogComponent'

class Consent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      lat: "",
      lng: "",
      loader:true,
      modal:false,
    };
    this.props.socketInit();

    this.props.changeHeaderonAadhar({ title:"Consent",visible: true,border_visible:false });

  }
  handleCheck = () => {
    this.setState({ checked: !this.state.checked });
  };
  handleClick=()=> {
    this.setState({modal:true})
  }
  handleClose=()=> {
    this.setState({modal:false})
  }
  SpeedCheck=()=> {
    var imageAddr = "http://www.tranquilmusic.ca/images/cats/Cat2.JPG" + "?n=" + Math.random();
    var startTime, endTime;
    var downloadSize = 5616998;
    var download = new Image();
    download.onload = function () {
        endTime = (new Date()).getTime();
        showResults();
    }
    startTime = (new Date()).getTime();
    download.src = imageAddr;

    function showResults() {
        var duration = (endTime - startTime) / 1000; //Math.round()
        var bitsLoaded = downloadSize * 8;
        var speedBps = (bitsLoaded / duration).toFixed(2);
        var speedKbps = (speedBps / 1024).toFixed(2);
        var speedMbps = (speedKbps / 1024).toFixed(2);
        if (speedMbps<1) {
          toast.info(`Your Network Speed is slow,So Please check your internet`)
        }
    }
  }
  componentDidMount = () => {
    document.body.classList.add("background-white");
    // this.props.generateUid()
    this.SpeedCheck()

  };
  geolocation= async ()=> {
    const handleOpen=()=> {
      this.setState({modal:true})
    }
    if (navigator.geolocation) {
      navigator.permissions.query({name:'geolocation'}).then(function(permissionStatus) {
        console.log('geolocation permission status is ', permissionStatus.state);
        if (permissionStatus.state==="denied") {
          handleOpen()
        }
        console.log(permissionStatus)
        permissionStatus.onchange = function() {
          if (permissionStatus.state==="denied") {
            handleOpen()
          }
          console.log('geolocation permission status has changed to ', this.state);
        };
      });
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position)
        this.props.Geolocation(position.coords.latitude,position.coords.longitude,(success)=> {
          console.log(success)
          if (success) {
          this.props.history.push("/conditioncheck")
          }
        });
      });
    }else {
      toast.error("Your browser is not supported for location access")
    }
  }
  render() {
    const { checked = false } = this.state;
    if (this.state.modal) {
      return <DialogComponent desc={"Location, Please Unblock your location in your browser site settings and Then access KYC "} header={"Location Denied"} modal={this.state.modal} handleClick={this.handleClick} handleClose={this.handleClose} buttonname={"OK"} Refresh={this.handleClose}/>
    }
    return (
      <div id="consent_page">
      {this.props.uid_loader?
        <Loader msg="Please wait ID is being generated...!"/>:
        <div className="inner-wrapper">
          <div className="">
            <div className="col-md-12">
              <h1>Consent for Video KYC</h1>
            </div>
            <p className="mt-4 mb-3 pl-3 context">
              Please give your consent for using your camera,microphone and
              screen capture by clicking 'allow' on all the pop-ups that appear.
            </p>
            <div className="consent_data mt-4">
              <ul>
                <li>I am physically present in India</li>
                <li>I am a resident of India and I am an Indian National</li>
                <li>I am not a politically exposed person</li>
                <li>
                  I am above 18 years of age, capable of self-operation of
                  accounts, and not reportable under FATCA and CRS
                </li>
              </ul>
            </div>
            <div className="d-flex checkbox">
              <Checkbox
                checked={checked}
                style={{ color: "#048C88" }}
                inputProps={{ "aria-label": "primary checkbox" }}
                onClick={this.handleCheck}
              />
              <p onClick={this.handleCheck} className="mt-3 cursor-p">
              I hereby accept the above and consent to video KYC. 
              </p>
            </div>
            <Button
              disabled={!checked}
              variant="contained"
              color="primary"
              className="consent_button mb-4"
              onClick={this.geolocation}
            >
              Accept
            </Button>
          </div>
        </div>
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    location: state.aadharotp.location,
    uid:state.user.uid,
    token:state.user.user.token,
    uid_loader:state.user.uid_loader,
  };
};
const mapDispatchToProps = {
  Geolocation,
  changeHeaderonAadhar,
  socketInit,
  generateUid
};
export default connect(mapStateToProps, mapDispatchToProps)(Consent);
