import React, { Component,useSelector } from 'react';
import OtpInput from 'react-otp-input';
import './otp.css'
import Button from '@material-ui/core/Button'
import mobilesvg from "../../Images/EnterOTPIcon.svg"
import { connect } from 'react-redux'
import { changeHeaderonAadhar,OTP_Page,signup } from "../../Actions";
import Loader from '../../Components/Loader'

let data=false
let show=false
class OTP extends Component {
  constructor(props){
    super(props);
    this.state = {
    otp: '',
    isDisabled: false,
    hasErrored: false,
    numInputs: 6,
    otpError:"",
    counter:60,
    Resendbutton:false,
  };
  this.props.changeHeaderonAadhar({
    title: "OTP",
    visible: true,
    border_visible:false
  });
  }
  componentDidMount=()=> {
      document.body.classList.add("background-white");
      // this.startTimer()
  }
  startTimer() {
    this.setState({Resendbutton:true})
    show=true
    data=true
    this.timer = setInterval(() => this.setState({
      counter: this.state.counter-1
    }), 1000);
    if (this.state.counter===0) {
      this.setState({counter:60})
    }
  }
  stopTimer=()=> {
    // this.setState({Resendbutton:false})
    data=false
    show=false
    clearInterval(this.timer)
  }
  handleOtpChange = otp => {
    const re = /^[0-9\b]+$/;
      if (re.test(otp)&&otp.length===6) {
          this.setState({hasErrored:false,otpError:""})
      }else {
          this.setState({hasErrored:true,otpError:"Please Enter valid Otp"})
      }
    this.setState({ otp });
  };
  handleSubmit = e => {
    e.preventDefault();
    const data={
      otp:this.state.otp,
      userID:this.props.userID
    }
    this.props.OTP_Page(data)
  };
  
  clearOtp = () => {
    this.setState({ otp: '' });
  };
  data=()=> {
    this.startTimer()
    this.setState({ otp: '' });
    const user=this.props.user_details
    const state={
      name:user.name,
      email:user.email,
      phone:user.phone,
      company:user.company,
      customer_id:user.customer_id,
      language:user.language
    }
    const data=user.url
    this.props.signup(state,data)
  }
  Redirect=()=> {
    window.open(this.props.user_details.url,"_self")
  }
  render() {
    console.log(this.state.counter)
    let buttontrue=false
    if (this.state.counter===0) {
      this.stopTimer()
    }
    
    console.log(buttontrue)
    const {
        otp,
        numInputs,
        isDisabled,
        hasErrored,
      } = this.state;
    return (
      <div className="  ">
      {this.props.loader?<Loader msg="Resending OTP......"/>:
        <div className="otp_main mt-3">
          <div className="">
            <img src={mobilesvg} alt="chat" />
          </div>
          <h1>Enter OTP</h1>
          <div className=" ">
            <h6>
              <div>Please enter the OTP sent to your Email Id-{" "}</div> 
              <div className="d-flex"><span>{this.props.userEmail}</span>
                <div className="resend ml-2" onClick={this.Redirect}>Change</div>
                </div>
            </h6>
          </div>
          <div>
          <form onSubmit={this.handleSubmit}>
            <div>
              <OtpInput
                inputStyle="inputStyle"
                numInputs={6}
                isDisabled={isDisabled}
                hasErrored={hasErrored}
                errorStyle="error"
                onChange={this.handleOtpChange}
                separator={<span> </span>}
                isInputNum={true}
                shouldAutoFocus
                value={otp}
              />
            </div>
            <div className="errormsg">{this.state.otpError}</div>
            <div className="d-flex justify-content-between mt-2 pt-2 otp_button_div">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="otp_submit_button"
                disabled={otp.length < numInputs||hasErrored}
              >
                Verify
              </Button>
            </div>
            </form>
            {show?
            <p className="timer">Please wait {this.state.counter} seconds(s) before requesting a new One Time Password(OTP)</p>:""}
            <div className="d-flex justify-content-center data mt-4">Not received your code? <Button onClick={this.data} className="resend" disabled={data}>Resend OTP</Button></div>
           {/* <Button color="primary" className="resend" type="button" disabled={isDisabled} onClick={this.clearOtp}>Resend Otp</Button>*/}
          </div>
        </div>
          }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userID: state.user.user.UserID,
    otp:state.aadharotp.otp,
    userEmail:state.user.user.email,
    user_details:state.user.user,
    loader:state.user.loader
  }
}
const mapDispatchToProps = {
  OTP_Page,
  changeHeaderonAadhar,
  signup
  
}
export default connect(mapStateToProps,mapDispatchToProps)(OTP)