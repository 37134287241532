import React, { Component } from "react";
import "./Extraction.css";
import Profile from "../../Images/profile_icon.png";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Add_icon from "../../Images/AddIcon.svg";
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { emit, socketInit, socket } from "../../Helpers";
import {Current_Address} from '../../Actions'
export class Extraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      address1: "",
      address2: "",
      showedit:false,
      addresserror1:"",
      addresserror2:""
      
      };
      if (!socket) {
        this.props.socketInit()
      }
  }

  handleChangeAddress1 = (e) => {
    const address=e.target.value.replace(/\s+/g, '');
    if (address.length>1) {
      this.setState({address1:address,addresserror1:""})
    }else {
      this.setState({address1:address})
    }
  };
  handleChangeAddress2=(e)=> {
    const address=e.target.value.replace(/\s+/g, '');
    if (address.length>1) {
      this.setState({address2:address,addresserror2:""})
    }else {
      this.setState({address2:address})
    }
  }
  handleClick = () => {
    this.setState({ opened: !this.state.opened });
  };
  ONsumbit=()=> {
    if (this.state.address1.length<1 ){
        this.setState({addresserror1:"Please enter your address"})
    }else if (this.state.address2.length<1) {
      this.setState({addresserror2:"Please enter your address"})
    }
    else {
    this.setState({addresserror1:"",addresserror2:''})
    const mainaddress=this.state.address1.concat(' ,'+this.state.address2)
    this.props.Current_Address(mainaddress,(success)=> {
      if (success)
        this.setState({opened:false,showedit:true})
    }) 
  }
  }
  Pancardempty=()=> {
    this.props.history.push("/pancapture")
  }
  render() {
    console.log(this.props)
    let type=''; 
    let aadhardata;
    if (this.props.capture&&this.props.capture.length>0) {
        aadhardata = this.props.capture[0];
        if(this.props.capture[0].DOC_TYPE||aadhardata.type==="PAN Card") {
            type = "pan"
        }
        else {
          type="aadhar"
        }
    }else if (this.props.aadhar_xml&&this.props.aadhar_xml.length>0) {
      aadhardata = this.props.aadhar_xml[0];
      type = "aadhar"
    }
    
    else {
      aadhardata = this.props.aadhar_xml[0];
        if(aadhardata&&this.props.aadhar_xml[0].DOC_TYPE==="Aadhar_XML") {
            type = "aadhar"
        }
    }
    var {name="",number="",gender="",dob=""}=this.props.aadhar_front&&this.props.aadhar_front?this.props.aadhar_front:{}
    localStorage.setItem("gender",gender)
    console.log(number)
    return (
      <div className="aadhar_extraction">
        <h4>{type==="aadhar" ? "Aadhaar Extraction Result" : "Pan Extraction Result"}</h4>
        {type==="aadhar"?
        <div>
        <div className="extraction_data">
          <div className="extraction_profile_data d-flex align-items-center">
            {aadhardata&&aadhardata.photo?<img src={"data:image/png;base64," +  aadhardata.photo} alt="aadharphoto" />:<img src={Profile} alt="aadharphoto" />}
            
            <div>
              <h6>Aadhaar Number</h6>
              {number||aadhardata.number||aadhardata.AADHAR_NUMBER?
                <h5>{aadhardata&&aadhardata?aadhardata.number||number&&number.replace(/\d(?=\d{4})/g, "X")||aadhardata&&aadhardata.AADHAR_NUMBER.replace(/\d(?=\d{4})/g, "X"):"NA"}</h5>
                :
                <h5>{aadhardata&&aadhardata?aadhardata.number||number||aadhardata&&aadhardata.AADHAR_NUMBER:"NA"}</h5>
                }
            </div>
          </div>
          <hr />
          <div className="extraction_name">
            <h6>Name</h6>
            <h5>{aadhardata?aadhardata.NAME||name||aadhardata.name:"NA"}</h5>
          </div>
          <hr />
          <div>
            <h6>Father Name</h6>
            <h5>{aadhardata?aadhardata.FATHER_NAME||aadhardata.fathername:"NA"}</h5>
          </div>
          <hr />
          <div>
            <h6>DOB</h6>
            <h5>{aadhardata?aadhardata.DOB||dob||aadhardata.dob:"NA"}</h5>
          </div>
          <hr />
          <div>
            <h6>Gender</h6>
            <h5>{aadhardata?aadhardata.GENDER||gender||aadhardata.gender:"NA"}</h5>
          </div>
          <hr />
          <div>
            <h6>Address</h6>
            <textarea type="textValue" className={`aadhar-input text-area name-input`} value={aadhardata?aadhardata.ADDRESS||aadhardata.address:"NA"} />
          </div>
          <hr />
          {this.props.currentaddress?
          <div>
            <h6>Current Address</h6>
            <textarea type="textValue" className={`aadhar-input text-area name-input`} value={this.props.currentaddress} />
          <hr />
          </div>
          :""}
          <div
            onClick={this.handleClick}
            className="d-flex justify-content-center current_address"
          >
            <div>
              <img src={Add_icon} alt="add_icon" />
              <span>{this.state.showedit?"Edit Current Address":"Add Current Address"}</span>
            </div>
          </div>
          {this.state.opened ? (
            <div>
              <form>
                <div>
                  <p>Address line 1:</p>
                  <TextField
                    id="standard-name"
                    onChange={this.handleChangeAddress1}
                    name="address1"
                    value={this.state.address1}
                    className="aadharuploadinput mt-3"
                  />
                </div>
                <div className="errormsg">{this.state.addresserror1}</div>
                <div>
                  <p>Address line
                  2:</p>
                  <TextField
                    id="standard-name"
                    onChange={this.handleChangeAddress2}
                    name="address2"
                    value={this.state.address2}
                    className="aadharuploadinput mt-3"
                  />
                </div>
                <div className="errormsg">{this.state.addresserror2}</div>
              </form>
              <Button variant="contained" color="primary" className="save-button" onClick={this.ONsumbit}>Save</Button>
            </div>
          ) : (
            ""
          )}
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.Pancardempty}
        >
          SUBMIT
        </Button>
        </div>
        :
        <div>
        <div className="extraction_data">
        <div className="extraction_profile_data d-flex align-items-center">
          <img src={Profile} alt="aadharphoto" />
          <div>
            <h6>PAN Number</h6>
            <h5>{aadhardata?aadhardata.PAN||aadhardata.number:"NA"}</h5>
          </div>
        </div>
        <hr />
        <div className="extraction_name">
          <h6>Name</h6>
          <h5>{aadhardata?aadhardata.NAME||aadhardata.name:"NA"}</h5>
        </div>
        <hr />
        <div className="extraction_name">
          <h6>Father/Spouse Name</h6>
          <h5>{aadhardata?aadhardata.FATHER_NAME||aadhardata.fathername:"NA"}</h5>
        </div>
        <hr />
        <div>
          <h6>Date of Birth</h6>
          <h5>{aadhardata?aadhardata.DOB||aadhardata.dob:"NA"}</h5>
        </div>
      </div>
      
      <Button
          variant="contained"
          color="primary"
          onClick={() => this.props.history.push("/signature")}
        >
          SUBMIT
        </Button>
        </div>
        

          }
          
      </div>
    );
  }
}
const mapStateToProps=(state)=> {
  return {
    capture:state.aadharotp.capture,
    aadhar_xml:state.aadharotp.aadhar_xml,
    aadhar_number:state.aadharotp.Aadharnumber,
    aadhar_front:state.agent.aadhar_front,
    currentaddress:state.aadharotp.currentaddress
  }
}
const mapDispatchToProps={
  Current_Address,
  socketInit

}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Extraction));
