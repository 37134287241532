import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signup } from "../../Actions";
import "./redirection.css";
import { changeHeaderonAadhar } from "../../Actions";
import { connect } from "react-redux";
import Loader from "../../Components/Loader"

const SignUp = (props) => {
  const { loader = false } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
  });


  useEffect(() => {
    const urlElements = window.location;
    var url = new URL(urlElements);
    var name = url.searchParams.get("name");
    let email = url.searchParams.get("email");
    let phone = url.searchParams.get("phone");
    let company = url.searchParams.get("company");
    setState({ name: name, email: email, phone: phone, company: company });
    console.log(name, email, phone, company);
    dispatch(changeHeaderonAadhar({ title: "Sign up", visible: true }));
    document.body.classList.add("background-white");
    console.log(state.company)
  }, []);
  console.log(state);
  const register = async () => {
    if (state.name) {
      const result = await dispatch(signup(state));
      if (result) props.history.push("/otp");
    }
  };
  useEffect(()=> {
    if (state.company && state.name && state.email && state.phone) {
      console.log(state)
      register();
    }
  },[state])
  return (
    <React.Fragment>
      {loader?<Loader msg="Please wait we will redirect to otp page ..."/>:""}
    </React.Fragment>
  );
};

const mapdispatchToProps = {
  changeHeaderonAadhar,
};
export default connect(() => ({}), mapdispatchToProps)(SignUp);