
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import editPencil from "../../Images/edit.svg";
import { Button, CircularProgress } from '@material-ui/core';
import { saveAadhar,Validation } from "../../Actions";
import Profile from "../../Images/profile_icon.png";
import { toast } from "react-toastify";
const Loader = () => {
    return (
        <div className="aadhar-loader-wrapper center">
            <CircularProgress disableShrink />
        </div>
    )
}
export default (props) => {
    const dispatch = useDispatch();
    const { _loader = false, extraction={}, steps, doctype='',aadhar_verification={},aadhar_front={},agent_override="" } = useSelector(({ agent }) => agent);
    const {type=''} = extraction&&extraction[0]?extraction[0]:{};
    const {db_check={}}=aadhar_verification&&aadhar_verification?aadhar_verification:{}
    const {aadhar=""}=db_check?db_check:{}
    const {db_fetch={}}=aadhar_verification&&aadhar_verification?aadhar_verification:{}
    const {status=""}=db_fetch?db_fetch:{}
    const { tab, heading, title } = props;
    const [edit, setEdit] = useState(false);
    const [state, setState] = useState({
        name: '', address: '', number: '', photo:'',dob:"",gender:"",fathername:""
    });
    const [error,setError]=useState({AadharError:"",button_disable:false,DobError:"",PanError:''})
    let datatype = steps.active===0?'aadhar' : steps.active===1?doctype : steps.active===2?'signature' :steps.active===3?'liveliness':''
    useEffect(()=>{
        if (tab===1&&heading==="Aadhaar"&&type!=="Aadhar_XML"&&agent_override!=="over_ride" ) {
            const { address = '' } = extraction[steps.active]?extraction[steps.active]:{};
            const {name="",number="",fathername="",dob="",gender=''}=aadhar_front&&aadhar_front?aadhar_front:{}
            setState({address,name,number,fathername,dob,gender})
        }else {
            const { name = '', address = '', dob='', fathername='', number = '', photo='',gender="" } = extraction[steps.active]?extraction[steps.active]:{};
            setState({ name, address, dob, fathername, number, photo,gender})
        }
    },[extraction,steps,agent_override])
    const handleChange = (key, text) => {
        setState({ ...state, [key]: text })
    }
    const saveData = () => {
        const agent_override="over_ride"
            if (!state.name || !state.number||!state.dob||!state.fathername){
              toast.error("Please fill all fields")
            }else {
                dispatch(saveAadhar(state, datatype,agent_override))
            }
    }
    const validation=()=> {
        dispatch(Validation())
    }
    const handleChangeValidation=(e) => {
        const number=e.target.value.replace(/[^\w\s]/gi, '')
        console.log(number.length)
        console.log(tab,heading,type)
        if (tab===1&&heading==="Aadhaar") {
            if (number.length===12) {
              setState({...state,number:e.target.value})
              setError({button_disable:false,AadharError:""})
            }
            else {
              setState({...state,number:e.target.value})
              setError({button_disable:true,AadharError:"Aadhar number should be in 12 digit"})
            }
        }else {
            if (number.length===10) {
              setState({...state,number:e.target.value})
              setError({button_disable:false,PanError:""})
            }
            else {
              setState({...state,number:e.target.value})
              setError({button_disable:true,PanError:"Pan number should be in 10 digit"})
            }
        }
      }
      const DobChange=(e)=> {
        const dob=e.target.value
        console.log(dob)
        const re=/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
        if(re.test(dob)) {
          setError({DobError:"",button_disable:false})
          setState({...state,dob:dob})
        }else {
          setError({DobError:"Date must be DD/MM/YYYY format",button_disable:true})
          setState({...state,dob:dob})
        }
      }
      
    const { name = '', address = '', number = '', photo='', dob='', fathername='',gender="" } = state;
    if(title==='Signature' || tab===3 ||title==="Liveliness" ||tab===4 )
    return null 
    else
    return (
        
        <div className="agent-detail-card">
        <div>
        {heading==="Aadhaar"?status==='Verification failed'?<p className="verification_false">Verification failed</p>:aadhar===true?<p className="verification_true">True</p>:aadhar===false?<p className="verification_false">False</p>:"":""}
        </div>
            <div className="agent-detail-card-header">
                <div className={`detail-card-header-text`}>
                    {heading+" "}Details
            </div>
                {heading==="Aadhaar"&&type==="Aadhar Card Back"?
                <Button variant="contained"  color="primary" onClick={validation}>Validation</Button>:
                <div className="h-space" />
                }
                { tab === 2 || heading === 'OVD' || tab === 1 || heading === 'Aadhaar'  ?
                    edit ?
                        <div className={`edit-cance-bt-view`}>
                            <Button variant="outlined" className="cance-button" disabled={_loader}
                                onClick={() => setEdit(!edit)} >
                                Cancel
                            </Button>
                            <Button variant="contained" className="save-button" disabled={error.button_disable}
                                onClick={saveData}>
                                Save
                            </Button>
                        </div> :
                        <div>
                        {type==="Aadhar_XML" && tab===1&&heading==="Aadhaar"?"":
                        <div className="sm-pen-round center" onClick={() => setEdit(!edit)}>
                            <img src={editPencil} className="edit-pen" />
                        </div>
                        }
                        </div>
                    : null}
                    
            </div>
           
           <div className="agent-card-body2">
                {_loader ? <Loader /> :
                    <>
                        <div className="detail-left-card center">
                            {/* replace conditional image */}
                            <div className="detail-round-view">
                            {photo&&photo?<img src={`data:image/png;base64, ${photo}`} className='aadhar-image' alt="User Aadhaar pic" />:<img src={Profile} className='aadhar-image' alt="User Aadhaar pic" />}
                                <input type="text" disabled={!edit} className={`aadhar-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                    value={number.replace(/\d(?=\d{4})/g, "X")} onChange={handleChangeValidation} />
                                    {heading === 'Aadhaar'?<h6 className="aadhar_error">{error.AadharError}</h6>:heading === 'OVD'?<h6 className="aadhar_error">{error.PanError}</h6>:""}
                            </div>
                        </div>
                        <div className="detail-right-card center">
                            <div className="aadhar-details-info">
                                <span className="details-label">Name</span>
                                <input type="text" disabled={!edit} className={`aadhar-input name-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                    value={name} onChange={e => handleChange("name", e.target.value)} />
                                <div className={`details-hairline ${edit ? "hide-hairline" : ""}`} />
                                <span className="details-label">Father/Spouse Name</span>
                                <input type="text" disabled={!edit} className={`aadhar-input name-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                    value={fathername} onChange={e => handleChange("fathername", e.target.value)} />
                                <div className={`details-hairline ${edit ? "hide-hairline" : ""}`} />
                                <div className="d-flex flex-column">
                                    <span className="details-label">DOB</span>
                                    <input type='text' disabled={!edit} className={`aadhar-input name-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                        value={dob} placeholder={edit?'DD/MM/YYYY':''} onChange={DobChange} />
                                    <div className={`details-hairline ${edit ? "hide-hairline" : ""}`} />
                                    <h6 className="aadhar_error mt-2">{error.DobError}</h6>
                                </div>
                                {datatype==='aadhar'&&
                                <div>
                                <span className="details-label">Gender</span>
                                <input type="text" disabled={!edit} className={`aadhar-input name-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                    value={gender} onChange={e => handleChange("gender", e.target.value)} />
                                </div>}
                                {datatype==='aadhar'&&<span className="details-label">Address</span>}
                                {datatype==='aadhar'&&<textarea type="textValue" disabled={!edit} className={`aadhar-input text-area name-input ${edit ? "aadhar-input-active" : "aadhar-input-inactive"}`}
                                    value={address} onChange={e => handleChange("address", e.target.value)} />}
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    )
}