  
import user from "./user";
import agent from "./agent";
import customer from "./customer";
import opentok from "./opentok";
import aadharotp from "./aadharotp";

export default {user, opentok, customer, agent, aadharotp };


