import React, { Component } from "react";
import "./capture.css";
import { connect } from 'react-redux'
import {  socketInit, socket } from "../../Helpers";
import Back from "../../Images/back.svg";
import DialogComponent from '../DialogComponent'
import Webcam from "react-webcam";
import { LightCheck } from "../../Actions";
import { Button } from "@material-ui/core";
export class capture extends Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef(null);
    this.state={
      modal:false,
      image: "",
      facingMode: "user",
    }
    if (!socket) {
      this.props.socketInit()
    }
  }
  handleClick=()=> {
    this.setState({modal:true})
  }
  handleClose=()=> {
    this.setState({modal:false})
  }
  componentDidMount=()=> {
   this.Access()
  }
  onSubmit=(e)=> {
    e.preventDefault()
    this.props.LightCheck(this.state.image,this.props.history,(success)=> {
      console.log(success)
      if (success) {
        this.props.history.push('/consent')
      }
    })
  }
  CaptureImage = () => {
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.setState({ image: imageSrc });
  };
  Access=()=> {
    const handleOpen=()=> {
      this.setState({modal:true})
    }
    navigator.permissions.query({name:'camera'}).then(function(permissionStatus) {
      console.log('camera permission status is ', permissionStatus.state);
      if (permissionStatus.state==="denied") {
        handleOpen()
      }
      permissionStatus.onchange = function() {
        if (this.state==="denied") {
        handleOpen()
        }
        console.log('camera permission status has changed to ', this.state);
      };
    });
  }
  render() {
    console.log(this.props.data_type)
    if (this.state.modal) {
      return <DialogComponent desc={"Camera, Please Unblock your camera in your browser site settings and Then access KYC "} header={"Camera Access Denied"} modal={this.state.modal} handleClick={this.handleClick} handleClose={this.handleClose} status={"status"} Refresh={this.handleClose} buttonname={"OK"}/>
    }
    return (
      <React.Fragment>
      {this.props.data_type==="aadhar"?
      <div className="aadhar_back" onClick={() => this.props.history.push("/aadharxml")}>
          <img src={Back} alt="back" />
          <h3>BACK</h3>
      </div>:""}
          <div className="status">
            {this.props.data_type==="lightning"?<p>Please capture your image by pressing the below button to check your lighting conditions' suitability to complete this KYC process</p>:
            <div>
              {this.props.data_type==="sign"?<p>Please Show your Signature to your camera</p>:<p>
              {this.props.data_type==="aadhar"?"Please Show your AADHAAR Card to your camera":<div>
              {this.props.data_type==="pan"?"Please Show your PAN Card to your camera":""}</div>} </p>}
            </div>
            }
          </div>
          {this.props.data_type==="lightning"?
          <React.Fragment>
            <div className="captured_image_show mt-3">
              <div className="image_show">
                {this.state.image?this.state.image && <img src={this.state.image} alt="Captured pic"/>
              :<h6 className="mt-3 pt-5">Captured Image will be shown here</h6>}</div>
            </div>
          <div className="d-flex video_show pt-5 mt-5">
            <Webcam
              audio={false}
              ref={this.webcamRef}
              screenshotFormat="image/jpeg"
            >
            </Webcam>
          </div>
            <Button
          onClick={this.CaptureImage}
          variant="contained"
          color="primary"
          className=" image_capture_button mb-2 mt-2"
          style={{width:"100%"}}
        >
          Capture
        </Button>
            <Button
          variant="contained"
          color="primary"
          className="capture_button mt-3 mb-3"
          onClick={this.onSubmit}
          disabled={!this.state.image}
        >
          Submit
        </Button>
        </React.Fragment>
            :""}
      </React.Fragment>
    );
  }
}

const mapStateToProps=(state)=> {
  console.log(state)
  return {
    capture:state.aadharotp.capture,
    aadhar_xml:state.aadharotp.aadhar_xml,
    type:state.aadharotp.type,
    sign:state.aadharotp.sign

  }
}

const mapDispatchToProps= {
// Aadhar_capture
socketInit,
LightCheck
}
export default connect(mapStateToProps,mapDispatchToProps)(capture);