import React from "react";
import { useSelector } from "react-redux";
import CallGreenImage from "../../Images/call.svg";
import Stepper from "./Stepper";
// import agent from "../../Reducers/agent";
// import Back from "../../Images/back.svg";

export default () =>{
    const { steps={active : 0}, extraction={},aadhar_front={} } = useSelector( ({agent}) => agent);
    const { name="IN-D" } = extraction[0]?extraction[0]:aadhar_front?aadhar_front:{};
    return (
        <div className="agent-call-header">
            <div className="header-left-section">
                <div className="call-green-image-pre-wrapper center">
                    <div className="call-green-image-wrapper center">
                        <img className="call-green-image" src={CallGreenImage} />
                    </div>
                </div>
                <div className="opentok-header-labels">
                        <span className="header-label">{name}</span>
                        <span>Ongoing video call</span>
                    </div>
            </div>
            <div className="header-right-section">
                <Stepper active={steps.active}/>
            </div>
        </div>
    )
}