import React, { Component } from 'react'
import Capture from '../../Components/DataCapture/capture'
import { connect } from 'react-redux'
import { changeHeaderonAadhar } from '../../Actions'
import { socket, socketInit } from "../../Helpers";

export class signature extends Component {
    constructor(props) {
        super(props);
        if (!socket) {
            this.props.socketInit();
        }
        this.props.changeHeaderonAadhar({step:3,title:"Signature",visible:true,border_visible:true,border:60})
    }
    render() {
        return (
            <div>
                <Capture data_type="sign" history={this.props.history}/>
            </div>
        )
    }
}

const mapdispatchToProps = {
    changeHeaderonAadhar,
    socketInit
  };
export default connect(()=>({}),mapdispatchToProps)(signature);

