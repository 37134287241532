import { KYC_SIT, PATH, API_ID, ActionType } from "../../Constants";
import axios from "axios";
import { toast } from "react-toastify";
import { emit } from "../../Helpers";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

export const requestAgentService = (company,url) => async (dispatch,getState) => {
  const {user={}}=getState().user
  const {token='',language=""}=user
  const consumer_company=`${company}_${language}`
  
  console.log(url)
  try {
    dispatch({ type: ActionType.AGENT_SERVICE_REQUEST });
    // dispatch({type:ActionType.URL_UPDATE,payload:data})
    const req = await (await fetch(`${KYC_SIT}${PATH.agentOpentok}${consumer_company}`,{
      method: 'POST',mode: 'cors', headers: {
        body: JSON.stringify({}),
        'Content-Type': 'application/json',
        // Authorization:`Bearer ${token}`
      }
    }) ).json();
    console.log("requestAgentService res", req);
    dispatch({ type: ActionType.AGENT_SERVICE_COMPLETED });

    if (req && req.Token) {
      const {
        Company = "",
        Session_id = "",
        api_key = "",
        Token = "",
        user_id,
        uid,
        user_token,
        userlocation,
      } = req;
      const payload = {
        Session_ID: Session_id,
        apikey: api_key,
        token: Token,
        Company,
        user_token,
        user_id,
        uid,
        userlocation,
        url
      };
      dispatch({ type: ActionType.OPENTOK_COMPLETED, payload });
      return new Promise((res) => res(payload));
    } else {
      // toast.error(req.desc);
      return new Promise((res) => res(null));
    }
  } catch (error) {
    console.log("opnetok tokens catch error ", error);
    dispatch({ type: ActionType.OPENTOK_COMPLETED, payload: {} });
    dispatch({ type: ActionType.AGENT_SERVICE_FAILED });
    return new Promise((res) => res(null));
  }
};

export const agentAadharStep = (payload) => (dispatch) =>{
  dispatch({ type: ActionType.AGENT_VIDEO_COMPONENTS, payload });
}

export const saveAadhar = (data, type,agent_override) => async (dispatch, getState) => {
  console.log(agent_override)
  let new_filename=""
  const { session = {} } = getState().opentok;
  const { steps = {}, extraction = {},aadhar_front={} } = getState().agent;
  const { uid = "", user_token = "", user_id = "",Session_ID="" } = session;
  // const {type=''} = extraction&&extraction[0]?extraction[0]:{};
  if (type === "aadhar") {
    const {filename=""}=aadhar_front&&aadhar_front?aadhar_front:{}
    new_filename=filename
  }else {
    const {filename=""}=extraction[steps.active]?extraction[steps.active]:{}
    if (filename) {
      new_filename=filename
    }else {
      new_filename="pan.jpeg"
    }
  }
  const {dob = "N/A",address = "N/A",name = "N/A",fathername = "N/A",number = "",gender=""} = data;
  const types = {
    aadhar: "Aadhar Card Front",
    pan: "PAN Card",
    dl: "Driving Licence",
  };
  const numbers = {
    aadhar: { AADHAR_NUMBER: number },
    pan: { PAN: number },
    dl: { DL_NUMBER: number },
  };
  let nums = numbers[type] ? numbers[type] : {};
  let adds = type === "aadhar" ? { ADDRESS: address } : {};
  try {
    const docType = types[type];
    console.log(docType);
    const body = {
      ...nums,
      ...adds,
      DOB: dob,
      DOC_TYPE: docType,
      FATHER_NAME: fathername,
      NAME: name,
      GENDER:gender,
      // filename: `${type}${new Date().getTime()}.jpeg`,
      filename:new_filename,
      validation: false,
    };

    dispatch({ type: ActionType.SAVE_AADHAR_REQUEST });
    const res = await axios.post(
      `${KYC_SIT}${PATH.validation}${uid}`,
      body,
      createJsonHeader(user_token)
    );
    console.log("edit edit edit ", res);
    const editdata = [];
    if (res.data.status === "success") {
      dispatch({ type: ActionType.SAVE_AADHAR_COMPLETE });
      const response = formatData(body);
      if (response && response.name) 
      console.log(response);
      editdata.push(response);
      console.log(editdata);
      dispatch({
        type: ActionType.IMAGE_EXTRACTION_COMPLETED,
        payload: { ...extraction, [steps.active]: response },
      });
      dispatch({type:ActionType.AGENT_OVERRIDE,payload:agent_override})
      console.log(editdata,"editadataaaaaaaaaaaaaaaaaaaaaaaaaa")
      emitData({ type, data: editdata }, uid, user_id,Session_ID);
      toast.success("Data updated successfully");
    } else {
      dispatch({ type: ActionType.SAVE_AADHAR_FAILED });
      toast.error(res.data.desc);
    }
  } catch (error) {
    console.log("save details catch error ", error);
    dispatch({ type: ActionType.SAVE_AADHAR_FAILED });
    toast.error("Something bad has happened, Please try again later");
  }
};

export const setDocType = (type) => (dispatch) => {
  dispatch({ type: ActionType.CHANGE_DOC_TYPE, payload: type });
};

export const createFormHeader = (formdata, token) => {
  return {
    method: "POST",
    data: formdata,
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const createJsonHeader = (token) => {
  return {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const livelinessdatafromuser=(data)=> (dispatch,getState)=> {
  const { extraction = {} } = getState().agent;
  console.log(data)
  if (data){
  dispatch({type:ActionType.LIVELINESS_DATA_FROM_USER,payload:{...extraction, [3]: data}})
  }
}
export const imageExtractionFromUser = (data) => (dispatch, getState) => {
  const { extraction = {} } = getState().agent;
  const response = formatData(data);
  console.log(response)
  if (response && (response.name || response.address || response.number)) {
    toast.success("Aadhar xml data recieved successfuly");
    dispatch({
      type: ActionType.IMAGE_EXTRACTION_COMPLETED,
      payload: { ...extraction, [0]: response },
    });
  } else dispatch({ type: ActionType.IMAGE_EXTRACTION_FAILED });
};
export const setImageExtractLoader = () => (dispatch) => {
  dispatch({ type: ActionType.IMAGE_EXTRACTION_REQUEST });
  setTimeout(() => {
    dispatch({ type: ActionType.IMAGE_EXTRACTION_FAILED });
  }, 10000);
};
export const imageExtraction = (image, type = "aadhar") => async (dispatch,getState) => {
  let response = {};
  try {
    const { steps = {}, extraction = {},aadhar_front={} } = getState().agent;
    const { session = {} } = getState().opentok;
    const { uid = "", user_token = "", user_id = "",Session_ID="" } = session;
    const { user = {} } = getState().user;
    const { active = 0 } = steps;
    const { agent_id = "", token } = user;
    dispatch({ type: ActionType.IMAGE_EXTRACTION_REQUEST });
    const bToFile = await fetch(image);
    const blob = await bToFile.blob();
    const filename=`${type}${new Date().getTime()}.jpeg`
    const file = new File([blob], filename, { type: "image/jpeg" });
    let formdata = new FormData();
    if (type === "signature") {
      formdata.append("image", file);
      response = await signature(formdata, uid, user_token, type, user_id,dispatch,Session_ID);
    } else {
      formdata.append("images", file);
      response = await extractions(
        formdata,
        uid,
        user_token,
        user_id,
        type,
        dispatch,
        Session_ID,
        getState,
      );
    }
    console.log("image extraction ", response);
    if (response.name || response.number ||response.address ||response.success) {
      if (response.type=== "Aadhar Card Front"){
        toast.success("Your Aadhar front document is being verified please upload Aadhar back also.")
        dispatch({type: ActionType.AADHAAR_FRONT_IMAGE,payload:image});
        dispatch({type: ActionType.AADHARFRONT_CARD,payload: response});
      }
      else {
      toast.success("Image extraction successful");
      dispatch({
        type: ActionType.IMAGE_EXTRACTION_COMPLETED,
        payload: { ...extraction,[active]: response },
      });
    }
    }
  } catch (error) {
    toast.error("Image extraction failed, Please try again");
    dispatch({ type: ActionType.IMAGE_EXTRACTION_FAILED });
  }
};

const signature = async (formdata, id, token, type, user_id,dispatch,Session_ID) => {
  const bucket = await axios(
    `${KYC_SIT}${PATH.bucket}${id}`,
    createFormHeader(formdata, token)
  );
  console.log("bucket response ", bucket);
  if (bucket.data.status === "fail") {
    dispatch({ type: ActionType.IMAGE_EXTRACTION_FAILED });
    return toast.error(bucket.data.desc);
  }
  if (bucket.data.status === "success") {
    emitData({ type }, id, user_id,Session_ID);
  }
  return {
    success:
      bucket && bucket.status === 200 && bucket.data.status === "success",
  };
};

const extractions = async (formdata, id, token, user_id, type, dispatch,Session_ID,getState) => {
  const {aadhar_front={}}=getState().agent;
  const extrTypes = [
    "Aadhar_XML",
    "aadhar_xml",
    "voter id",
    "pan card",
    "pan_card",
    "PAN Card",
    "voter_id",
    "Aadhar Card Front",
    "aadhar card front",
    "aadhar card back",
    "DL_NUMBER",
    "AADHAR_NUMBER",
    "driving licence",
    "passport_number",
  ];
  const classification = await axios(
    `${KYC_SIT}${PATH.classification}${id}`,
    createFormHeader(formdata, token)
  );
  console.log("classifications ", formdata, classification);
  let data = {};
  console.log(classification.data.result[0].document_type);
  if (
    classification.data.result[0].document_type === "Unknown Category" ||
    classification.data.result[0].document_type === "NA"
  ) {
    dispatch({ type: ActionType.IMAGE_EXTRACTION_FAILED });
    return toast.error("Classification failed");
  }
  if (classification && classification.data && classification.data.result) {
    const document = classification.data.result[0].document_type;
    const extraction = await axios(
      `${KYC_SIT}${PATH.imageExtraction}${id}`,
      createFormHeader(formdata, token)
    );
    console.log("extraction extraction ", extraction);
    if (extraction.status === 200) {
      const { result = [] } = extraction.data ? extraction.data : {};
      console.log(result)
      // const extr = result.filter(
      //   (item) =>
      //     extrTypes.indexOf(item.DOC_TYPE && item.DOC_TYPE.toLowerCase()) > -1
      // );
      const extr = result.filter(
        (item) => item.DOC_TYPE === document);
      console.log(extr)
      data = extr[0] ? extr[0] : {}; //dummyA;
      if (document==="Aadhar Card Front"){
        console.log("aadhar front ")
      }else {

      emitData({ type, data: extr,data1:aadhar_front }, id, user_id,Session_ID);
    }
      return formatData(data);

    } else return data; //formatData(dummyA)//data;
  } else return data; //formatData(dummyA)//data;
};
export const formatData = (obj) => {
  console.log(obj)
  const {
    photo,
    filename,
    ADDRESS,
    DOC_TYPE = "",
    NAME,
    GENDER,
    DOB,
    FATHER_NAME,
    randdata,
    msg,
    ...details
  } = obj;
  const doc = DOC_TYPE.toLowerCase();
  let data = {
    photo,
    filename,
    name: NAME,
    address: ADDRESS,
    gender: GENDER,
    dob: DOB,
    fathername: FATHER_NAME,
    type: DOC_TYPE,
    randdata,
    msg
    
  };
  if (doc === "driving licence" || doc === "driving_licence")
    return { ...data, number: details["DL_NUMBER"] };
  if (doc === "aadhar_xml")
    return { ...data, number: details["AADHAR_NUMBER"] };
  if (doc === "voter id" || doc === "voter_id")
    return { ...data, number: details["VOTER_ID"] };
  if (doc === "pan card" || doc === "pan_card")
    return { ...data, number: details["PAN"] };
  if (doc === "aadhar card front")
    return { ...data, number: details["AADHAR_NUMBER"] };
  if (doc === "Aadhar Card Front")
    return { ...data, number: details["AADHAR_NUMBER"] };
  if (doc === "Aadhar Card Back")
    return { ...data, number: details["AADHAR_NUMBER"] };
  if (doc === "aadhar card back")
    return { ...data, number: details["AADHAR_NUMBER"] };
  if (doc === "passport_number") return { ...data, number: "" }; //details['AADHAR_NUMBER']
  if (doc==="") return {...data}
};

export const emitData = (data, uid, user_id,Session_ID) => {
  
  emit("agent-to-user", {
    uid,
    user_id,
    alias: "image_capture_extracted_data",
    data,
    session_id:Session_ID,
    side:"AGENT"
  });
};

export const clearExtraction = () => (dispatch) => {
  dispatch({ type: ActionType.IMAGE_EXTRACTION_COMPLETED, payload: {} });
  dispatch({ type: ActionType.AGENT_VIDEO_COMPONENTS, payload: { active: 0 } });
  dispatch({ type: ActionType.AGENT_PROFILE_PAGE_COMPLETED, payload: {} });
  dispatch({ type: ActionType.AGENT_PROFILE_PAGE_SUCCESS, payload: {} });
  dispatch({ type: ActionType.COMPANY_NAME_UPDATE, payload: "" });
  dispatch({type:ActionType.OPENTOK_COMPLETED,session:{}})
  dispatch({type:ActionType.AGENT_SCREEN_CAPTURE_STOP_MSG,payload:""})
  dispatch({type:ActionType.AGENTRESET})
};

// agent profile api calling

export const agentProfileData = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ActionType.AGENT_PROFILE_PAGE_REQUEST });
    const { user = {} } = getState().user;
    const { session = {} } = getState().opentok;
    const { uid = "", user_token = "" } = session;
    const profile = await axios(`${KYC_SIT}${PATH.agentprofilepage}${uid}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${user_token}`,
      },
    });
    console.log("profile profile  ", profile); // TODO check the condition here
    if (profile.data.status === "success")
      dispatch({
        type: ActionType.AGENT_PROFILE_PAGE_COMPLETED,
        payload: profile.data.result,
      });
    else dispatch({ type: ActionType.AGENT_PROFILE_PAGE_FAILED });
  } catch (error) {
    console.log("profile catch error ", error);
    dispatch({ type: ActionType.AGENT_PROFILE_PAGE_FAILED });
  }
};

export const changeProfileData = (state,onClose) => async (dispatch, getState) => {
  const { agentprofile = {} } = getState().agent;
  const changeagentprofile = [];
  agentprofile&&agentprofile.changes&&agentprofile.changes.push(state);
  changeagentprofile.push(agentprofile);
  const { session = {} } = getState().opentok;
  const { uid = "", user_token = "",Session_ID="" } = session;
  console.log(changeagentprofile[0])
  try {
  dispatch({ type: ActionType.AGENT_PROFILE_PAGE_REQUEST });
    const changes = await axios.post(
      `${KYC_SIT}${PATH.profileChanges}${uid}`,
      JSON.parse(JSON.stringify(changeagentprofile[0])),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user_token}`,
        },
      }
    );
    console.log("changes changes", changes);
    if (changes.data.status === "success") {
      emit("agent-to-user", {
        alias: "agent-overide-data",
        session_id:Session_ID,
        side:"AGENT"
      });
      dispatch({
        type: ActionType.AGENT_PROFILE_PAGE_SUCCESS,
        payload: changes.data,
       
      });
      setTimeout(()=> {
        dispatch({type:ActionType.AGENT_SCREEN_CAPTURE_STOP_MSG,payload:"stop"})
        onClose()
      },2000)
      // dispatch(
      //   agentProfileData({
      //     type: ActionType.AGENT_PROFILE_PAGE_COMPLETED,
      //     payload: changeagentprofile[0],
      //   }),
      //   setTimeout(()=> {
      //       onClose()
      //   },2000)
      // ); // pull for latest updated data
    } else {
    dispatch({ type: ActionType.AGENT_PROFILE_PAGE_FAILED });
    console.log("data")
    }
  } catch (error) {
    console.log("profile catch error ", error);
    dispatch({ type: ActionType.AGENT_PROFILE_PAGE_FAILED });
  }
};

export const Screencapture = (videodata,history) => async (dispatch, getState) => {
  console.log(videodata);
  const { session = {} } = getState().opentok;
  const { uid = "", user_token = "" } = session;
  let formdata = new FormData();
  formdata.append("video", videodata);
  dispatch({ type: ActionType.SCREEN_CAPTURE_REQUEST });
  toast.success("Recorded Video is stoped and Uploading to the server")
  try {
    const screencapture = await axios.post(
      `${KYC_SIT}${PATH.screencapture}${uid}`,
      formdata,
      {
        method: "POST",
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${user_token}`
        },
      }
    );
    console.log(screencapture);
    if (screencapture.data.status === "success") {
      toast.success("Video is Successfully Uploaded to server.");
      history.push('/agent')
    }
    dispatch({
      type: ActionType.SCREEN_CAPTURE_COMPLETED,
      payload: screencapture,
    });
  } catch (error) {
    dispatch({ type: ActionType.SCREEN_CAPTURE_FAILED });
    toast.error("Video upload failed disconnect the call to view dashboard")
  }
};

export const Agentidinsertion = () => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { agent_id = "",token="" } = user;
  const { session = {} } = getState().opentok;
  const { user_id = "" } = session;
  let formData = new FormData();
  formData.append("agentid", agent_id);
  formData.append("userid", user_id);
  try {
    const uidinsert = await axios.post(
      `${KYC_SIT}${PATH.agentidinsertion}`,
      formData,
      {
        method: "POST",
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
      }
    );
    console.log(uidinsert);
  } catch (error) {
    console.log("error", error);
  }
};

export const Validation = () => async (dispatch, getState) => {
  const { session = {} } = getState().opentok;
  const { uid = "", user_token = "" } = session;
  console.log(user_token)
  try {
    const validation = await axios(
      `${KYC_SIT}${PATH.aadharvalidation}${uid}`,{
        method: "POST",
        headers: {
          Authorization: `Bearer ${user_token}`
        },
      }
    );
    if(validation.data.status	==="success"){
    console.log(validation.data.result);
    console.log(validation.data.result[0])
      dispatch({type:ActionType.AADHAR_VALIDATION,payload:validation.data.result[0]})
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const Remarks = (remark) => async (dispatch, getState) => {
  const { session = {} } = getState().opentok;
  const { uid = "", user_token = "" } = session;
  try {
    let formData = new FormData();
    formData.append("remarks", remark);
    formData.append("uid", uid);
    const remarks = await axios.post(
      `${KYC_SIT}${PATH.update_remarks}`,formData,{
        headers: {
          Authorization: `Bearer ${user_token}`
        },
      }
    );
    console.log(remarks)
    if(remarks.data.status==="success"){
    toast.success("Successfully Updated Remarks.")
    }else {
      toast.error(remarks.data.desc)
    }
  } catch (error) {
    console.log("error", error);
    toast.error("Remarks is not updated")
    // toast.error(error.data.desc)
  }
};