import React from "react"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckImage from "../../Images/check.svg";


const StepIconComponent = ({ active, completed, icon }) => {
    return completed ? <div className="completed-check-circle center"> <img src={CheckImage}/> </div> :
     <div className={`check-circle center ${active?"active-check-circle":""}`}> {icon} </div> 
} 
export default ({active=0}) => {
    const steps = ["Aadhaar","OVD","Signature","Liveliness Check"]; //,"Liveliness Check"
    return (
        <div className="stepper-conatiner">
            <Stepper activeStep={active} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel StepIconComponent={StepIconComponent}>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
        </div>
    )
}