import { KYC_SIT, PATH, ActionType } from "../Constants";
import { toast } from "react-toastify";
import axios from 'axios'
export const signup = ({ name, email, phone, company,customer_id,language },data) => async (dispatch) => {
  try {
    dispatch({ type: ActionType.USER_REQUEST });
    let formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("company", company);
    formData.append("customer_id",customer_id)
    formData.append("language",language)
    const user = await (
      await fetch(`${KYC_SIT}${PATH.custRegister}`, {
        method: "POST",
        body: formData,
      })
    ).json();
    console.log(user)
    console.log(user.status)
    if (user.status === "success") {
      dispatch({
        type: ActionType.USER_COMPLETE_PRE,
        payload: { UserID: user.UserID, name, email, phone, company,url:data,language },
      });
      return new Promise((res) => res(true));
    }else if (user.status==="fail"||user.status==="Insertion Failed") {
      toast.error(user.desc)
      dispatch({ type: ActionType.USER_COMPLETE, payload: {} });
    }
     else {
      dispatch({ type: ActionType.USER_COMPLETE, payload: {} });
      return new Promise((res) => res(false));
    }
  } catch (error) {
    console.log("signup catch error ", error);
    toast.error(error)
    dispatch({ type: ActionType.USER_COMPLETE, payload: {} });
    return new Promise((res) => res(false));
  }
};


export const Download = (mainkey) => async (dispatch,getState) => {
  const { user = {} } = getState().user;
  const { token } = user&&user;
  try {
    const user = axios(`${KYC_SIT}${PATH.download}/${mainkey}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    console.log(user)
    
  } catch (error) {
    toast.error(error)
  }
};

export const logout = () => (dispatch) => {
  // call here api to clear session on backend
  dispatch({ type: ActionType.LOGOUT });
  dispatch({ type: ActionType.RESET });
};
export const Agentreset = () => (dispatch) => {
  // call here api to clear session on backend
  dispatch({ type: ActionType.AGENTRESET });
};
export const Modal=(state)=> (dispatch) => {
  console.log(state)
  dispatch({type:ActionType.MODAL_CHANGE,modal:state})
}