import React, { Component } from 'react';
import './side-menu.css';

import '../../../AgentViews/dashboard/dashboard.css';

import Tooltip from '@material-ui/core/Tooltip';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import {
	Link
} from "react-router-dom";

import $ from 'jquery';

export default class SideMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
		};


	}

	componentDidMount=()=> {
		if(this.props.highlight === "dash") {
			$('#sideElements1').addClass('backColor');
		}
		else if(this.props.highlight === "set") {
			$('#sideElements3').addClass('backColor');
		}
	}


	open=()=> {
		this.setState({ showModal: true });
	}

	close=()=> {
		this.setState({ showModal: false });
	}

	logout=()=> {
		localStorage.clear();
		window.location.href = '/agent';
	}

	render() {
		return (
			<div>
				<nav id="sidebar" className="activexyz">

					<ul id="sideNavTop" className="list-unstyled components">

						<li id="logoLi">

							<div id="" >
								<div className="activexyz logo" >
								</div>
							</div>

						</li>

						{/* <ReactTooltip place="right" type="dark" effect="solid" style={{overflow: "visible"}} /> */}
						<Tooltip title="Dashboard" placement="right">
							<li>
								<Link to="/Dashboard" style={{ textDecoration: 'none' }}>
									<div data-tip="Dashboard" id="sideElements1" className="">
										<div className="activexyz Dashboard" >
											<p> Dashboard </p>
										</div>
									</div>
								</Link>
							</li>
						</Tooltip>




						{/* <Tooltip title="Settings" placement="right">
							<li id="set" className="">
								<Link to="/Settings" style={{ textDecoration: 'none' }}>
									<div id="sideElements3" className="">
										<div className="activexyz Settings" >
											<p> Settings </p>
										</div>
									</div>
								</Link>
							</li>
						</Tooltip> */}


					</ul>


					<ul id="sideNavBottom" className="list-unstyled components">

						{/* <Tooltip title="Information" placement="right">
							<li>
								<div id="sideElements4" className="">
									<div className="activexyz Information" >
										<p> Information </p>
									</div>
								</div>
							</li>
						</Tooltip> */}

						<Tooltip id="tool" title="Logout" placement="right">
							<li>
								<div id="sideElements" onClick={this.open}>
									<div className="activexyz Logout">
										<p> Logout </p>
									</div>

								</div>
								<Modal show={this.state.showModal} onHide={this.close}>
									<Modal.Header closeButton>
										<Modal.Title>Are you sure you want to logout?</Modal.Title>
									</Modal.Header>
									<Modal.Body className="row  justify-content-center" >
										<Button id="logoutButton" onClick={this.logout}>Yes</Button>
										<Button id="logoutButton" onClick={this.close}>No</Button>

									</Modal.Body>
								</Modal>
							</li>
						</Tooltip>

					</ul>

				</nav>
			</div>
		)
	}
}