import { ActionType, KYC_SIT, PATH } from "../Constants";
import axios from "axios";
import { generateUid } from "./opentok";
import { emit } from "../Helpers";
import { toast } from "react-toastify";
import { createFormHeader, createJsonHeader } from "./agent/opentok";

export const Aadhar_Number = (postData) => async (dispatch, getState) => {
  const { user = {}, uid = "" } = getState().user;
  const { token = "" } = user;
  let formData = new FormData();
  formData.append("number", postData);
  dispatch({ type: ActionType.AADHAR_NUMBER_SENT_OTP_REQUEST });
  const aadhardata = await axios(
    `${KYC_SIT}${PATH.generateotp}${uid}`,
    createFormHeader(formData, token),
    {}
  )
    .then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        toast.success("Successfully sent OTP to your mobile number");
        dispatch({
          type: ActionType.AADHAR_NUMBER_SENT_OTP_COMPLETE,
          payload: res.data.result.client_id,
        });
      } else {
        dispatch({ type: ActionType.AADHAR_NUMBER_SENT_OTP_FAILED });
        toast.error(res.data.desc);
      }
    })
    .catch((error) => {
      dispatch({ type: ActionType.AADHAR_NUMBER_SENT_OTP_FAILED });
      if (error) {
        if (error.response&&error.response.status===500) {
          toast.error("Internal Server Error")
        }else if (error.response) {
          toast.error(error.response.data.message)
        }
        else {
          toast.error("Unable to send otp to your mobile number");
        }
      }
    });
};
export const Submit_Otp = (res) => async (dispatch, getState) => {
  const { user = {}, uid = "" } = getState().user;
  const { token = "", UserID = "" } = user;
  const { session = {} } = getState().opentok;
  let formData = new FormData();
  formData.append("otp", res.otp);
  formData.append("client_id", res.client_id);
  dispatch({ type: ActionType.AADHAR_XML_FIELDS_REQUEST });
  try {
    const submitotp = await axios(
      `${KYC_SIT}${PATH.submitotp}${uid}`,
      createFormHeader(formData, token),
      {}
    ).then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        if (res.data.result.document_type === "XML") {
          toast.success("Aadhaar OTP uploaded successfully");
        }
        const imagedata = axios(
          `${KYC_SIT}${PATH.imageExtraction}${uid}`,
          createFormHeader(formData, token),
          {}
        ).then((res) => {
          console.log(res);
          const newArr = res.data.result.filter(
            (obj) => obj.DOC_TYPE === "Aadhar_XML"
          );
          dispatch({
            type: ActionType.AADHAR_XML_FIELDS_COMPLETED,
            payload: newArr,
          });
          if (newArr && newArr.length)
            emit("agent-to-user", {
              uid,
              user_id: UserID,
              alias: "aadhar_xml_extracted_data",
              data: newArr[0],
              session_id: session.Session_ID,
              side: "USER",
            });
        });
      } else {
        dispatch({ type: ActionType.AADHAR_XML_FIELDS_FAILED });
        toast.error(res.data.desc);
      }
    });
  } catch (error) {
    console.log(error.response)
      dispatch({ type: ActionType.AADHAR_XML_FIELDS_FAILED });
      if (error) {
        if (error.response&&error.response.status===500) {
          toast.error("Internal Server Error")
        }else if (error.response) {
          toast.error(error.response.data.message)
        }
        else {
          toast.error("Aadhaar OTP Submission Failed");
        }
      }
  }
};

// Aadharxml upload Actions

export const Aadharxml_upload = (upload) => async (dispatch, getState) => {
  const { user = {}, uid = "" } = getState().user;
  let { token = "", UserID = "" } = user;
  const { session = {} } = getState().opentok;

  let formData = new FormData();
  formData.append("zipfile", upload.file, upload.filename);
  formData.append("phone", upload.phone);
  formData.append("sharecode", upload.code);
  try {
    dispatch({ type: ActionType.AADHAR_XML_FIELDS_REQUEST });
    const data = await axios(
      `${KYC_SIT}${PATH.aadharxml}${uid}`,
      createFormHeader(formData, token),
      {}
    ).then((res) => {
      console.log(res);
      if (res.status === 207) {
        toast.error(res.data.desc);
        dispatch({ type: ActionType.AADHAR_XML_FIELDS_FAILED });
      }
      if (res.data.status === "success") {
        if (res.data.result.document_type === "XML")
          toast.success("AadharXML Uploaded Successfully");
        const imagedata = axios(`${KYC_SIT}${PATH.imageExtraction}${uid}`,createFormHeader(formData, token))
          .then((res) => {
            const newArr = res.data.result.filter(
              (obj) => obj.DOC_TYPE === "Aadhar_XML"
            );
            dispatch({
              type: ActionType.AADHAR_XML_FIELDS_COMPLETED,
              payload: newArr,
            });
            if (newArr && newArr.length)
              emit("agent-to-user", {
                uid,
                user_id: UserID,
                alias: "aadhar_xml_extracted_data",
                data: newArr[0],
                session_id: session.Session_ID,
                side: "USER",
              });
          })
          .catch((error) => {
            toast.error(error)
            dispatch({ type: ActionType.AADHAR_XML_FIELDS_FAILED });
          })
      }
    });
  } catch (error) {
    dispatch({ type: ActionType.AADHAR_XML_FIELDS_FAILED });
    if (error) {
      if (error.response&&error.response.status===500) {
        toast.error("Internal Server Error")
      }else if (error.response) {
        toast.error(error.response.data.message)
      }
      else {
        toast.error("Aadhaarxml Submission Failed");
      }
    }
  }
};

export const Liveliness_page = (videodata) => async (dispatch, getState) => {
  const { user = {}, uid } = getState().user;
  const { token, UserID = "" } = user;
  const { session = {} } = getState().opentok;
  try {
  const randdata = videodata.rand;
  var formData = new FormData();
  formData.append("video", videodata.fileObject);
  formData.append("term", videodata.rand);
  // emit("agent-to-user", {
  //   uid,
  //   user_id: UserID,
  //   alias: "Liveliness_data_from_user",
  //   data: { randdata, msg: "Started", state: true },
  //   session_id: session.Session_ID,
  //   side: "USER",
  // });
  dispatch({ type: ActionType.LIVELINESS_PAGE_REQUEST });
  const livedata = await axios(
    `${KYC_SIT}${PATH.livelines}${uid}`,
    createFormHeader(formData, token),
    {}
  )
    .then((res) => {
      console.log(res);
      if (res.data.status === "success") {
        toast.success("Your Livelines video successfully recorded");
        dispatch({ type: ActionType.LIVELINESS_PAGE_SUCCESS, payload: res });
        emit("agent-to-user", {
          uid,
          user_id: UserID,
          alias: "Liveliness_data_from_user",
          data: { randdata, msg: "Success", state: false },
          session_id: session.Session_ID,
          side: "USER",
        });
      }
    })
  }
    catch(error) {
      toast.error("Liveliness video is not recorded");
      dispatch({ type: ActionType.LIVELINESS_PAGE_FAILED });
    };
};

export const Profile_page = () => (dispatch, getState) => {
  const { user = {}, uid } = getState().user;
  const { token } = user;
  dispatch({ type: ActionType.PROFILE_PAGE_REQUEST });
  axios(`${KYC_SIT}${PATH.profileChanges}${uid}`, createJsonHeader(token), {})
    .then((res) => {
      console.log(res);
      dispatch({
        type: ActionType.PROFILE_PAGE_COMPLETED,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      toast.error("Something Error while fetching profile data");
      dispatch({ type: ActionType.PROFILE_PAGE_FAILED });
    });
};

export const OTP_Page = (otpdata) => async (dispatch, getState) => {
  try {
    const { user = {} } = getState().user;
    const { token = "" } = user;
    let formData = new FormData();
    formData.append("otp", otpdata.otp);
    formData.append("userID", otpdata.userID);
    const response = await (
      await fetch(`${KYC_SIT}${PATH.otp}`, {
        method: "POST",
        body: formData,
      })
    ).json();
    console.log(response)
    
    if (response.status === "success") {
      toast.success("Successfully Submitted OTP");
      dispatch({ type: ActionType.OTP_PAGE_REQUEST, payload: response });
      dispatch(createToken(user));
    }else if (response.status==="fail") {
      toast.error(response.desc)
    }
     else {
      toast.error("Incorrect OTP");
    }
  } catch (error) {}
};
const createToken = (user) => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { company = "",location } = user;
  try {
  let formData = new FormData();
  formData.append("company_name", company);
  const response = await axios(`${KYC_SIT}${PATH.company_apitoken}`, {
    method: "POST",
    data: formData,
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
  console.log(response);
  if (response.data.status === "success") {
    const data = { ...user, token: response.data.Auth_token,youtubelink:response.data.youtubelink };
    dispatch({ type: ActionType.USER_COMPLETE, payload: data });
    dispatch(generateUid());
  }
  if (response.status===207) {
    toast.error(response.data.desc)
  }
}
  catch(error) {
    if (error&&error.response){
      toast.error(error.response.data.message)
      }else {
        toast.error("Token is not generated")
      }
  }
};
export const Geolocation = (lat, lng, cb) => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { token } = user;
  dispatch({ type: ActionType.RESET_AADHAR });
  try {
    const response = await axios(`${KYC_SIT}${PATH.geocoding}/${lat},${lng}`,createJsonHeader(token));
    console.log(response);
    // if (response.data.result.cc!=="IN") {
    //   cb(true)
    //   toast.info(`Hey,You are Outside of India:\n${response.data.result.admin1},${response.data.result.name}`);
    //   dispatch({ type: ActionType.GEO_LOCATION, payload:"out_india" });
    // }
    // else if (response.data.status==="success") {
    //   cb(true)
    //   dispatch({ type: ActionType.GEO_LOCATION, payload: response.data.result });
    //   let country;
    //   if (response.data.result.cc === "IN") {
    //     country = "INDIA";
    //   }
    //   toast.info(
    //     `Hey,You current location is ${country}\nState:${response.data.result.admin1}`
    //   );
    // }
    if (response.data.status==="success") {
      if (response.data.location==="Outside India") {
          cb(true)
          toast.info(`Hey,You are Outside of India:\n${response.data.result}`);
          dispatch({ type: ActionType.GEO_LOCATION, payload:"out_india" });
      }
      else if (response.data.location==="Inside India") {
        cb(true)
        dispatch({ type: ActionType.GEO_LOCATION, payload: response.data.result });
        toast.info(`Hey,You current location is: ${response.data.result}`);
      }
    }

  } catch (error) {
    if (error&&error.response) {
      toast.error(error.response.data.message);
    }else {
      toast.error("Something went wrong In location.")
    }
  }
};
export const Uidinsertion = () => async (dispatch, getState) => {
  const { user = {}, uid } = getState().user;
  const { UserID, token } = user;
  let formData = new FormData();
  formData.append("uid", uid);
  formData.append("userid", UserID);

  try {
    const uidinsert = await axios
      .post(`${KYC_SIT}${PATH.uidupload}`, formData, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => console.log(res));
  } catch (error) {
    console.log("error", error);
  }
};
export const Statusupdate = () => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { UserID, token } = user;
  let formData = new FormData();
  formData.append("userid", UserID);
  try {
    const uidinsert = await axios
      .post(`${KYC_SIT}${PATH.statusupdate}`, formData, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === "success") {
          dispatch({ type: ActionType.USER_STATUS_UPDATE, payload: res.data });
        }
      });
  } catch (error) {
    console.log("error", error);
  }
};

export const Current_Address = (mainaddress, cb) => async (
  dispatch,
  getState
) => {
  const { user = {}, uid } = getState().user;
  const { token } = user;
  let formData = new FormData();
  formData.append("address", mainaddress);
  try {
    const response = await axios(
      `${KYC_SIT}${PATH.current_address}${uid}`,
      createFormHeader(formData, token),
      {}
    );
    if (response.status === 200) {
      cb(true);
      toast.success("Your current address is successfully submited.");
      dispatch({type:ActionType.CURRENT_ADDRESS_SUCCESSFUL,payload:mainaddress})
    } else {
      cb(false);
      toast.error(response.data.desc);
      dispatch({type:ActionType.CURRENT_ADDRESS_FAILURE})
    }
  } catch (error) {
    cb(false);
    dispatch({type:ActionType.CURRENT_ADDRESS_FAILURE})
    toast.error("Current Address is not updated");
  }
};
let light_count=0
export const LightCheck = (image,history,cb) => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { token } = user;
  const bToFile = await fetch(image);
  const blob = await bToFile.blob();
  const filename=`${"filename"}${new Date().getTime()}.jpeg`
  const file = new File([blob], filename, { type: "image/jpeg" });
  let formData = new FormData();
  formData.append("image", file);
  dispatch({type:ActionType.LIGHTNING_CONDITION_REQUEST})
  try {
    const light = await axios
      .post(`${KYC_SIT}${PATH.lightcheck}`, formData, {
        method: "POST",
        headers:{
          Authorization: `Bearer ${token}`
        }
      })
      console.log(light)
      if (light.data.result==='dark') {
        cb(false)
        light_count+=1
        if (light_count>0 && light_count<=2) {
        toast.error("Sorry, but we cannot begin your KYC. Please move to a place with better lighting and click on the below button to retest your lighting conditions. Thank you")
        }
        else if (light_count>=3) {
          cb(true)
          toast.error("We're sorry but your KYC cannot be completed at this time due to inadequate lighting. Our agent will contact you shortly")
          light_count=0
        }
      }else if (light.data.result==='light') {
          dispatch(LivePerson(formData,history))
      }
      console.log(light_count)
      if (light.data.status==="success") {
        // cb(false)
        dispatch({type:ActionType.LIGHTNING_CONDITION_SUCCESS})
      }
  } catch (error) {
    cb(false)
    console.log(error)
    dispatch({type:ActionType.LIGHTNING_CONDITION_FAIL})
    if (error&&error.response){
    toast.error(error.response.data.message)
    }
  }
};
export const LivePerson = (formData,history) => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { token } = user;
  try {
    const liveperson = await axios(`${KYC_SIT}${PATH.faceliveliness}`, createFormHeader(formData, token))
      console.log(liveperson)
      if (liveperson.data.Label==="Imposter") {
        toast.error("Sorry, we couldn't determine whether you're a real person or a bot. Please press the Check My Liveliness and Lighting button again.")
      }else if (liveperson.data.Label==="Client") {
        history.push("/waitpage")
      }
      if (light_count===3) {
        toast.error("Sorry, we couldn't determine whether you're a real person or a bot. If you believe this is an error, please contact Customer Support.")
      }
  } catch (error) {
    if (error&&error.response){
      toast.error(error.response.data.desc)
    }
  }
};