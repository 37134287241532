import React, { Component } from "react";
import { connect } from "react-redux";
import Back from "../../Images/back.svg";
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import './Aadharotp.css'
import { Aadhar_Number, Submit_Otp, changeHeaderonAadhar } from '../../Actions'
import LoadingOverlay from "react-loading-overlay";
export class Aadharotp extends Component {
  state={
    aadharnumber:"",
    aadharotp:"",
    button_disable:false,
    AadharError:"",
    otp_button:false
  }
  constructor(props){
    super(props)
    this.props.changeHeaderonAadhar({step : 1, title : "Aadhar", visible : true,border_visible:true,border:20});
  }
  handleAadharChange=(e) => {
    this.setState({aadharnumber:e.target.value})
    console.log(e.target.value.length)
    if (e.target.value.length===12) {
      this.setState({button_disable:true,AadharError:""})
    }
    else {
      this.setState({button_disable:false,AadharError:"Aadhar number should be in 12 digit"})
    }
  }
  handleOTPChange=(e)=> {
    this.setState({aadharotp:e.target.value})
    if (e.target.value.length===6) {
      this.setState({otp_button:true})
    }
  }
  AadharSubmit=(e)=> {
    e.preventDefault();
    this.props.Aadhar_Number(this.state.aadharnumber)
  }
  OTPSubmit=(e)=> {
    e.preventDefault();
    const data={
      client_id:this.props.client_id,
      otp:this.state.aadharotp
    }
    this.props.Submit_Otp(data)
  }
  render() {
    if (this.props.aadhar_xml){
      this.props.history.push("/aadharextraction")
    }
    return (
      <React.Fragment>
      <LoadingOverlay active={this.props.aadhar_otp_loader||this.props.xml_loader}
      spinner
      text="Please Wait ...">
        <div className="aadhar_back" onClick={()=>this.props.history.push("/aadharxml")}>
          <img src={Back} alt="back" />
          <h3>BACK</h3>
        </div>
        <form>
            <div>
                <div className="otp_box pt-5">
                    <h3>Aadhaar OTP</h3>
                      <label className="aadharotp_label">Aadhaar Number</label>
                      <TextField id="standard-name" className="aadharuploadinput mt-4" name="aadhar" onChange={this.handleAadharChange} value={this.state.aadharnumber}/>
                      <h6 className="aadhar_error">{this.state.AadharError}</h6>
                      <div className="text-right mt-2">
                      <Button  color="primary" onClick={this.AadharSubmit} disabled={!this.state.button_disable}>Get OTP</Button>
                      </div>
                      <label className="aadharotp_label otp_label">OTP</label>
                    <TextField id="standard-name"  className="aadharuploadinput" name="aadharotp" onChange={this.handleOTPChange} value={this.state.aadharotp}/>
              </div>
              <div className="otp_button  mb-4">
                    <Button variant="contained" color="primary" onClick={this.OTPSubmit} disabled={!this.state.otp_button}>SUBMIT</Button>
              </div>
            </div>
        </form>
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    client_id: state.aadharotp.client_id,
    aadhar_xml:state.aadharotp.aadhar_xml,
    aadhar_otp_loader:state.aadharotp.aadhar_otp_loader,
    xml_loader:state.aadharotp.xml_loader
  }
}
const mapDispatchToProps = {
  Aadhar_Number,
  Submit_Otp,
  changeHeaderonAadhar
}
export default connect(mapStateToProps,mapDispatchToProps)(Aadharotp);
