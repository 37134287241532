import React, { Component } from "react";
import { connect } from "react-redux";
import "./login.css";
import { NavLink } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import FormLoader from "../../Components/Agents/loader/loader";
import { login } from "../../Actions/agent";
import password_hide from "../../Images/password_hide.png";
import password_show from "../../Images/password_show.png";
import IndLogo from "../../Images/IN-D-Logo/IND.png";
import Loader from '../../Components/Loader'

const initialState={
  token: "",
  emailid: "",
  password: "",
  emailError: "",
  hidden: true,
  emailbutton:true,
  passwordbutton:true,
  passwordError:""
}
class Login extends Component {
  
  constructor() {
    super();
    this.state = initialState
  }
  onEmailChange=(e)=> {
    const email=e.target.value
    if (email.length!==0){
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
          this.setState({...this.state,emailError:"",emailid:email,emailbutton:false})
        }else {
          this.setState({...this.state,emailError:"Email is not valid",emailbutton:true,emailid:email})
        }
      }
    else {
      this.setState({...this.state,emailError:"Field Required",emailbutton:true,emailid:email})
    }
  }
  handlePassword=(e)=> {
    const pass=e.target.value
    if (pass.length>2) {
      this.setState({...this.state,passwordbutton:false,passwordError:"",password:pass})
    }else {
      this.setState({...this.state,passwordbutton:true,passwordError:"Please Enter your Password",password:pass})
    }
  }
  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  onSignIn = (event) => {
    const body = { email: this.state.emailid, password: this.state.password };
    this.props.login(body, this.props.history,(success)=>{
      if (success) {
      this.setState(initialState);
      }
    });
  };

  render() {
    const { loader = false } = this.props;
    return (
      <div className="loginMain ">
      {loader?<Loader msg="Please wait signing in......"/>:
      <React.Fragment>
      <div className="header d-flex justify-content-between align-items-center">
      <div className="d-flex flex-column">
          <div>Login</div>
      </div>
      <img src={IndLogo} alt="ind-logo" />
      </div>
        <div id="loginBody" className="row justify-content-center">
          <div className="row justify-content-center " id="data">
            <div id="loginForm" className="col-md-6 col-sm-12">
              
              <h1 className="signup">Login</h1>
              <div className="signup_form ">
              <label>Email Id</label>
                <TextField
                  id="standard-name"
                  name="emailid"
                  onChange={this.onEmailChange}
                  className="underline"
                  type="email"
                  value={this.state.emailid}
                  onKeyPress={this.onEmailChange}
                />
                <div className="errormsg">{this.state.emailError}</div>
                <label>Password</label>
                <div>
                  <TextField
                    id="standard-name"
                    name="password"
                    onChange={this.handlePassword}
                    className="underline"
                    type={this.state.hidden ? "password" : "text"}
                    value={this.state.password}
                  />
                  <div onClick={this.toggleShow} className="hide_div">
                  {this.state.hidden?
                    <img src={password_hide} alt="hide" className="hide" />
                    :
                    <img src={password_show} alt="hide" className="hide" />
                  }
                  </div>
                </div>
                <div className="errormsg">{this.state.passwordError}</div>
                <button type="submit" id="signInButton" onClick={this.onSignIn} disabled={this.state.emailbutton||this.state.passwordbutton}>
                  Login
                </button>
              </div>

              <div id="newFull" className="row">
                {" "}
                <hr />
                <div id="new">New to IN-D ?</div>
                <hr />{" "}
              </div>

              <NavLink to="/agent/register" style={{ textDecoration: "none" }}>
                <button id="createButton">Create your IN-D Account</button>
              </NavLink>
            </div>
          </div>
        </div>
        </React.Fragment>
                }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.user.loader,
  };
};
const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
