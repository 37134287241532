import { KYC_SIT, PATH, API_ID, ActionType,QA_KYC } from "../Constants";
import axios from "axios";
import { toast } from "react-toastify";
import aadharotp from "../Reducers/aadharotp";

const createFormHeader = (type = "get", token, formdata = {}) => {
  return type === "get"
    ? {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {
        method: "POST",
        data: formdata,
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
};

// generate uid for apis
export const generateUid = () => async (dispatch, getState) => {
  const { user = {} } = getState().user;
  const { token,url } = user&&user;
  try {
    dispatch({ type: ActionType.USER_UID_REQUEST });
    const uid = await axios(
      `${KYC_SIT}${PATH.uid}`,
      createFormHeader("get", token)
    );
    console.log("uid uid uid ", uid);
    dispatch({
      type: ActionType.USER_UID_COMPLETED,
      payload: uid.data.result ? uid.data.result.uid : null,
    });
    return new Promise((res) => res(uid?.data?.result?.uid));
  } catch (error) {
    toast.error("ID generation failed please login again.")
    // dispatch({ type: ActionType.LOGOUT });
    // window.open(url,"_self")
    dispatch({ type: ActionType.USER_UID_FAILED });
    return new Promise((res) => res(null));
  }
};

export const requestUserService = (uid) => async (dispatch, getState) => {
  if (!uid)
    return toast.error(
      "Failed to get UID, Please update your token or relogin"
    );
  const { user = {} } = getState().user;
  const { session = {} } = getState().opentok;
  const { company = "",language="" } = user;
  const { Session_ID = "", apikey = "", token = "" } = session;
  const {location={}}=getState().aadharotp;
  console.log(location)
  const company_language=`${company}_${language}`
  const userlocation=location&&location==="out_india"?"outside_india":""
  try {
    dispatch({ type: ActionType.USER_SERVICE_REQUEST });
    const req = await axios.post(`${KYC_SIT}${PATH.userOpentok}`,{
      Company: company_language,
      Token: token,
      Session_id: Session_ID,
      api_key: apikey,
      user_token: user.token,
      user_id: user.UserID,
      uid,
      userlocation:userlocation,
      // method: "POST",
      // mode: 'cors',
      
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Authorization: `Bearer ${token}`,
      // },
    });
    console.log(req)
    console.log("requestUserService res", Session_ID);
    dispatch({ type: ActionType.USER_SERVICE_COMPLETED });
    if (req.status === "Sccuess") toast.success(req.desc);
    else toast.error(req.desc);
  } catch (error) {
    console.log("opnetok tokens catch error ", error);
    dispatch({ type: ActionType.USER_SERVICE_FAILED });
    toast.error("Failed to push in queue, Please again later");
    return new Promise((res) => res({ finished: true }));
  }
};

export const setUserOpentokStream = (streams = []) => (dispatch) => {
  let streamCp = [...streams];
  dispatch({ type: ActionType.USER_RE_STREAM, payload: streamCp });
};
export const setUserOpentokSession = (session) => (dispatch) =>
  dispatch({ type: ActionType.USER_RE_SESSION, payload: session });

export const clearExtractions = ({ type = "full", step = 0 }) => (
  dispatch,
  getState
) => {
  if (type === "full")
    dispatch({ type: ActionType.CLEAR_EXTRACTION_STEPS, payload: {} });
};

export const changeHeaderonAadhar = (header) => (dispatch) =>
  dispatch({ type: ActionType.CHANGE_AADHAR_STEPS, header });

// export const BorderChange = (border) => (dispatch) =>{
//   console.log(border)
//   dispatch({ type: ActionType.BORDER_CHANGE, border });
// }
export const captureDataAction = (arr, type, redirect) => (dispatch) => {
  console.log(arr);
  if (arr.type&&arr.type === "aadhar") {
    const newArr = arr.data.filter(
      (data) => data.DOC_TYPE === "Aadhar_XML" || "Aadhar Card Back"
    );
    dispatch({ type: ActionType.AADHAR_CAPTURE, payload: newArr });
  } // dispatch on some unique key
  if (arr.data1&&arr.data1?arr.data1:{}) {
    dispatch({type:ActionType.AADHARFRONT_CARD,payload:arr.data1})
  }
  if (arr.type&&arr.type === "pan") {
    const newArr = arr.data.filter(
      (data) => data.DOC_TYPE ==="PAN Card" || data.type === "PAN Card"
    );
    dispatch({ type: ActionType.PAN_CAPTURE, payload: newArr }); // dispatch on some unique key
  }
  redirect(arr.type);
};
