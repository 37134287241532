import React, { Component } from 'react'
import './profilepage.css'
import success from '../../Images/success.svg'
import error from '../../Images/error.svg'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'
import { changeHeaderonAadhar,Profile_page,Statusupdate } from '../../Actions'
import profile from '../../Images/profile_icon.png'
import Orangelogo from '../../Images/orange.png'
import LoadingOverlay from "react-loading-overlay";

export class Profilepage extends Component {
    constructor(props) {
        super(props);
        this.props.changeHeaderonAadhar({title:"Profile Result",visible:true,border_visible:true,border:100})
    }
    componentDidMount=()=> {
        this.props.Profile_page()
        this.props.Statusupdate()
    }
    StatusUpdate= async ()=> {
        this.props.history.push('/thankyou')  
    }
    render() {
            const Aadhar_profile_data=this.props.profilepage?this.props.profilepage:{}
            const {NAME="",DATE_OF_BIRTH="",FATHER_SPOUSE_NAME="",PAN="",XML_PHOTO="",GENDER=""}=Aadhar_profile_data
            const location = this.props.location
            const changes=this.props.profilepage&&this.props.profilepage.changes ?this.props.profilepage.changes[0] :null
            const edited=this.props.profilepage&&this.props.profilepage.edited ?this.props.profilepage.edited :{}
            for (let i = 0; i <edited.length; i++) {
                console.log(edited[i])
            if (edited[i] === "DATE_OF_BIRTH") {
               var dob = edited[i];
            }
            if (edited[i] === "PAN") {
                 var pan = edited[i];
            }
            if (edited[i] === "FATHER_SPOUSE_NAME") {
                var father_spouse_name = edited[i];
            }
            if (edited[i] === "NAME") {
                var name = edited[i];
            }
            if (edited[i] === "GENDER") {
                var gender = edited[i];
            }
            console.log(dob)
}
        return (
            <div className="main_profile">
            <LoadingOverlay active={this.props.ploader}
            spinner
            text="Please Wait ...">
                
                <div>
                <div className="user_data_results">
                    <div className="profile_data">
                    {XML_PHOTO ?
                        <img src={"data:image/png;base64," +  XML_PHOTO} alt="profile_icon" id="profile_icon"/>:Aadhar_profile_data.face?<img src={"data:image/png;base64," +  Aadhar_profile_data.face} alt="profile_icon" id="profile_icon"/>:<img src={profile} alt="profile" id="profile_icon"/> }
                        <div>
                            <h6>Hello!</h6>
                            <h5>{NAME}</h5>
                        </div>
                    </div>
                    <hr />
                    <div className="icon_data">
                        <div className="d-flex justify-content-between profile_image">
                            <h6>Liveliness test result</h6>
                            {changes?
                            changes.livelines?<img src={success} alt="success" />:<img src={error} alt="error" />:
                            Aadhar_profile_data.livelines?<img src={success} alt="success" />:<img src={error} alt="error" />
                            }
                        </div>
                        <hr />
                        
                        <div className="d-flex justify-content-between profile_image">
                            <h6>Face match result</h6>
                            {changes?
                                changes.face_match?<img src={success} alt="success" />:<img src={error} alt="error" />:
                                Aadhar_profile_data.face_match?<img src={success} alt="success" />:<img src={error} alt="error" />
                                }
                            
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between profile_image">
                            <h6>Cross check result</h6>
                            {changes?
                                changes.cross_check?<img src={success} alt="success" />:<img src={error} alt="error" />:
                                Aadhar_profile_data.cross_check?<img src={success} alt="success" />:<img src={error} alt="error" />
                                }
                        </div>
                        
                        <hr />
                        <div className="d-flex justify-content-between profile_image">
                            <h6>Government DB validation result</h6>
                            {Aadhar_profile_data.govt_check? 
                            <img src={success} alt="success" />:<img src={error} alt="error" /> }
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between profile_image">
                            <h6>Geo tagging</h6>
                            {location&&location.cc==="IN"? 
                                <img src={success} alt="success" />:<img src={error} alt="error" /> }
                        </div>
                    </div>
                </div>
                <div className="profile_details">
                    <h4>Profile details</h4>
                    <div className="mini_profile">
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Name</p>
                            <div className="orange_div">
                            <h5>{NAME}</h5>
                            {name?
                                <img src={Orangelogo} alt="logo" className="orange_logo"/>:""}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>DOB</p>
                            <div className="orange_div">
                                <h5>{DATE_OF_BIRTH}</h5>
                                {dob?
                                <img src={Orangelogo} alt="logo" className="orange_logo"/>:""}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Father Name</p>
                            <div className="orange_div">
                                <h5>{FATHER_SPOUSE_NAME}</h5>
                                {father_spouse_name?
                                    <img src={Orangelogo} alt="logo" className="orange_logo"/>:""}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Location</p>
                            <h5>{location ?<div className="profilelocation">{location}</div>:"NA"}</h5>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Timestamp</p>
                            <h5>{localStorage.getItem("timestamp").slice(0,25)} IST</h5>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>Gender</p>
                            <div className="orange_div">
                            <h5>{GENDER==="M"?"Male":GENDER==="F"?"Female":GENDER?GENDER:"NA"}</h5>
                                {gender?
                                    <img src={Orangelogo} alt="logo" className="orange_logo"/>:""}
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <p>PAN</p>
                            <div className="orange_div">
                                <h5>{PAN?PAN:"NA"}</h5>
                                {pan?
                                    <img src={Orangelogo} alt="logo" className="orange_logo"/>:""}
                            </div>
                        </div>
                    </div>
                </div>
                <Button variant="contained" color="primary" className="profile_submit mt-3 mb-4" onClick={this.StatusUpdate}>FINISH</Button>
                    </div>
                    </LoadingOverlay>
                </div>
        )
    }
}
const mapStateToProps=(state)=> {
    return {
        location:state.aadharotp.location,
        profilepage:state.aadharotp.profilepage,
        ploader:state.aadharotp.user_ploader,
    }
}
const mapDispatchToProps={
    changeHeaderonAadhar,
    Profile_page,
    Statusupdate
}
export default connect(mapStateToProps,mapDispatchToProps)(Profilepage);

















