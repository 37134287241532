import React, { useState, useEffect } from 'react';

export const Timer= ({ name }) => {
    const [counter, setCounter] = useState(0);
    const [timer,setTimer]=useState()
    useEffect(() => {
      const timeout = setTimeout(() => {
        setCounter(counter + 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }, [counter]);

    const createTime=(secs)=> {
        let hours=Math.floor(secs/(60*60))
        let divisor_for_minutes=secs%(60*60);
        let minutes=Math.floor(divisor_for_minutes/60);
        let divisor_for_seconds=divisor_for_minutes%60;
        let seconds = Math.ceil(divisor_for_seconds);
        let timeObject={
                  "hours":hours,
                  "minutes":minutes,
                  "seconds":seconds
                  }
        return timeObject;
    }
    useEffect(() => {
        let timeLeft=createTime(counter)
        setTimer(timeLeft)
    },[counter])
    return (
      <div>
      {counter?
        <div>{timer.minutes}:{timer.seconds}</div>:<div>"00:00"</div>}
    </div>
    )
  };