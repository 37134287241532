import { ActionType } from "../Constants";
import { Action } from "history";
const initialState = {
  loader: false,
  _loader: false,
  steps: {
    active: 0,
  },
  doctype: "pan",
  extraction: {},
  extloader: false,
  screencapture: false,
  image_loader: false,
  authloader: false,
  stoprecording:""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionType.AGENT_SERVICE_REQUEST:
      return { ...state, loader: true };
    case ActionType.AGENT_SERVICE_COMPLETED:
      return { ...state, loader: false };
    case ActionType.AGENT_SERVICE_FAILED:
      return { ...state, loader: false };
    case ActionType.AGENT_VIDEO_COMPONENTS:
      return { ...state, steps: action.payload };
    case ActionType.SAVE_AADHAR_REQUEST:
      return { ...state, _loader: true };
    case ActionType.SAVE_AADHAR_COMPLETE:
      return { ...state, _loader: false };
    case ActionType.SAVE_AADHAR_FAILED:
      return { ...state, _loader: false };
    case ActionType.CHANGE_DOC_TYPE:
      return { ...state, doctype: action.payload };
    case ActionType.IMAGE_EXTRACTION_REQUEST:
      return { ...state, extloader: true };
    case ActionType.IMAGE_EXTRACTION_COMPLETED:
      return { ...state, extloader: false, extraction: action.payload };
    case ActionType.IMAGE_EXTRACTION_FAILED:
      return { ...state, extloader: false };
    case ActionType.AGENT_PROFILE_PAGE_REQUEST:
      return { ...state, ploader: true };
    case ActionType.AGENT_PROFILE_PAGE_COMPLETED:
      return { ...state, agentprofile: action.payload, ploader: false };
    case ActionType.AGENT_PROFILE_PAGE_FAILED:
      return { ...state, ploader: false };
    case ActionType.AGENT_PROFILE_PAGE_SUCCESS:
      return { ...state, ploader: false };
    case ActionType.SCREEN_CAPTURE_FAILED:
      return { ...state, screencapture: {}, screencapture: false };
    case ActionType.SCREEN_CAPTURE_REQUEST:
      return { ...state, screencapture: {}, screencapture: true };
    case ActionType.SCREEN_CAPTURE_COMPLETED:
      return { ...state, screencapture: action.payload, screencapture: false };
    case ActionType.COMPANY_NAME_UPDATE:
      return { ...state, company: action.payload };
    case ActionType.LIVELINESS_DATA_FROM_USER:
      return { ...state, extraction: action.payload };
    case ActionType.DASHBOARD_READ_USER:
      return { ...state, readuser: action.payload };
    case ActionType.DECODE_TOKEN_COMPLETED:
      return { ...state, decodetoken: action.payload };
    case ActionType.DECODE_TOKEN_FAIL:
      return { ...state, decodetoken: "" };
    case ActionType.AADHAR_VALIDATION:
      return { ...state, aadhar_verification: action.payload };
    case ActionType.AADHARFRONT_CARD:
      return { ...state, aadhar_front: action.payload, extloader: false };
    case ActionType.AGENT_OVERRIDE:
      return { ...state, agent_override: action.payload };
    case ActionType.IMAGE_LOADER_REQUEST:
      return { ...state, image_loader: true };
    case ActionType.IMAGE_LOADER_SUCCESS:
      return { ...state, image_loader: false,data_image:action.payload };
    case ActionType.USER_STATUS_UPDATE:
      return { ...state, statusupdate: action.payload };
    case ActionType.AGENT_REGISTRATION_REQUEST:
      return { ...state, authloader: true };
    case ActionType.AGENT_REGISTRATION_SUCCESS:
      return { ...state, authloader: false };
    case ActionType.AGENT_REGISTRATION_FAILED:
      return { ...state, authloader: false };
    case ActionType.AGENT_SCREEN_CAPTURE_STOP_MSG:
      return {...state, stoprecording:action.payload}
    case ActionType.AADHAAR_FRONT_IMAGE:
      return {...state,aadharfront_image:action.payload}
    case ActionType.SCREENCAPTURE_CAPTURE_AUTOMATE:
      return {...state,captureautomate:action.payload}
    case ActionType.RESET:
      return initialState;
    case ActionType.AGENTRESET:
      return initialState;
    default:
      return state;
  }
};
