import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

export default class loader extends Component {
  
  render() {
    return (
      <React.Fragment>
        <div className="loader">
          <LinearProgress />
          {/*<CircularProgress id="loadercolor" />
          <div>
          {this.props.msg === undefined ? ' Loading, Please wait !!! ' : <span className="loadingText"> {this.props.msg} </span>}
          </div>
          */}
        </div>
      </React.Fragment>
    );
  }
}
