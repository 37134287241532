import React, { Component, useEffect, useState, useCallback } from "react";
import "./liveliness.css";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { changeHeaderonAadhar, Liveliness_page } from "../../Actions";
import { useSelector, useDispatch } from "react-redux";
import { socket, socketInit } from "../../Helpers";
import { Mic, Videocam } from "@material-ui/icons";
import LoadingOverlay from "react-loading-overlay";
import { emit } from "../../Helpers";
import { toast } from "react-toastify";
import Webcam from "react-webcam";
const videoRef = React.createRef();
let stream,
  recorder,
  chunks = [];

const Web = (props) => {
  const [capturing, setCapture] = React.useState(false);
  const [state, setState] = useState(true);
  const changeState = () => {
    setState(!state);
  };
  console.log(state);

  const dispatch = useDispatch();
  console.log(props.rand);
  useEffect(() => {
    const initVideo = async () => {
      const constraints = {
        video: { width: { max: 600 } },
        audio: state,
      };
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        console.log(constraints);
        if (videoRef) videoRef.current.srcObject = stream;
      } catch (error) {
        alert(
          "Camera is not supported, please try in a different and secure browser"
        );
      }
    };
    initVideo();
    // if (!state) {
    //   console.log(state);
    //   toast.error("Please enable below red color microphone and try liveliness");
    //   setCapture(true);
    // } else {
    //   setCapture(false);
    // }
  }, [state]);

  const startLive = () => {
    setCapture(true);
    recorder = new MediaRecorder(stream);
    recorder.ondataavailable = (e) => chunks.push(e.data);
    recorder.onstop = (e) => {
      const completeBlob = new Blob(chunks, { type: "video/webm" });
      let fileObject = new File([completeBlob], "video.webm", {
        type: "video/webm",
      });
      localStorage.setItem("timestamp", fileObject.lastModifiedDate);
      const livelinessdata = {
        fileObject: fileObject,
        rand: props.rand,
      };
      console.log(livelinessdata);
      dispatch(Liveliness_page(livelinessdata));
    };
    recorder.start();
    setTimeout(() => {
      recorder.stop();
      setCapture(false);
    }, 5200);
  };

  return (
    <div>
      <div className="liveliness_webcam">
        <div id="container1">
          <Webcam id="video" ref={videoRef} audio={true} autoPlay />
          <div
            id="playVideo"
            className={`round-tool-icon center ${
              state ? "round-tool-icon-pressed" : ""
            }`}
            onClick={changeState}
          >
            <Mic />
          </div>
        </div>
        <Button
          disabled={capturing}
          variant="contained"
          color="primary"
          className="liveliness_button"
          onClick={startLive}
        >
          Start Liveliness
        </Button>
      </div>
    </div>
  );
};

const min = 1;
const max = 10;
let rand = Math.floor(min + Math.random() * (max - min));
const randdata = rand;
export class liveliness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
    if (!socket) {
      this.props.socketInit();
    }
    this.props.changeHeaderonAadhar({
      step: 4,
      title: "Liveliness",
      visible: true,
      border_visible: true,
      border: 80,
    });
  }
  componentDidMount = async () => {
    const { user = {}, uid = "" } = this.props;
    const { session = {} } = this.props;
    console.log(user);
    if (socket) {
      socket.on(`${session.Session_ID}_AGENT`, (data) => {
        if (data.user_id === user.UserID && data.alias === "agent-overide-data")
          this.props.history.push("/profilepage");
      });
    }
    emit("agent-to-user", {
      uid,
      user_id: this.props.userid,
      alias: "Liveliness_data_from_user",
      data: { randdata, msg: "", state: true },
      session_id: this.props.session_id,
      side: "USER",
    });
  };

  render() {
    return (
      <div className="main_liveliness">
        <React.Fragment>
          <LoadingOverlay
            active={this.props.liveloader}
            spinner
            text="Please wait we are verifying your liveliness ..."
          >
            <div>
              <div className="liveliness_start_button mt-3">
                <h6>
                  <p>1. Click "Start Liveliness" button</p>
                  <p>2. Read aloud the number displayed below</p>
                  <p>
                    Please note: Your liveliness video may take up to 60 seconds
                    to upload. Please do not close or refresh the window.{" "}
                  </p>
                </h6>
                <h1>{rand}</h1>
              </div>
              <div className="liveliness_video mt-3 mb-3">
                <Web rand={rand} />
              </div>
            </div>
          </LoadingOverlay>
        </React.Fragment>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    liveloader: state.aadharotp.liveloader,
    uid: state.user.uid,
    userid: state.user.user.UserID,
    session_id: state.opentok.session.Session_ID,
    session: state.opentok.session,
  };
};
const mapDispatchToProps = {
  Liveliness_page,
  changeHeaderonAadhar,
  socketInit,
};
export default connect(mapStateToProps, mapDispatchToProps)(liveliness);
