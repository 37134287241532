import React, { useState, useEffect,useCallback } from "react";
import {
  preloadScript,
  OTStreams,
  OTSession,
  OTPublisher,
  OTSubscriber,
} from "opentok-react";
import { useSelector, useDispatch } from "react-redux";
import { imageExtraction, captureDataAction,Delete } from "../../Actions";
import { Loader } from "../../Components";
import "@opentok/client";
import { socket, socketInit } from "../../Helpers";
import "./opentok.css";
import { withRouter } from "react-router-dom";
import camera from '../../Images/CameraIcon.png'
import {Dialog,DialogActions,DialogContent,DialogTitle,DialogContentText,Slide,Button} from "@material-ui/core";
import { ActionType} from "../../Constants";
import {Timer} from '../../Components/Timer/timer'


let publisher;
let OtSessionRef;
if (!socket) socketInit();
let detailIm = {};
let agent_data={};
const OpenTok = (props) => {
  const { session = {} } = useSelector(({ opentok }) => opentok);
  const { loader = false } = useSelector(({ customer }) => customer);
  const { user = {} } = useSelector(({ user }) => user);
  const {url=""}=user;
  const { statusupdate={} } = useSelector(({ aadharotp }) => aadharotp);
  const {desc=""}=statusupdate
  const [poistion, setPosition] = useState('user');
  const [count, setCount] = useState(0);
  const [Data,setData]=useState(0)
  const [open,setOpen]=useState(false)
  const [disModal, setDiscModal] = useState(false);
  let dispatch = useDispatch();
  let numberOfConnections=0

console.log(desc,"sqwdgtfrdadgthyju")
  const redirections = (type) => {
    console.log(type)
    if (type === "aadhar") {
      props.history.push("/aadharextraction")
    }
    if (type === "pan") {
      props.history.push("/aadharextraction")
    }
    if (type === "signature"||type==="sign") {
      props.history.push("/liveliness")
    }
  };


  const handleRef = (ref) => {
    if (!socket){
    dispatch(socketInit())
    }
    OtSessionRef = ref;
    let session = OtSessionRef&&OtSessionRef.sessionHelper&&OtSessionRef.sessionHelper.session&&
        OtSessionRef.sessionHelper.session;
    if(session){
      session.on("connectionCreated",e=>{
        console.log("connectionCreated",e);
        numberOfConnections+=1
        setTimeout(() => {
          if (numberOfConnections>=2){
              console.log("More than 2 connections")
          }
          else if (numberOfConnections<=1){
            setTimeout(() => {
              setDiscModal(true);
              if (desc==="completed"){
                console.log("status is completed")
              }else {
                console.log("deleteing google bucket data")
                // dispatch(Delete())
              }
            }, 5000);
          }
          }, 25000);

        console.log(numberOfConnections)

      });
      session.on("connectionDestroyed", e=>{
        numberOfConnections-=1
        console.log("connectionDestroyed ",e);
        setTimeout(() => {
        setDiscModal(true)
        if (desc==="completed"){
          console.log("Status is completed")
        }else {
          console.log("deleteing google bucket data")
          // dispatch(Delete())
        }
        }, 30000);
      })
    }
      
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Disconnect = ({ open, handleClose}) => {
  const Redirect=()=> {
    dispatch({type:ActionType.RESET})
    setTimeout(() => {
      window.open(url,"_self")
  }, 1000);
  }
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"Disconnect"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          No Agents are present in session please disconnect.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {handleClose(); Redirect();}} color="primary" style={{color:"red"}}>
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

  const handleToggleFace = () => {
    setOpen(true)
    setPosition(poistion === 'environment' ? 'user' : 'environment')
    if (publisher) {
      let pub = publisher.getPublisher();
      if (pub && pub.cycleVideo)
        pub.cycleVideo();
    }
  }
  setTimeout(() => {
    setOpen(false)
  }, 3000);

  const handlePublishRef = ref => {
    if (!socket) {
      socketInit()
    }
    publisher = ref
    if (socket) {
      socket.on(`${session.Session_ID}_AGENT`, (detail) => {
        if (detail.user_id === user.UserID && detail.alias === "image_capture_extracted_data"){
          setData(Data+1)
          agent_data=detail
        }
        if (detail.user_id === user.UserID && detail.alias === 'capture_user_camera') {
          setCount(count + 1)
          detailIm=detail
        }
      });
    }
  }
 
  useEffect(() => {
    if (count === 1) {
      if (publisher) {
        let pub = publisher.getPublisher()
        const imageData = pub ? pub.getImgData() : null;
        socket.emit('agent-to-user', { image: imageData, agent: detailIm.agent,session_id:session.Session_ID,uid:session.uid,alias:"capture_user_camera",side:"USER" })
      }
      setTimeout(() => {
        setCount(0);
        detailIm={}
      }, 3000);
    }
  }, [count])
  console.log(agent_data)
  useEffect(() => {
      if (agent_data&&Object.keys(agent_data).length>0){
      dispatch(captureDataAction(agent_data.data, agent_data.data, redirections));
      }
      setTimeout(() => {
        setData(0);
        agent_data={}
      }, 3000);
  }, [Data])
 
  return (
    <div>
    { open?<h5 style={{textAlign:"center"}} className="slider">{poistion=='user'?'Front Camera':'Back Camera'}</h5>:""}
    <div className="user-video-call-container">
      {loader ? (
        <Loader />
      ) : session && session.token ? (
        
        <div className="opentok-container-client">
          <OTSession apiKey={session.apikey} sessionId={session.Session_ID} token={session.token} ref={handleRef}>
          <div className="videoborder"></div>
            <OTPublisher ref={handlePublishRef}
              properties={{
                publishAudio: true,
                publishVideo: true,
                width:"100%",
                height: 424,
                facingMode:poistion,
                mirror:false
              }}
            />
            <OTStreams>
             
                <div className="publisher-view subscriberinuser">
                <OTSubscriber 
                    properties={{
                      subscribeToAudio: true,
                      subscribeToVideo: true,
                      width: 100,
                      height: 113
                    }}
                    />
                </div>
               
            </OTStreams>
          </OTSession>
          <div className="video-footer center user_video_footer" onClick={handleToggleFace} style={{textAlign:"center"}}>
                <img src={camera}  />
                <span className="user_timer">
                  <Timer />
                </span>
           </div>
      <Disconnect open={disModal} handleClose={() => setDiscModal(false)} />

                  
        </div>
      ) : null}
      
     
    </div>
    </div>

  );
};

export default withRouter(preloadScript(OpenTok));
